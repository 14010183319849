export default {
  permissions: [
    'Perform::Put',
    'Perform::Pick',
    'Perform::Reserve',
    'Perform::Adjust',
    'Perform::CheckIn',
    'Perform::CheckOut',
    'Perform::ReportIncident',
    'Create::Warehouse',
    'Modify::Warehouse',
    'Create::Inventory',
    'Modify::Inventory',
    'Create::Users',
    'Modify::Users',
    'Print::Labels'
  ],
  allowedUIModules: ['Warehouse', 'Inventory', 'Users', 'Labels', 'Reports'],
  predefinedUserRoleNames: ['SuperAdmin', 'Admin', 'Manager', 'Supervisor', 'ProjectWorker']
};
