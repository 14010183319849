import React, { useEffect, useState } from 'react';

import DashboardLayout from 'layouts/DashboardLayout';
import DashboardNavbar from 'components/DashboardNavbar';
import Breadcrumbs from 'components/Breadcrumbs';
import {
  Box,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TableBody,
  TableCell,
  TableRow
} from '@mui/material';
import MDBox from 'components/MDBox';
import MDInput from 'components/MDInput';
import { useDispatch, useSelector } from 'react-redux';
import { ReportSelectors } from 'redux/ReportsRedux';
import ReportActions from 'redux/ReportsRedux';
import { API } from 'constant';
import MDButton from 'components/Button';
import BasicTable from 'components/BasicTable';
import moment from 'moment';
import UsersActions from 'redux/UsersRedux';
import WarehouseActions from 'redux/WarehouseRedux';
import InventoryActions from 'redux/InventoryRedux';
import { WarehouseSelectors } from 'redux/WarehouseRedux';
import { InventorySelectors } from 'redux/InventoryRedux';
import { UsersSelectors } from 'redux/UsersRedux';
import TablePagination from 'components/TablePagination';
import { exportToExcel } from 'utils/exportExcel';

const tHeads = [
  { id: 'kind', label: 'Kind' },
  { id: 'createdAt', label: 'Date' },
  { id: 'commonName', label: 'Common Name' },
  { id: 'performedBy', label: 'Performed By' }
];

const mapHeadsToReponse = (resp) =>
  resp.map((res) => ({
    id: res?._id || '',
    kind: res?.kind || '',
    createdAt: moment(res?.createdAt).format('D/M/YYYY h:m:s A') || '',
    commonName: res?.performedOn?.commonName || '',
    performedBy: res?.performedBy?.fullName || ''
  }));

const CCRReport = () => {
  const dispatch = useDispatch();

  const warehouses = useSelector(WarehouseSelectors.getWarehouseDetail);
  const inventories = useSelector(InventorySelectors.getInventoryDetail);
  const users = useSelector(UsersSelectors.getUsersDetail);
  const [filterInventory, setFilterInventory] = useState('');
  const [filterWarehouse, setFilterWarehouse] = useState('');
  const [filterFromDate, setFilterFromDate] = useState('');
  const [filterToDate, setFilterToDate] = useState('');
  const [filterKind, setFilterKind] = useState(['CheckIn', 'CheckOut', 'Report']);
  const [filterUser, setFilterUser] = useState('');
  const [applyCount, setApplyCount] = useState(0);

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(50);

  const ccrData = mapHeadsToReponse(useSelector(ReportSelectors.getReportDetail));
  const reportType = useSelector(ReportSelectors.getReportType);
  const count = useSelector(ReportSelectors.getReportCount);

  const buildParams = () => {
    let params = [];
    if (filterInventory) params.push(`inventoryId=${filterInventory}`);
    if (filterWarehouse) params.push(`warehouseId=${filterWarehouse}`);
    if (filterFromDate) params.push(`dateFrom=${filterFromDate}`);
    if (filterToDate) params.push(`dateTo=${filterToDate}`);
    if (filterKind.length) params.push(`kind=${filterKind}`);
    if (filterUser) params.push(`userId=${filterUser}`);
    if (page) params.push(`page=${page}`);
    if (perPage) params.push(`perPage=${perPage}`);
    return params.length ? `?${params.join('&')}` : '';
  };

  useEffect(() => {
    (!warehouses || warehouses.length === 0) &&
      dispatch(
        WarehouseActions.warehouseDataAction({
          loader: 'loading-request',
          slug: API.GET_WAREHOUSE_DATA,
          method: 'get'
        })
      );
    (!inventories || inventories.length === 0) &&
      dispatch(
        InventoryActions.getInventoryAction({
          loader: 'loading-request',
          slug: API.GET_INVENTORY,
          method: 'get'
        })
      );
    (!users || users.length === 0) &&
      dispatch(
        UsersActions.getUsersAction({
          loader: 'loading-request',
          slug: API.GET_USERS_DATA,
          method: 'get'
        })
      );
  }, []);

  useEffect(() => {
    dispatch(
      ReportActions.getReportAction({
        loader: 'loading-request',
        slug: API.REPORT_CCR + buildParams(),
        method: 'get',
        reportType: 'ccr'
      })
    );
  }, [applyCount, page, perPage]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Breadcrumbs
        title="CCR Report"
        route={[
          { name: 'Home', path: '/home' },
          { name: 'Reports', path: '/reports' },
          { name: 'CCR Report' }
        ]}
      />
      <MDBox px={5} py={3}>
        <Grid
          container
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'space-evenly'
          }}
        >
          <Grid item xs={3}>
            <FormControl fullWidth sx={{ marginBottom: '10px' }}>
              <InputLabel>Filter by Warehouse</InputLabel>
              <Select
                fullWidth
                labelId="warehouse-filter-label"
                id="warehouse-filter"
                value={filterWarehouse}
                label="Filter by Warehouse"
                onChange={(e) => setFilterWarehouse(e.target.value)}
              >
                <MenuItem value="">
                  <em>All</em>
                </MenuItem>
                {warehouses &&
                  warehouses.map((warehouse) => (
                    <MenuItem key={warehouse._id} value={warehouse._id}>
                      {warehouse.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControl fullWidth sx={{ marginBottom: '10px' }}>
              <InputLabel>Filter by Inventory</InputLabel>
              <Select
                fullWidth
                labelId="inventory-filter-label"
                id="inventory-filter"
                value={filterInventory}
                label="Filter by Inventory"
                onChange={(e) => setFilterInventory(e.target.value)}
              >
                <MenuItem value="">
                  <em>All</em>
                </MenuItem>
                {inventories &&
                  inventories.map((inventory) => (
                    <MenuItem key={inventory._id} value={inventory._id}>
                      {inventory.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <MDInput
              fullWidth
              type="date"
              variant="outlined"
              label="From Date"
              value={filterFromDate}
              InputLabelProps={{ shrink: true }}
              sx={{ marginBottom: '10px' }}
              onChange={(e) => setFilterFromDate(e.target.value)}
            />
            <MDInput
              fullWidth
              type="date"
              variant="outlined"
              label="To Date"
              value={filterToDate}
              InputLabelProps={{ shrink: true }}
              sx={{ marginBottom: '10px' }}
              onChange={(e) => setFilterToDate(e.target.value)}
            />
          </Grid>

          <Grid item xs={3}>
            <FormControl fullWidth sx={{ marginBottom: '5px' }}>
              <InputLabel>Kind</InputLabel>
              <Select
                multiple
                fullWidth
                value={filterKind}
                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.2 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} size="small" />
                    ))}
                  </Box>
                )}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 12 * 4.5 + 2,
                      width: 250
                    }
                  }
                }}
                onChange={(event) => {
                  const {
                    target: { value }
                  } = event;
                  setFilterKind(
                    // On autofill we get a stringified value.
                    typeof value === 'string' ? value.split(',') : value
                  );
                }}
              >
                {['CheckIn', 'CheckOut', 'Report'].map((name) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth sx={{ marginBottom: '10px' }}>
              <InputLabel>Filter by User</InputLabel>
              <Select
                fullWidth
                labelId="user-filter-label"
                id="user-filter"
                value={filterUser}
                label="Filter by User"
                onChange={(e) => setFilterUser(e.target.value)}
              >
                <MenuItem value="">
                  <em>All</em>
                </MenuItem>
                {users &&
                  users.map((user) => (
                    <MenuItem key={user._id} value={user._id}>
                      {user.fullName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={1}>
            <MDButton
              fullWidth
              size="medium"
              color="primary"
              variant="outlined"
              sx={{ marginBottom: '10px' }}
              onClick={() => {
                setFilterInventory('');
                setFilterWarehouse('');
                setFilterFromDate('');
                setFilterToDate('');
                setFilterKind(['CheckIn', 'CheckOut', 'Report']);
                setFilterUser('');
              }}
            >
              RESET
            </MDButton>
            <MDButton
              fullWidth
              size="medium"
              color="primary"
              sx={{ marginBottom: '10px' }}
              onClick={() => {
                setApplyCount(applyCount + 1);
              }}
            >
              APPLY
            </MDButton>
          </Grid>
          <Grid item xs={1}>
            <MDButton
              fullWidth
              size="medium"
              color="primary"
              sx={{ marginBottom: '10px', height: '80%' }}
              onClick={() => {
                exportToExcel(tHeads, ccrData, 'CCR_Report');
              }}
            >
              Export to Excel
            </MDButton>
          </Grid>
        </Grid>
        {reportType === 'ccr' ? (
          <BasicTable headCells={tHeads} backgroundColor="#E5E5E5" color="#8D8D8D">
            <TableBody>
              {ccrData && ccrData.length
                ? ccrData.map((item) => (
                    <TableRow key={item.id}>
                      {tHeads.map((head, i) => (
                        <TableCell key={i}>{item[head.id]}</TableCell>
                      ))}
                    </TableRow>
                  ))
                : 'No records'}
            </TableBody>
          </BasicTable>
        ) : (
          'Loading'
        )}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '15px 10px'
          }}
        >
          <Box sx={{ fontSize: '14px', color: '#000' }}>
            Per page:{' '}
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={perPage}
              label="Page Number"
              onChange={(e) => {
                setPerPage(e.target.value);
              }}
            >
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
              <MenuItem value={200}>200</MenuItem>
              <MenuItem value={1000000}>All</MenuItem>
            </Select>
          </Box>
          <Box>
            <TablePagination count={Math.ceil(count / perPage)} page={page} setPage={setPage} />
          </Box>
          <Box sx={{ fontSize: '14px', color: '#000' }}>
            {!!count && (
              <>
                {(page - 1) * perPage + 1} to {perPage * page > count ? count : perPage * page} of{' '}
                {count}
              </>
            )}
          </Box>
        </Box>
      </MDBox>
    </DashboardLayout>
  );
};

export default CCRReport;
