import ExcelJS from 'exceljs';

const writeFile = (fileName, content) => {
  const link = document.createElement('a');
  const blob = new Blob([content], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  });
  link.download = fileName;
  link.href = URL.createObjectURL(blob);
  link.click();
};

export const exportToExcel = (headers, data, fileName) => {
  let workbook = new ExcelJS.Workbook();
  let worksheet = workbook.addWorksheet(fileName);

  const column = [];
  headers.forEach((head) => {
    if (head.label && head.id) {
      column.push({
        header: head.label,
        key: head.id,
        width: head.label.length
      });
    }
  });

  worksheet.columns = column;

  data?.forEach((row) => {
    const rowValue = [];
    headers.forEach((head) => {
      if (head.label && head.id) {
        const cellValue = row[head.id] || '';
        rowValue.push(cellValue);
        const col = worksheet.getColumn(head.id);
        col.width = cellValue.length > col?.width ? cellValue.length : col?.width;
      }
    });
    worksheet.addRow(rowValue);
  });

  workbook.xlsx.writeBuffer().then((buffer) => {
    writeFile(fileName, buffer);
  });
};
