import { Box, Grid, TableBody, TableCell, TableRow } from '@mui/material';
import PropTypes from 'prop-types';
import DashboardNavbar from 'components/DashboardNavbar';
import DashboardLayout from 'layouts/DashboardLayout';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import BasicTable from 'components/BasicTable';
import MDButton from 'components/Button';
import Checkbox from '@mui/material/Checkbox';
import Breadcrumbs from 'components/Breadcrumbs';
import Dropdown from 'components/Dropdown';
import { useDispatch, useSelector } from 'react-redux';
import { InventorySelectors } from 'redux/InventoryRedux';
import WidgetActions from 'redux/WidgetRedux';
import { API } from 'constant';
import MDBox from 'components/MDBox';
import { WidgetSelectors } from 'redux/WidgetRedux';
import ProductActions from 'redux/ProductsRedux';
import { ProductSelectors } from 'redux/ProductsRedux';
import InventoryActions from 'redux/InventoryRedux';
import QRcode from 'components/QRcode';
import PrintWidgetLabel from 'components/PrintWidgetLabel';
import ReactToPrint from 'react-to-print';
import MDTypography from 'components/MDTypography';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const useStyles = makeStyles({
  size: {
    marginTop: '40px'
  },
  nodataStyle: {
    height: '200px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '600%'
  },
  labelSize: {
    fontSize: '16px',
    letterSpacing: '0.01em',
    color: '#000',
    marginBottom: '4px'
  },
  customLabel: {
    backgroundColor: '#fff'
  },
  buttondiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '52px 0px'
  },
  show: {
    display: 'block'
  },
  hide: {
    display: 'none'
  }
});

const headCells = [
  {
    id: 'inventory',
    label: 'Inventory'
  },
  {
    id: 'Family',
    label: 'Family'
  },
  {
    id: 'Subfamily',
    label: 'Subfamily'
  },
  {
    id: 'formalName',
    label: 'Name'
  },
  {
    id: 'manufacturer',
    label: 'Manufacture'
  },
  {
    id: 'size',
    label: 'Size'
  },
  {
    id: 'barcode number',
    label: 'Barcode number'
  },
  {
    id: 'Barcode',
    label: 'Barcode'
  }
];
const headCellsNew = [
  {
    id: 'Inventory Name',
    label: 'Inventory Name'
  },
  {
    id: 'formalName',
    label: 'Item Name'
  },
  {
    id: 'description',
    label: 'Item Description'
  },
  {
    id: 'Barcode Number',
    label: 'Barcode Number'
  },
  {
    id: 'Barcode',
    label: 'Barcode'
  }
];
export const ComponentToPrint = React.forwardRef((props, ref) => {
  return <div ref={ref}>{props.children}</div>;
});

ComponentToPrint.propTypes = {
  children: PropTypes.any
};

function WidgetLabel() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const printableRef = React.useRef();

  const [labelData, setLabelData] = useState([]);
  const [inventoryId, setInventoryId] = useState('');
  const [familyId, setFamilyId] = useState('');
  const [subFamilyId, setSubFamilyId] = useState('');  
  const [printLabel, showPrintPreview] = useState(false);

  const inventoryData = useSelector(InventorySelectors.getInventoryDetail);
  const familyData = useSelector(WidgetSelectors.getWidgetFamiliesByInventoryId(inventoryId));
  const subFamilyData = useSelector(WidgetSelectors.getWidgetsByParentId(familyId));
  const productData = useSelector(ProductSelectors.getProductDetail);

  React.useEffect(() => {
    dispatch(
      InventoryActions.getInventoryAction({
        loader: 'loading-request',
        slug: API.GET_INVENTORY,
        method: 'get'
      })
    );
  }, []);

  const inventoryChange = (event) => {
    const id = event.target.value;
    setInventoryId(id);
    setFamilyId('');
    setSubFamilyId('');
    dispatch(
      WidgetActions.widgetRequest({
        loader: 'loading-request',
        slug: `${API.GET_WIDGET_FAMILY_BY_INVENTORY}${id}`,
        method: 'get'
      })
    );
  };

  const familyChange = (event) => {
    const id = event.target.value;
    setFamilyId(id);
    setSubFamilyId('');
    dispatch(
      WidgetActions.widgetRequest({
        loader: 'loading-request',
        slug: `${API.GET_WIDGET_FAMILY_BY_INVENTORY}${id}`,
        method: 'get'
      })
    );
  };

  const subFamilyChange = (event) => {
    const id = event.target.value;
    setSubFamilyId(id);
    dispatch(
      WidgetActions.widgetRequest({
        loader: 'loading-request',
        slug: `${API.GET_WIDGET_FAMILY_BY_INVENTORY}${id}`,
        method: 'get'
      })
    );
  };

  const filterHandler = () => {
    dispatch(
      ProductActions.getProductByIdAction({
        loader: 'loading-request',
        slug: `${API.GET_PRODUCT_BY_ID}${inventoryId}&perPage=1000`,
        method: 'get'
      })
    );
  };

  const getTableItem = (e, updatedItem) => {
    showPrintPreview(false);
    if (e.target.checked) {
      setLabelData([...labelData, updatedItem]);
    } else {
      const filterData = labelData.filter((item) => item._id !== updatedItem._id);
      setLabelData(filterData);
    }
  };

  const renderPrintPreview = () => {
    showPrintPreview(true);
  };

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <Breadcrumbs
          title="Search And Print Location Labels"
          route={[
            { name: 'Home', path: '/home' },
            { name: 'Setup', path: '/setup' },
            { name: 'Labeling', path: '/setup/labeling' },
            { name: 'Widget Label' }
          ]}
        />
        <MDBox px={5} py={5}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <Dropdown
                label="Select Inventory"
                dropdownData={inventoryData}
                value={inventoryId}
                onChange={inventoryChange}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Dropdown
                label="Select Family"
                dropdownData={familyData}
                value={familyId}
                onChange={familyChange}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Dropdown
                label="Select Sub Family"
                value={subFamilyId}
                dropdownData={subFamilyData}
                onChange={subFamilyChange}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <MDButton
                color="primary"
                sx={{ minWidth: '100%', marginTop: '50px', padding: '13px 40px' }}
                onClick={() => filterHandler()}
              >
                {'Filter'}
              </MDButton>
            </Grid>
          </Grid>
          <MDTypography variant="h4" className={classes.size}>
            Select Rows To Print
          </MDTypography>
          <Box
            sx={{
              marginTop: '24px',
              backgroundColor: '#FFFFFF',
              overflowX: 'hidden',
              overflowY: 'auto',
              height: '60vh'
            }}
          >
            <BasicTable
              headCells={headCells}
              records={productData?.result || []}
              backgroundColor="#E5E5E5"
              color="#8D8D8D"
            >
              <TableBody>
                {productData?.result?.length ? (
                  productData?.result.map((item) => (
                    <TableRow key={item._id}>
                      <TableCell>
                        <Checkbox
                          {...label}
                          sx={{ marginRight: '2px' }}
                          onChange={(e) => getTableItem(e, item)}
                        />
                        {item?.inventory?.name}
                      </TableCell>
                      <TableCell>
                        {item?.widgetfamily?.parent?.name || item?.widgetfamily?.name || '-'}
                      </TableCell>
                      <TableCell>
                        {item?.widgetfamily?.parent?.name ? item?.widgetfamily?.name || '-' : '-'}
                      </TableCell>
                      <TableCell>{item?.formalName}</TableCell>
                      <TableCell>{item?.manufacturer}</TableCell>
                      <TableCell>{item?.size}</TableCell>
                      <TableCell>{item?._id}</TableCell>
                      <TableCell>
                        <QRcode
                          payload={JSON.stringify({ type: 'Widget', id: item._id })}
                          width={100}
                          height={100}
                        />
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow className={classes.nodataStyle}>No Data</TableRow>
                )}
              </TableBody>
            </BasicTable>
          </Box>
          <MDTypography variant="h4" className={classes.size}>
            Rows Selected For Printing
          </MDTypography>
          <Box sx={{ marginTop: '24px', backgroundColor: '#FFFFFF' }}>
            <BasicTable
              headCells={headCellsNew}
              records={labelData}
              backgroundColor="#E5E5E5"
              color="#8D8D8D"
            >
              <TableBody>
                {labelData &&
                  labelData.map((item) => (
                    <TableRow key={item._id}>
                      <TableCell>{item?.inventory?.name}</TableCell>
                      <TableCell>{item.formalName}</TableCell>
                      <TableCell>{item.description}</TableCell>
                      <TableCell>{item._id}</TableCell>
                      <TableCell>
                        <QRcode
                          payload={JSON.stringify({ type: 'Widget', id: item._id })}
                          width={100}
                          height={100}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </BasicTable>
          </Box>
          <div className={classes.buttondiv}>
            <MDButton
              color="primary"
              style={{ marginRight: '12px' }}
              onClick={() => renderPrintPreview()}
            >
              {'View Labels'}
            </MDButton>
            <ReactToPrint
              trigger={() => <MDButton color="primary">{'Print PDF'}</MDButton>}
              content={() => printableRef.current}
              documentTitle={`Labels-${parseInt(new Date().getTime() / 1000)}`}
            />
          </div>
          <div className={labelData && printLabel ? classes.show : classes.hide}>
            <ComponentToPrint ref={printableRef}>
              <PrintWidgetLabel info={labelData} />
            </ComponentToPrint>
          </div>
        </MDBox>
      </DashboardLayout>
    </>
  );
}

export default WidgetLabel;
