import DashboardNavbar from 'components/DashboardNavbar';
import DashboardLayout from 'layouts/DashboardLayout';
import Breadcrumbs from 'components/Breadcrumbs';
import TileBasic from 'components/TileBasic';

const data = [
  {
    name: 'PPR Report',
    path: '/reports/ppr',
    disabled: false
  },
  {
    name: 'CCR Report',
    path: '/reports/ccr',
    disabled: false
  },
  // {
  //   name: 'Equipment Report',
  //   path: '/reports',
  //   disabled: true
  // },
  // {
  //   name: 'Inventory Report',
  //   path: '/reports',
  //   disabled: true
  // },
  // {
  //   name: 'User Access Report',
  //   path: '/reports',
  //   disabled: true
  // },
  // {
  //   name: 'Product Report',
  //   path: '/reports',
  //   disabled: true
  // },
  // {
  //   name: 'Adhoc Report',
  //   path: '/reports',
  //   disabled: true
  // },
  {
    name: 'Inventory Reservation Report',
    path: '/reports/inventory-reservation',
    disabled: false
  },
  {
    name: 'Inventory Report',
    path: '/reports/all-inventory',
    disabled: false
  }
];

const ReportScreen = () => {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Breadcrumbs
        title="Warehouse Management System Reports"
        route={[{ name: 'Home', path: '/home' }, { name: 'Reports' }]}
      />
      <TileBasic tiles={data} />
    </DashboardLayout>
  );
};

export default ReportScreen;
