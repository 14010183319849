import PropTypes from 'prop-types';
const EquipmentIcon = ({ width = 96, height = 96, color = '#007aff', ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 96 96"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M47.9994 53.1999C47.4794 53.1999 46.9594 53.08 46.4794 52.8L11.1594 32.3601C9.71941 31.5201 9.23933 29.68 10.0793 28.24C10.9193 26.8 12.7193 26.3199 14.1993 27.1599L47.9994 46.72L81.5993 27.28C83.0394 26.44 84.8793 26.9601 85.7193 28.3601C86.5593 29.8001 86.0394 31.64 84.6394 32.48L49.5594 52.8C49.0394 53.04 48.5194 53.1999 47.9994 53.1999Z"
      fill={color}
    />
    <path
      d="M48 89.4402C46.36 89.4402 45 88.0802 45 86.4402V50.1602C45 48.5202 46.36 47.1602 48 47.1602C49.64 47.1602 51 48.5202 51 50.1602V86.4402C51 88.0802 49.64 89.4402 48 89.4402Z"
      fill={color}
    />
    <path
      d="M48.0005 91C44.4805 91 41.0005 90.2399 38.2405 88.7199L16.8805 76.8401C11.0805 73.6401 6.56055 65.92 6.56055 59.28V36.6799C6.56055 30.0399 11.0805 22.3601 16.8805 19.1201L38.2405 7.28C43.7205 4.24 52.2805 4.24 57.7605 7.28L79.1205 19.1599C84.9205 22.3599 89.4404 30.0799 89.4404 36.7199V59.32C89.4404 65.96 84.9205 73.6399 79.1205 76.8799L57.7605 88.7199C55.0005 90.2399 51.5205 91 48.0005 91ZM48.0005 11C45.4805 11 43.0005 11.52 41.1605 12.52L19.8005 24.3999C15.9605 26.5599 12.5605 32.2799 12.5605 36.7199V59.32C12.5605 63.72 15.9605 69.4799 19.8005 71.6399L41.1605 83.52C44.8005 85.56 51.2005 85.56 54.8405 83.52L76.2004 71.6399C80.0404 69.4799 83.4404 63.76 83.4404 59.32V36.7199C83.4404 32.3199 80.0404 26.5599 76.2004 24.3999L54.8405 12.52C53.0005 11.52 50.5205 11 48.0005 11Z"
      fill={color}
    />
    <path
      d="M68.0007 55.9602C66.3607 55.9602 65.0007 54.6002 65.0007 52.9602V40.0803L28.5207 19.0403C27.0807 18.2003 26.6007 16.3602 27.4407 14.9602C28.2807 13.5202 30.0807 13.0401 31.5207 13.8801L69.4807 35.8003C70.4007 36.3203 71.0007 37.3201 71.0007 38.4001V53.0403C71.0007 54.6003 69.6407 55.9602 68.0007 55.9602Z"
      fill={color}
    />
  </svg>
);

export default EquipmentIcon;
EquipmentIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string
};
