import React from 'react';
import DashboardNavbar from 'components/DashboardNavbar';
import DashboardLayout from 'layouts/DashboardLayout';
import SetupIcon from 'assets/images/SetupIcon';
import SearchInventoryIcon from 'assets/images/SearchInventoryIcon';
import ReportsIcon from 'assets/images/ReportsIcon';
import ScanIcon from 'assets/images/ScanIcon';
import TileBasic from 'components/TileBasic';
import Breadcrumbs from 'components/Breadcrumbs';
import { useSelector } from 'react-redux';
import { AuthSelectors } from 'redux/AuthRedux';

/*eslint no-mixed-spaces-and-tabs: ["error", "smart-tabs"]*/
function HomepageScreen() {
  const data = [
    {
      name: 'Search Inventory',
      path: '/searchinventory',
      icon: <SearchInventoryIcon color="#007AFF" />
    },
    {
      name: 'Scan',
      path: '/scan',
      icon: <ScanIcon color="#007AFF" />
    },
    {
      name: 'Setup',
      path: '/setup',
      icon: <SetupIcon color="#007AFF" />
    },
    {
      name: 'Reports',
      path: '/reports',
      disabled: !useSelector(AuthSelectors.hasUIModule('Reports')),
      icon: <ReportsIcon color="#007AFF" />
    },
    {
      name: 'Reservations',
      path: '/reservation',
      icon: <ReportsIcon color="#007AFF" />
    }
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Breadcrumbs title="Warehouse Management System" route={[{ name: 'Home', path: '/home' }]} />
      <TileBasic tiles={data} />
    </DashboardLayout>
  );
}
export default HomepageScreen;
