/* eslint-disable complexity */
import React from 'react';
import { Box, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import MDButton from 'components/Button';
import MDInput from 'components/MDInput';
import { makeStyles } from '@mui/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import CrossIcon from 'assets/images/CrossIcon';
import { useFormik } from 'formik';
import schema from 'services/ValidationServices';
import { useDispatch } from 'react-redux';
import PprActions from 'redux/PprRedux';
import { API } from 'constant';

const useStyles = makeStyles(() => ({
  inputLabel: {
    fontSize: '16px',
    letterSpacing: '0.01em',
    color: '#000',
    marginBottom: '4px',
    fontWeight: 'normal',
    '& .MuiFormControlLabel-label': {
      fontWeight: 'normal',
      fontSize: '16px',
      color: '#000'
    }
  },
  centreContent: {
    justifyContent: 'center'
  },
  fullWidth: {
    width: '100%',
    borderColor: '#d2d6da',
    borderRadius: '0.375rem'
  },
  pickupBtn: {
    '&:hover': {
      backgroundColor: '#ff9330',
      color: '#fff'
    }
  },
  cursorPointer: {
    cursor: 'pointer',
    display: 'flex',
    alignSelf: 'end',
    margin: '0'
  }
}));

function ScanAdjust({ show, setShow, inventoryItem }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      widgetname: inventoryItem?.inventoryData.widgetName,
      type: inventoryItem?.inventoryData.type,
      size: inventoryItem?.inventoryData.size,
      color: inventoryItem?.inventoryData.color,
      removedDamagedQuantity: '',
      lastrecordedquantity: inventoryItem.inventoryData.availQuan,
      recountedQuantity: '',
      damagedQuantity: '',
      newAdjustedQuantity: 0,
      totaladjustment: 0,
      varianceObservedQuantity: 0
    },
    validationSchema: schema.adjust,
    onSubmit: (values) => {
      dispatch(
        PprActions.pickAction({
          loader: 'loading-request',
          slug: `${API.ADJUST.replace(':id', inventoryItem.inventoryData.inventoryId)}`,
          method: 'post',
          data: {
            recountedQuantity: values.recountedQuantity,
            damagedQuantity: values.damagedQuantity,
            subLevel: inventoryItem.locationId,
            removedDamagedQuantity: values.removedDamagedQuantity
          },
          callback: () => {
            formik.resetForm();
            setShow();
          }
        })
      );
    }
  });

  return (
    <div>
      <Dialog open={show} maxWidth="md" onClose={setShow}>
        <MDButton
          className={classes.cursorPointer}
          sx={{ padding: '15px', minWidth: '14px' }}
          onClick={setShow}
        >
          <CrossIcon />
        </MDButton>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent>
            <Grid
              container
              spacing={2}
              sx={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start' }}
            >
              <Grid item container xs={6} spacing={2}>
                <Grid item xs={12}>
                  <Box component="div" sx={{}} className={classes.inputLabel}>
                    Widget Name
                  </Box>
                  <MDInput
                    disabled
                    fullWidth
                    name="widgetname"
                    type="text"
                    variant="outlined"
                    value={formik.values.widgetname}
                    error={formik.touched.widgetname && Boolean(formik.errors.widgetname)}
                    helperText={
                      formik.touched.widgetname &&
                      formik.errors.widgetname && (
                        <div style={{ color: 'red' }}>{formik.errors.commonName}</div>
                      )
                    }
                    onChange={formik.handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box component="div" sx={{}} className={classes.inputLabel}>
                    Type
                  </Box>
                  <MDInput
                    disabled
                    fullWidth
                    name="type"
                    type="text"
                    variant="outlined"
                    value={formik.values.type}
                    error={formik.touched.type && Boolean(formik.errors.type)}
                    helperText={
                      formik.touched.type &&
                      formik.errors.type && <div style={{ color: 'red' }}>{formik.errors.type}</div>
                    }
                    onChange={formik.handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box component="div" sx={{}} className={classes.inputLabel}>
                    Size
                  </Box>
                  <MDInput
                    disabled
                    fullWidth
                    name="size"
                    type="text"
                    variant="outlined"
                    value={formik.values.size}
                    error={formik.touched.size && Boolean(formik.errors.size)}
                    helperText={
                      formik.touched.size &&
                      formik.errors.size && <div style={{ color: 'red' }}>{formik.errors.size}</div>
                    }
                    onChange={formik.handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box component="div" sx={{}} className={classes.inputLabel}>
                    Color
                  </Box>
                  <MDInput
                    disabled
                    fullWidth
                    name="color"
                    type="text"
                    variant="outlined"
                    value={formik.values.color}
                    error={formik.touched.color && Boolean(formik.errors.color)}
                    helperText={
                      formik.touched.color &&
                      formik.errors.color && (
                        <div style={{ color: 'red' }}>{formik.errors.color}</div>
                      )
                    }
                    onChange={formik.handleChange}
                  />
                </Grid>
              </Grid>

              <Grid item container xs={6} spacing={2}>
                <Grid item xs={12}>
                  <Box component="div" sx={{}} className={classes.inputLabel}>
                    Last Recorded Quantity
                  </Box>
                  <MDInput
                    fullWidth
                    disabled
                    name="lastrecordedquantity"
                    type="number"
                    variant="outlined"
                    value={formik.values.lastrecordedquantity}
                    error={
                      formik.touched.lastrecordedquantity &&
                      Boolean(formik.errors.lastrecordedquantity)
                    }
                    helperText={
                      formik.touched.lastrecordedquantity &&
                      formik.errors.lastrecordedquantity && (
                        <div style={{ color: 'red' }}>{formik.errors.lastrecordedquantity}</div>
                      )
                    }
                    onChange={formik.handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box component="div" sx={{}} className={classes.inputLabel}>
                    Quantity After Recount
                  </Box>
                  <MDInput
                    fullWidth
                    name="recountedQuantity"
                    type="text"
                    variant="outlined"
                    value={formik.values.recountedQuantity}
                    error={
                      formik.touched.recountedQuantity && Boolean(formik.errors.recountedQuantity)
                    }
                    helperText={
                      formik.touched.recountedQuantity &&
                      formik.errors.recountedQuantity && (
                        <div style={{ color: 'red' }}>{formik.errors.recountedQuantity}</div>
                      )
                    }
                    onChange={(e) => {
                      const recountedQuantity = Number(e.target.value);
                      const varianceObservedQuantity = Number(
                        recountedQuantity - formik.values.lastrecordedquantity
                      );
                      const damagedQuantity = formik.values.removedDamagedQuantity
                        ? formik.values.damagedQuantity
                        : 0;
                      const totaladjustment = Number(
                        varianceObservedQuantity - damagedQuantity
                      );
                      const newAdjustedQuantity = Number(
                        recountedQuantity - damagedQuantity
                      );
                      formik.setValues({
                        ...formik.values,
                        recountedQuantity,
                        varianceObservedQuantity,
                        totaladjustment,
                        newAdjustedQuantity
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box component="div" sx={{}} className={classes.inputLabel}>
                    Quantity Variance Observed
                  </Box>
                  <MDInput
                    fullWidth
                    disabled
                    name="varianceObservedQuantity"
                    type="number"
                    variant="outlined"
                    value={formik.values.varianceObservedQuantity}
                    error={
                      formik.touched.varianceObservedQuantity &&
                      Boolean(formik.errors.varianceObservedQuantity)
                    }
                    helperText={
                      formik.touched.varianceObservedQuantity &&
                      formik.errors.varianceObservedQuantity && (
                        <div style={{ color: 'red' }}>{formik.errors.varianceObservedQuantity}</div>
                      )
                    }
                    onChange={formik.handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box component="div" sx={{}} className={classes.inputLabel}>
                    Damage Quantity
                  </Box>
                  <MDInput
                    fullWidth
                    name="damagedQuantity"
                    type="text"
                    variant="outlined"
                    value={formik.values.damagedQuantity}
                    error={formik.touched.damagedQuantity && Boolean(formik.errors.damagedQuantity)}
                    helperText={
                      formik.touched.damagedQuantity &&
                      formik.errors.damagedQuantity && (
                        <div style={{ color: 'red' }}>{formik.errors.damagedQuantity}</div>
                      )
                    }
                    onChange={(e) => {
                      const value = Number(e.target.value);
                      if (value >= 0) {
                        formik.setFieldTouched('damagedQuantity', false);
                        if (!formik.values.recountedQuantity) {
                          formik.setFieldValue('damagedQuantity', value, true);
                          formik.setFieldError('recountedQuantity', 'Required');
                          formik.setFieldTouched('recountedQuantity', true);
                        } else {
                          const damagedQuantity = Number(value);
                          const reduceQuantity = formik.values.removedDamagedQuantity
                            ? damagedQuantity
                            : 0;
                          const totaladjustment = Number(
                            formik.values.varianceObservedQuantity - reduceQuantity
                          );
                          const newAdjustedQuantity = Number(
                            formik.values.recountedQuantity - reduceQuantity
                          );
                          formik.setValues({
                            ...formik.values,
                            damagedQuantity,
                            totaladjustment,
                            newAdjustedQuantity
                          });
                        }
                      }
                    }}
                  />
                </Grid>
              </Grid>

              {formik.values.damagedQuantity > 0 && (
                <Grid item container xs={6} spacing={2}>
                  <Grid item xs={12}>
                    <FormControl sx={{ paddingTop: '16px' }}>
                      <FormLabel id="demo-radio-buttons-group-label" className={classes.inputLabel}>
                        Please Remove damaged items from here
                      </FormLabel>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="removedDamagedQuantity"
                        error={
                          formik.touched.removedDamagedQuantity &&
                          Boolean(formik.errors.removedDamagedQuantity)
                        }
                        onChange={(e) => {
                          const removedDamagedQuantity = e.target.value === 'yes';
                          const damagedQuantity = removedDamagedQuantity
                            ? formik.values.damagedQuantity
                            : 0;
                          const totaladjustment = Number(
                            formik.values.varianceObservedQuantity - damagedQuantity
                          );
                          const newAdjustedQuantity = Number(
                            formik.values.recountedQuantity - damagedQuantity
                          );
                          formik.setValues({
                            ...formik.values,
                            removedDamagedQuantity,
                            totaladjustment,
                            newAdjustedQuantity
                          });
                        }}
                      >
                        <FormControlLabel
                          value="yes"
                          control={<Radio />}
                          label="Yes, removed"
                          className={classes.inputLabel}
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio />}
                          label="No, did not remove"
                          className={classes.inputLabel}
                        />
                      </RadioGroup>
                      <FormHelperText>
                        {formik.touched.removedDamagedQuantity &&
                          formik.errors.removedDamagedQuantity && (
                            <div style={{ color: 'red' }}>
                              {formik.errors.removedDamagedQuantity}
                            </div>
                          )}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
              )}

              <Grid item container xs={6} spacing={2}>
                <Grid item xs={12}>
                  <Box component="div" sx={{}} className={classes.inputLabel}>
                    Total Adjustment
                  </Box>
                  <MDInput
                    fullWidth
                    disabled
                    name="totaladjustment"
                    type="number"
                    variant="outlined"
                    value={formik.values.totaladjustment}
                    error={formik.touched.totaladjustment && Boolean(formik.errors.totaladjustment)}
                    helperText={
                      formik.touched.totaladjustment &&
                      formik.errors.totaladjustment && (
                        <div style={{ color: 'red' }}>{formik.errors.totaladjustment}</div>
                      )
                    }
                    onChange={formik.handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box component="div" sx={{}} className={classes.inputLabel}>
                    New Adjusted Quantity Here
                  </Box>
                  <MDInput
                    fullWidth
                    disabled
                    name="newAdjustedQuantity"
                    type="number"
                    variant="outlined"
                    value={formik.values.newAdjustedQuantity}
                    error={
                      formik.touched.newAdjustedQuantity &&
                      Boolean(formik.errors.newAdjustedQuantity)
                    }
                    helperText={
                      formik.touched.newAdjustedQuantity &&
                      formik.errors.newAdjustedQuantity && (
                        <div style={{ color: 'red' }}>{formik.errors.newAdjustedQuantity}</div>
                      )
                    }
                    onChange={formik.handleChange}
                  />
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className={classes.centreContent}>
            <MDButton size="medium" color="primary" type="submit">
              COMPLETE ADJUSTMENT
            </MDButton>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
ScanAdjust.propTypes = {
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
  inventoryItem: PropTypes.shape({
    inventoryData: PropTypes.shape({
      inventoryId: PropTypes.string.isRequired,
      widgetName: PropTypes.string.isRequired,
      availQuan: PropTypes.number,
      type: PropTypes.string.isRequired,
      size: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
      images: PropTypes.array.isRequired
    }),
    locationId: PropTypes.string.isRequired
  }).isRequired,
  onChangeInventoryItem: PropTypes.func
};

export default ScanAdjust;
