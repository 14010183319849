import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import _ from 'underscore';
import { getFetchingValue, getErrorValue } from '../services/Utils';

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  pickAction: ['payload'],
  pickSuccess: ['data'],
  pickFailure: ['error']
});

export const PprTypes = Types;
const PprActions = Creators;
export default PprActions;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  pick: null,
  put: null,
  fetching: [],
  error: {}
});

/* ------------- Selectors ------------- */
export const PprSelectors = {
  getPickData: (state) => state.ppr.pick,
  fetching: (state) => state.fetching
};

/* ------------- Reducers ------------- */
export const onPickAction = (state, { payload }) =>
  state.merge({
    fetching: _.uniq([...state.fetching, payload?.loader]),
    error: getErrorValue(state?.error, payload?.loader)
  });

export const onPickSuccess = (state, { data }) =>
  state.merge({
    fetching: getFetchingValue(state.fetching, data?.loader),
    error: getErrorValue(state?.error, data?.loader),
    pick: data.pick
  });

export const onPickFailure = (state, { error }) =>
  state.merge({
    fetching: _.without(state.fetching, error?.loader),
    error: { ...state.error, [error?.loader]: error?.error }
  });

/* ------------- Hookup Reducers To Types ------------- */
export const pprReducer = createReducer(INITIAL_STATE, {
  [Types.PICK_ACTION]: onPickAction,
  [Types.PICK_SUCCESS]: onPickSuccess,
  [Types.PICK_FAILURE]: onPickFailure
});
