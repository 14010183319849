import React from 'react';
import DashboardNavbar from 'components/DashboardNavbar';
import DashboardLayout from 'layouts/DashboardLayout';
import Breadcrumbs from 'components/Breadcrumbs';
import { Box, Grid } from '@mui/material';
import MDButton from 'components/Button';
import MDInput from 'components/MDInput';
import { makeStyles } from '@mui/styles';
import Avatar from '@mui/material/Avatar';
import MDTypography from 'components/MDTypography';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { API } from 'constant';
import PickupBase from 'modals/ScanPickUp';
import CompletePutting from 'modals/ScanPutting';
import ScanReserve from 'modals/ScanReserve';
import QRcode from 'components/QRcode';
import { ItemSelectors } from 'redux/ItemRedux';
import ItemActions from 'redux/ItemRedux';

const useStyles = makeStyles(() => ({
  inputLabel: {
    fontSize: '16px',
    letterSpacing: '0.01em',
    color: '#000',
    marginBottom: '4px',
    paddingTop: '16px'
  },
  fullWidth: {
    width: '100%',
    borderColor: '#d2d6da',
    borderRadius: '0.375rem'
  },
  alignRight: {
    marginLeft: 'auto'
  },
  fontNormal: {
    fontWeight: 'normal',
    '& .MuiInputLabel-root': {
      fontWeight: 'normal',
      fontSize: '16px',
      letterSpacing: '0.01em',
      color: '#000',
      marginBottom: '4px',
      paddingTop: '16px',
      lineHeight: '26px',
      paddingBottom: '0'
    }
  },
  tableBox: {
    border: '1px solid #ccc',
    borderRadius: '5px',
    padding: '10px'
  },
  gridStyle: {
    '& .MuiGrid-item': {
      paddingLeft: '0',
      marginRight: '15px'
    },
    '& .MuiGrid-item:last-child': {
      marginRight: '5px'
    }
  },
  gridStyleContent: {
    '& .MuiGrid-item': {
      padding: '5px',
      marginRight: '15px'
    },
    '& .MuiGrid-item:last-child': {
      marginRight: '5px'
    }
  }
}));

const PickupPutReserve = () => {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();

  const [item, setItem] = React.useState({});
  // const [inventoryList, setInventoryList] = React.useState([]);
  const [warehouseData, setWarehouseData] = React.useState({});

  const [loader, setLoader] = React.useState(true);
  const [loadModal, setLoadModal] = React.useState(false);
  const [modalData, setModalData] = React.useState(null);

  const [showPickModal, setShowPickModal] = React.useState(false);
  const [showPutModal, setShowPutModal] = React.useState(false);
  const [showReserveModal, setShowReserveModal] = React.useState(false);

  const scanData = useSelector(ItemSelectors.getPprData);
  React.useEffect(() => {
    dispatch(
      ItemActions.itemPprRequest({
        loader: 'location-request',
        slug: `${API.GET_ITEM_FOR_TRANSACTION_PPR.replace(':id', location.state.id)}`,
        method: 'get'
      })
    );
  }, [loadModal]);

  React.useEffect(() => {
    setItem(scanData);
    // setInventoryList(scanData.locations);
    setWarehouseData(getWarehouseData(scanData.locations));
    if (loadModal) {
      if (showReserveModal) {
        setModalData(getReserveModalData(scanData));
      } else {
        setModalData(getModalData(scanData));
      }
    }
    setLoader(false);
  }, [scanData]);

  const getWarehouseData = (locations) => {
    return locations?.reduce((acc, loc) => {
      return {
        ...acc,
        [loc.warehouse._id]: [...(acc[loc.warehouse._id] || []), loc]
      };
    }, {});
  };

  const getModalData = (currentItem) => {
    const { locations: currentList } = currentItem;
    return {
      inventoryData: {
        inventoryId: currentItem?._id,
        widgetName: currentItem?.commonName,
        type: currentItem?.type,
        size: currentItem?.size,
        color: currentItem?.color,
        images: currentItem?.images
      },
      locationList: currentList?.map((location) => {
        return {
          subLevelId: location._id,
          availableQuantity: Math.min(location.quantity, currentItem.availableQuantity),
          warehouseId: location.warehouse._id
        };
      })
    };
  };

  const getReserveModalData = (currentItem) => {
    return {
      inventoryData: {
        inventoryId: currentItem?._id,
        widgetName: currentItem?.commonName,
        availQuan: currentItem?.totalQuantity,
        type: currentItem?.type,
        size: currentItem?.size,
        color: currentItem?.color
      },
      locationList: warehouseData
    };
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Breadcrumbs
        title="Warehouse Management System Reports"
        route={[
          { name: 'Home', path: '/home' },
          { name: 'Scan', path: '/scan' },
          { name: item?.formalName }
        ]}
      />
      <Box className={loader ? 'loader' : ''}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            padding: '15px 48px',
            borderTop: '1px solid #ccc',
            borderBottom: '1px solid #ccc'
          }}
        >
          <Avatar
            src={item?.images?.[0]}
            alt="widget"
            sx={{ borderRadius: '0', width: '250px', height: 'auto' }}
          >
            {' '}
          </Avatar>
          <Box className={classes.alignRight}>
            <QRcode
              payload={JSON.stringify({ type: 'Widget', id: location.state.id })}
              width={100}
              height={100}
            ></QRcode>
          </Box>
        </Box>
        <Box sx={{ padding: '0 48px' }}>
          <Grid
            container
            spacing={2}
            sx={{ alignItems: 'flex-start', borderBottom: '1px solid #ccc', paddingBottom: '25px' }}
          >
            <Grid item container xs={12} md={6}>
              <Grid item container xs={12} md={12}>
                <Box component="div" sx={{}} className={classes.inputLabel}>
                  Name
                </Box>
                <MDInput
                  disabled
                  fullWidth
                  name="name"
                  type="text"
                  variant="outlined"
                  placeholder="pickup truck"
                  value={item?.commonName}
                />
              </Grid>
              <Grid item container xs={12} md={12} sx={{ flexDirection: 'column' }}>
                <Box component="div" sx={{}} className={classes.inputLabel}>
                  Description
                </Box>
                <MDInput
                  disabled
                  fullWidth
                  name="name"
                  type="text"
                  variant="outlined"
                  placeholder="pickup truck"
                  value={item?.description}
                />
              </Grid>
              <Grid item container xs={12} md={12}>
                <Grid item container xs={12} md={12} className={classes.fontNormal}>
                  <Box component="div" sx={{}} className={classes.inputLabel}>
                    Manufacturer
                  </Box>
                  <MDInput
                    disabled
                    fullWidth
                    name="name"
                    type="text"
                    variant="outlined"
                    placeholder="pickup truck"
                    value={item?.manufacturer}
                  />
                </Grid>
              </Grid>
              <Grid item container xs={12} md={12}>
                <Grid item container xs={12} md={12} className={classes.fontNormal}>
                  <Box component="div" sx={{}} className={classes.inputLabel}>
                    Type
                  </Box>
                  <MDInput
                    disabled
                    fullWidth
                    name="name"
                    type="text"
                    variant="outlined"
                    placeholder="pickup truck"
                    value={item?.type}
                  />
                </Grid>
              </Grid>
              <Grid item container xs={12} md={12}>
                <Grid item container xs={12} md={12} className={classes.fontNormal}>
                  <Box component="div" sx={{}} className={classes.inputLabel}>
                    Unit of Material
                  </Box>
                  <MDInput
                    disabled
                    fullWidth
                    name="name"
                    type="text"
                    variant="outlined"
                    placeholder="pickup truck"
                    value={item?.unitOfMaterial}
                  />
                </Grid>
              </Grid>
              <Grid item container xs={12} md={12}>
                <Box component="div" sx={{}} className={classes.inputLabel}>
                  Package Count
                </Box>
                <MDInput
                  disabled
                  fullWidth
                  name="packageCount"
                  type="text"
                  variant="outlined"
                  placeholder="pickup truck"
                  value={item?.packageCount}
                />
              </Grid>
            </Grid>
            <Grid item container xs={12} md={6}>
              <Grid item container xs={12} md={12}>
                <Box component="div" sx={{}} className={classes.inputLabel}>
                  Formal Name
                </Box>
                <MDInput
                  disabled
                  fullWidth
                  name="formalName"
                  type="text"
                  variant="outlined"
                  placeholder="pickup truck"
                  value={item?.formalName}
                />
              </Grid>
              <Grid item container xs={12} md={12}>
                <Grid item container xs={12} md={12} className={classes.fontNormal}>
                  <Box component="div" sx={{}} className={classes.inputLabel}>
                    Size
                  </Box>
                  <MDInput
                    disabled
                    fullWidth
                    name="name"
                    type="text"
                    variant="outlined"
                    placeholder="pickup truck"
                    value={item?.size}
                  />
                </Grid>
              </Grid>
              <Grid item container xs={12} md={12}>
                <Grid item container xs={12} md={12} className={classes.fontNormal}>
                  <Box component="div" sx={{}} className={classes.inputLabel}>
                    Color
                  </Box>
                  <MDInput
                    disabled
                    fullWidth
                    name="name"
                    type="text"
                    variant="outlined"
                    placeholder="pickup truck"
                    value={item?.color}
                  />
                </Grid>
              </Grid>
              <Grid item container xs={12} md={12}>
                <Box component="div" sx={{}} className={classes.inputLabel}>
                  Unit Cost
                </Box>
                <MDInput
                  disabled
                  fullWidth
                  name="unitCost"
                  type="text"
                  variant="outlined"
                  placeholder="pickup truck"
                  value={item?.unitCost}
                />
              </Grid>
              <Grid item container xs={12} md={12}>
                <Box component="div" sx={{}} className={classes.inputLabel}>
                  Count Per Pallete
                </Box>
                <MDInput
                  disabled
                  fullWidth
                  name="paletteCount"
                  type="text"
                  variant="outlined"
                  placeholder="pickup truck"
                  value={item?.countPerPallet}
                />
              </Grid>
              <Grid item container xs={12} md={12}>
                <Grid item container xs={12} md={12} className={classes.fontNormal}>
                  <Box component="div" sx={{}} className={classes.inputLabel}>
                    Family Association
                  </Box>
                  <MDInput
                    disabled
                    fullWidth
                    name="name"
                    type="text"
                    variant="outlined"
                    placeholder="pickup truck"
                    value={item?.widgetFamily?.parent?.name || item?.widgetFamily?.name}
                  />
                  {item?.widgetFamily?.parent?.name && (
                    <Grid item container xs={12} md={12} className={classes.fontNormal}>
                      <MDInput
                        disabled
                        fullWidth
                        name="name"
                        type="text"
                        variant="outlined"
                        placeholder="pickup truck"
                        value={item?.widgetFamily?.name}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Box sx={{ padding: '5px 0 5px 25px', maxWidth: '50%', marginLeft: '-8px' }}>
            <Grid
              container
              spacing={2}
              sx={{ alignItems: 'flex-start', mt: 0, display: 'flex', flexWrap: 'nowrap' }}
              className={classes.gridStyle}
            >
              <Grid item xs={12} md={4} spacing={2}>
                <MDTypography sx={{ fontSize: '16px', paddingLeft: '0' }}>
                  Total in Inventory{' '}
                </MDTypography>
              </Grid>
              <Grid item xs={12} md={4} spacing={2}>
                <MDTypography sx={{ fontSize: '16px', paddingLeft: '0' }}>
                  Total Reserved
                </MDTypography>
              </Grid>
              <Grid item xs={12} md={4} spacing={2} sx={{ mr: 0 }}>
                <MDTypography sx={{ fontSize: '16px', paddingLeft: '0' }}>
                  Total Available{' '}
                </MDTypography>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              sx={{ alignItems: 'flex-start', mt: 0, display: 'flex', flexWrap: 'nowrap' }}
              className={classes.gridStyleContent}
            >
              <Grid item xs={12} md={4} className={classes.tableBox} spacing={2}>
                <MDTypography sx={{ fontSize: '16px' }}>{item?.totalQuantity} </MDTypography>
              </Grid>
              <Grid item xs={12} md={4} className={classes.tableBox} spacing={2}>
                <MDTypography sx={{ fontSize: '16px' }}>{item?.reservedQuantity}</MDTypography>
              </Grid>
              <Grid item xs={12} md={4} className={classes.tableBox} spacing={2} sx={{ mr: 0 }}>
                <MDTypography sx={{ fontSize: '16px' }}>{item?.availableQuantity}</MDTypography>
              </Grid>
            </Grid>
          </Box>
          {Object.keys(warehouseData).map((warehouseId) => (
            <>
              <MDTypography sx={{ fontSize: '16px', marginTop: '15px' }}>
                {warehouseData[warehouseId][0]?.warehouse?.name}
              </MDTypography>
              <Box
                key={warehouseId}
                sx={{
                  border: '1px solid #ccc',
                  padding: '5px 0 5px 25px',
                  maxWidth: '50%'
                }}
              >
                <Grid
                  container
                  spacing={2}
                  sx={{ alignItems: 'flex-start', mt: 0, display: 'flex', flexWrap: 'nowrap' }}
                  className={classes.gridStyle}
                >
                  <Grid item xs={12} md={4} spacing={2}>
                    <MDTypography sx={{ fontSize: '16px', paddingLeft: '0' }}>
                      Location
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12} md={4} spacing={2}>
                    <MDTypography sx={{ fontSize: '16px', paddingLeft: '0' }}>Total</MDTypography>
                  </Grid>
                  <Grid item xs={12} md={4} spacing={2} sx={{ mr: 0 }}>
                    <MDTypography sx={{ fontSize: '16px', paddingLeft: '0' }}>
                      Accuracy
                    </MDTypography>
                  </Grid>
                </Grid>
                {warehouseData[warehouseId]?.map((inventory) => (
                  <>
                    <Grid
                      container
                      spacing={2}
                      sx={{
                        alignItems: 'flex-start',
                        mt: 1,
                        mb: 1,
                        display: 'flex',
                        flexWrap: 'nowrap'
                      }}
                      className={classes.gridStyleContent}
                    >
                      <Grid item xs={12} md={4} className={classes.tableBox} spacing={2}>
                        {/* <MDTypography sx={{ fontSize: '16px' }}> */}
                        <Box className={classes.alignRight}>
                          <MDTypography sx={{ fontSize: '16px' }}>{inventory.label}</MDTypography>
                        </Box>
                        {/* </MDTypography> */}
                      </Grid>
                      <Grid item xs={12} md={4} className={classes.tableBox} spacing={2}>
                        <MDTypography sx={{ fontSize: '16px' }}>{inventory.quantity}</MDTypography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={4}
                        className={classes.tableBox}
                        spacing={2}
                        sx={{ mr: 0 }}
                      >
                        <MDTypography sx={{ fontSize: '16px' }}>
                          {inventory.outstandingVariance ? 'No' : 'Yes'}
                        </MDTypography>
                      </Grid>
                    </Grid>
                  </>
                ))}
              </Box>
            </>
          ))}
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '25px 0px'
          }}
        >
          <MDButton
            size="medium"
            color="warning"
            type="submit"
            sx={{ marginRight: '15px' }}
            onClick={() => {
              setShowPickModal(true);
              setLoadModal(true);
              setLoader(true);
            }}
          >
            PICK
          </MDButton>
          <MDButton
            size="medium"
            color="success"
            type="submit"
            sx={{ marginRight: '15px' }}
            onClick={() => {
              setShowPutModal(true);
              setLoadModal(true);
              setLoader(true);
            }}
          >
            PUT
          </MDButton>
          <MDButton
            size="medium"
            color="primary"
            type="submit"
            sx={{ marginRight: '15px' }}
            onClick={() => {
              setShowReserveModal(true);
              setLoadModal(true);
              setLoader(true);
            }}
          >
            RESERVE
          </MDButton>
        </Box>
        {modalData && (
          <>
            <PickupBase
              show={showPickModal}
              setShow={() => {
                setShowPickModal(false);
                setLoadModal(false);
                setModalData(null);
              }}
              inventoryItem={modalData}
            />
            <CompletePutting
              show={showPutModal}
              setShow={() => {
                setShowPutModal(false);
                setLoadModal(false);
                setModalData(null);
              }}
              inventoryItem={modalData}
            />
            <ScanReserve
              show={showReserveModal}
              setShow={() => {
                setShowReserveModal(false);
                setLoadModal(false);
                setModalData(null);
              }}
              inventoryItem={modalData}
            />
          </>
        )}
      </Box>
    </DashboardLayout>
  );
};

export default PickupPutReserve;
