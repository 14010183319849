import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import _ from 'underscore';
import { getFetchingValue, getErrorValue } from '../services/Utils';

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  getNotificationAction: ['payload'],
  getNotificationSuccess: ['data'],
  toggleReadNotificationAction: ['payload'],
  toggleReadNotificationSuccess: ['data'],
  dismissNotificationAction: ['payload'],
  dismissNotificationSuccess: ['data'],
  getNotificationFailure: ['error']
});

export const NotificationTypes = Types;
const NotificationActions = Creators;
export default NotificationActions;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  notifications: [],
  totalCount: 0,
  unreadCount: 0
});

/* ------------- Selectors ------------- */
export const NotificationSelectors = {
  allNotifications: (state) => state.notification.notifications,
  getTotalCount: (state) => state.notification.totalCount,
  getUnreadCount: (state) => state.notification.unreadCount
};

/* ------------- Reducers ------------- */
export const onGetNotificationAction = (state, { payload }) =>
  state.merge({
    fetching: _.uniq([state.fetching, payload?.loader]),
    error: getErrorValue(state?.error, payload?.loader)
  });

export const onGetNotificationSuccess = (state, { data }) =>
  state.merge({
    fetching: getFetchingValue(state.fetching, data?.loader),
    error: getErrorValue(state?.error, data?.loader),
    notifications: data.getNotificationDetail,
    totalCount: data.totalCount,
    unreadCount: data.unreadCount
  });

export const onToggleReadNotificationAction = (state, { payload }) =>
  state.merge({
    fetching: _.uniq([state.fetching, payload?.loader]),
    error: getErrorValue(state?.error, payload?.loader)
  });

export const onToggleReadNotificationSuccess = (state, { data }) =>
  state.merge({
    fetching: getFetchingValue(state.fetching, data?.loader),
    error: getErrorValue(state?.error, data?.loader),
    notifications: state.notifications.map((_) => {
      if (_._id !== data.notificationId) return _;
      return {
        ..._,
        readStatus: data.status
      };
    })
  });

export const onDismissNotificationAction = (state, { payload }) =>
  state.merge({
    fetching: _.uniq([state.fetching, payload?.loader]),
    error: getErrorValue(state?.error, payload?.loader)
  });

export const onDismissNotificationSuccess = (state, { data }) =>
  state.merge({
    fetching: getFetchingValue(state.fetching, data?.loader),
    error: getErrorValue(state?.error, data?.loader),
    notifications: state.notifications.filter((_) => _.id !== data.notificationId)
  });

export const onGetNotificationFailure = (state, { error }) =>
  state.merge({
    fetching: _.without(state.fetching, error?.loader),
    error: { ...state.error, [error?.loader]: error?.error }
  });

/* ------------- Hookup Reducers To Types ------------- */
export const notificationReducer = createReducer(INITIAL_STATE, {
  [Types.GET_NOTIFICATION_ACTION]: onGetNotificationAction,
  [Types.GET_NOTIFICATION_SUCCESS]: onGetNotificationSuccess,
  [Types.TOGGLE_READ_NOTIFICATION_ACTION]: onToggleReadNotificationAction,
  [Types.TOGGLE_READ_NOTIFICATION_SUCCESS]: onToggleReadNotificationSuccess,
  [Types.DISMISS_NOTIFICATION_ACTION]: onDismissNotificationAction,
  [Types.DISMISS_NOTIFICATION_SUCCESS]: onDismissNotificationSuccess,
  [Types.GET_NOTIFICATION_FAILURE]: onGetNotificationFailure
});
