/* eslint-disable indent */
import React from 'react';
// import FormControlLabel from '@mui/material/FormControlLabel';
import PropTypes from 'prop-types';
import { Grid, Box } from '@mui/material';
import LocationLabel from './LocationLabel';
import TotemLabeling from './TotemLabeling';
import MDTypography from 'components/MDTypography';

const PrintLocationLabel = ({ totemMap }) => {
  return (
    <Box
      px={10}
      py={5}
      id="labeling-container"
      sx={{
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Grid container sx={{ textAlign: 'center', width: 1000 }}>
        <Grid item sm={12}>
          {Object.keys(totemMap).map((key) => {
            // const item = totemMap[key];
            const locationData = [...totemMap[key].location_data].reverse();
            const totemLabel = totemMap[key].totem_label[0];
            const warehouseData = totemMap[key].warehouse;
            return (
              <div
                // id={`print-pdf-${key}`}
                id={`view-totem-${key}`}
                key={key}
                style={{
                  flexDirection: 'row',
                  marginBottom: '100px'
                }}
              >
                <>
                  <MDTypography
                    id={`totem-label-${key}`}
                    variant="h5"
                    component="div"
                    sx={{
                      fontSize: '20px',
                      fontWeight: 'bold',
                      lineHeight: '10px',
                      marginBottom: '30px'
                    }}
                  >
                    {totemLabel && String(totemLabel).replace(/=/g, '-')}
                  </MDTypography>
                  {locationData.map((locationLabel, key) => {
                    // eslint-disable-next-line no-unsafe-optional-chaining
                    const [zl, al, rl, bl, ll, sl] = locationLabel.label?.split('=');
                    const uniqueId = locationLabel.sublevel.id;
                    return (
                      <Grid container id={`location-data-${uniqueId}`} key={key}>
                        <Grid
                          item
                          id={`totem-${uniqueId}`}
                          md={6}
                          sx={{
                            textAlign: 'center',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <TotemLabeling
                            labels={locationLabel}
                            warehouseData={warehouseData}
                            keyName={key}
                          ></TotemLabeling>
                        </Grid>
                        <Grid
                          item
                          id={`location-${uniqueId}`}
                          md={6}
                          sx={{
                            textAlign: 'center',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <Box key={key}>
                            <LocationLabel
                              zone={zl}
                              area={al}
                              row={rl}
                              bay={bl}
                              level={ll}
                              position={sl}
                              levelData={locationLabel.level.name}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    );
                  })}
                </>
              </div>
            );
          })}
        </Grid>
      </Grid>
    </Box>
  );
};

PrintLocationLabel.propTypes = {
  info: PropTypes.any,
  totemMap: PropTypes.any,
  printDocument: PropTypes.any
};

export default PrintLocationLabel;
