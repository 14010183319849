import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';

const PendingAssociationsModal = ({ data, type, dismissAction }) => {
  const pendingContent = `This ${type} ${
    type === 'warehouse' ? 'contains inventory associated to it at' : 'is associated with'
  } ${data.length} locations.`;
  return (
    <>
      <Dialog open={data.length} onClose={dismissAction}>
        <DialogTitle>This {type} cannot be deleted</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {pendingContent} <br />
            Please remove these associations before deleting the ${type}.
            <br />
            <br />
            Total quantity: {data.reduce((pre, cur) => pre + cur.totalQuantity, 0)}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={dismissAction}>
            Dismiss
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

PendingAssociationsModal.propTypes = {
  data: PropTypes.any,
  type: PropTypes.string,
  dismissAction: PropTypes.any
};

export default PendingAssociationsModal;
