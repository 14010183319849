import * as Yup from 'yup';

// const PASSWORD_REGEX = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*./-]).{8,}$/;
// // eslint-disable-next-line no-useless-escape
// const emailRegex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/; // has been disabled because we need escape characters
// const MOBILE_REG = /^[0-9]{10}$/; // Change this regex based on requirement
// const NAME_REG = /^[-a-zA-Z-()]+(\s+[-a-zA-Z-()]+)*$/;
// const WEBSITE_REG =
//   /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g;
// const HASHTAG = /^[a-z0-9\-&#\s]+$/i;

const schema = {
  login: Yup.object({
    email: Yup.string('Enter your email')
      .email('Enter a valid email')
      .required('Email is required'),
    password: Yup.string('Enter your password')
      .min(8, 'Password should be of minimum 8 characters length')
      .required('Password is required')
  }),

  forgotPassword: Yup.object({
    emailPhone: Yup.string('Email / Phone Number')
      .required('Email / Phone Number is required')
      .test({
        name: 'validate-email-phone',
        message: 'Enter Valid Phone/Email',
        test: function (value) {
          const phoneRegex = /^(\+91-|\+91|0)?\d{10}$/; // Change this regex based on requirement
          const emailRegex = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
          let isValidEmail = emailRegex.test(value);
          let isValidPhone = phoneRegex.test(value);
          if (!isValidEmail && !isValidPhone) {
            return false;
          }
          return true;
        }
      })
  }),

  resetPassword: Yup.object({
    password: Yup.string('Password').required('Password is required'),
    // .matches(
    //   /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*./-]).{8,}$/,
    //   'Password must contain minimum eight characters, at least one letter, one number and one special character'
    // ),
    confirmPassword: Yup.string('Confirm Password')
      .required('Conform password is required')
      // eslint-disable-next-line quotes
      .oneOf([Yup.ref('password'), null], "Passwords don't match.")
  }),

  warehouseForm: Yup.object({
    warehousename: Yup.string('Enter warehouse name').required('warehouse name is required'),
    address: Yup.string('Enter address').required('address is required'),
    preferredInventories: Yup.array('Enter inventory Type')
      .of(Yup.string())
      .required('inventory Type is required'),
    specs: Yup.string('Enter other attributes'),
    image: Yup.array()
  }),

  addNewItem: Yup.object({
    commonName: Yup.string('Enter details').required(),
    formalName: Yup.string('Enter details').required(),
    description: Yup.string('Enter details'),
    manufacturer: Yup.string('Enter details'),
    size: Yup.string('Enter details'),
    color: Yup.string('Enter details'),
    type: Yup.string('Enter details'),
    unitOfMaterial: Yup.string('Enter details'),
    unitCost: Yup.number().test((val) => val >= 0),
    packageCount: Yup.number().test((val) => val >= 0),
    countPerPallet: Yup.number().test((val) => val >= 0),
    countPerPalletPackage: Yup.number().test((val) => val >= 0),
    primaryWidgetFamilyId: Yup.string('Enter details'),
    secondaryWidgetFamilyId: Yup.string('Enter details'),
    policiesMetadata: Yup.object({
      underStockLevelCount: Yup.number().test((val) => val >= 0),
      overStockLevelCount: Yup.number().test((val) => val >= 0),
      alertStockLevelCount: Yup.number().test((val) => val >= 0),
      reorderStockLevelCount: Yup.number().test((val) => val >= 0)
    }),
    images: Yup.array()
  }),
  addFamily: Yup.object({
    name: Yup.string('Enter details').required()
  }),

  addInventory: Yup.object({
    name: Yup.string('Enter Inventory Name').required('Inventory Name is required'),
    widgetName: Yup.string('Enter Widget name').required('Widget Name is required'),
    policies: Yup.object({
      orderTracking: Yup.boolean(),
      alerting: Yup.boolean(),
      replenishment: Yup.boolean(),
      preferredLocations: Yup.boolean(),
      inventory_process: Yup.string()
    }),
    icons: Yup.string()
  }),

  createUser: Yup.object({
    fullName: Yup.string('Enter Full Name').required('User Name is required'),
    email: Yup.string('Enter Email').email('Enter a valid email').required('Email is required'),
    requiredPassword: Yup.boolean(),
    password: Yup.string('Enter Password')
      .matches(
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*./-]).{8,}$/,
        'Password must contain minimum eight characters, at least one letter, one number and one special character'
      )
      .when('requiredPassword', {
        is: true,
        then: Yup.string().required('Password is required')
      }),
    phoneNumber: Yup.string('Enter Phone Number').required('Phone Number is required'),
    role: Yup.string('Please select at least one role').required('At least one role is required')
  }),

  createRole: Yup.object({
    name: Yup.string('Enter Role Name').required('Role Name is required'),
    description: Yup.string('Enter Description'),
    role: Yup.string('Select one role to be assigned to the user')
  }),
  pick: Yup.object({
    widgetname: Yup.string(),
    availQuan: Yup.number().positive(),
    type: Yup.string(),
    pickupQuan: Yup.number()
      .required('Required quantity')
      .test({
        name: 'pickUpQuantity',
        message: 'Given quantity is not available',
        test: function (value) {
          return value <= this.parent.availQuan;
        }
      }),
    size: Yup.string(),
    remainingQuan: Yup.number(),
    color: Yup.string(),
    pickreasons: Yup.string(),
    count: Yup.string(),
    varianceType: Yup.string(),
    varianceComments: Yup.string()
  }),
  put: Yup.object({
    widgetname: Yup.string(),
    availQuan: Yup.number(),
    type: Yup.string(),
    putQuan: Yup.number(),
    size: Yup.string(),
    totalQuan: Yup.number(),
    color: Yup.string(),
    putreasons: Yup.string().required('required reason'),
    count: Yup.string()
  }),
  reserve: Yup.object({
    widgetname: Yup.string(),
    totalQuan: Yup.string(),
    type: Yup.string(),
    size: Yup.string(),
    color: Yup.string(),
    reservequan: Yup.number()
      .required('required quantity')
      .test({
        name: 'reserveQuantity',
        message: 'Given quantity is not available',
        test: function (value) {
          return value <= this.parent.totalQuan;
        }
      }),
    job: Yup.string().required('required job'),
    pickdate: Yup.date().required('required date'),
    reservationreason: Yup.string().required('required reason'),
    warehouseId: Yup.string().required('Required warehouse info')
  }),
  checkin: Yup.object({
    checkInMeter: Yup.number().required('Please enter Check-In meter reading'),
    issueDetail: Yup.string().when('hasIssue', {
      is: true,
      then: Yup.string().required('Required issue description')
    }),
    hasIssue: Yup.string().required('Required issue status')
  }),
  checkout: Yup.object({
    checkoutmeter: Yup.string().required('Please enter Check-Out meter reading'),
    usagereason: Yup.string().when('checkoutReason', {
      is: 'Miscellaneous use',
      then: Yup.string().required('Please enter issue detail')
    }),
    job: Yup.string().when('checkoutReason', {
      is: 'Job/Project',
      then: Yup.string().required('Please enter job details')
    }),
    checkoutReason: Yup.string().required('Please enter Checkout reason')
  }),
  report: Yup.object({
    issuedetail: Yup.string().required('required issuedetail'),
    reporting: Yup.string().required('required issuedetail')
  }),
  adjust: Yup.object({
    widgetname: Yup.string(),
    type: Yup.string(),
    size: Yup.string(),
    color: Yup.string(),
    removedDamagedQuantity: Yup.boolean().when('damagedQuantity', {
      is: (damagedQuantity) => {
        return damagedQuantity > 0;
      },
      then: Yup.boolean().required('required')
    }),
    lastrecordedquantity: Yup.number().required('required'),
    recountedQuantity: Yup.number().required('required'),
    damagedQuantity: Yup.number()
      .max(Yup.ref('recountedQuantity'), 'Damaged quantity cant be greater than recounted quantity')
      .required('required'),
    newAdjustedQuantity: Yup.number().required('required'),
    totaladjustment: Yup.number().required('required'),
    varianceObservedQuantity: Yup.number().required('required')
  })
};

export default schema;
