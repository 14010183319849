/* eslint-disable complexity */
import React, { useState } from 'react';
import { Box, FormHelperText, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import MDButton from 'components/Button';
import MDInput from 'components/MDInput';
import { makeStyles } from '@mui/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import CrossIcon from 'assets/images/CrossIcon';
import PickupScanGrid from 'components/PickUpScan';
import { useFormik } from 'formik';
import schema from 'services/ValidationServices';
import { useDispatch } from 'react-redux';
import PprActions from 'redux/PprRedux';
import { API } from 'constant';
// import ItemActions from 'redux/ItemRedux';

const useStyles = makeStyles(() => ({
  inputLabel: {
    fontSize: '16px',
    letterSpacing: '0.01em',
    color: '#000',
    marginBottom: '4px',
    fontWeight: 'normal',
    '& .MuiFormControlLabel-label': {
      fontWeight: 'normal',
      fontSize: '16px',
      color: '#000'
    }
  },
  centreContent: {
    justifyContent: 'center'
  },
  fullWidth: {
    width: '100%',
    borderColor: '#d2d6da',
    borderRadius: '0.375rem'
  },
  pickupBtn: {
    '&:hover': {
      backgroundColor: '#ff9330',
      color: '#fff'
    }
  },
  cursorPointer: {
    cursor: 'pointer',
    display: 'flex',
    alignSelf: 'end',
    margin: '0'
  }
}));

function ScanCheckOut({ show, setShow, inventoryItem }) {
  const classes = useStyles();
  const [reasonType, setReasonType] = useState(null);
  const [locationId, setLocationId] = React.useState(null);
  const [inventoryArr, setInventoryArr] = useState(null);
  const dispatch = useDispatch();

  React.useEffect(() => {
    setInventoryArr(inventoryItem.locationList);
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      checkoutmeter: '',
      usagereason: '',
      job: '',
      checkoutReason: ''
    },
    validationSchema: schema.checkout,
    onSubmit: (values) => {
      const reason = { checkoutReason: values.checkoutReason };
      if (values.checkoutReason === 'Miscellaneous use') {
        reason.usageReason = values.usagereason;
      } else if (values.checkoutReason === 'Job/Project') {
        reason.job = values.job;
      }

      dispatch(
        PprActions.pickAction({
          loader: 'loading-request',
          slug: `${API.CHECK_OUT.replace(':id', inventoryItem.inventoryId)}`,
          method: 'post',
          data: {
            subLevel: locationId,
            checkOutMeterReading: values.checkoutmeter,
            ...reason
          },
          callback: () => {
            formik.resetForm();
            setShow();
          }
        })
      );
    }
  });

  return (
    <div>
      <Dialog fullWidth open={show} maxWidth="sm" onClose={setShow}>
        <MDButton
          className={classes.cursorPointer}
          sx={{ padding: '15px', minWidth: '14px' }}
          onClick={setShow}
        >
          <CrossIcon />
        </MDButton>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent
            sx={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}
          >
            <Grid container sx={{ marginLeft: 'auto' }}>
              <Grid item container md={5}>
                <PickupScanGrid
                  inventoryArr={inventoryArr}
                  onLocationFound={(data) => {
                    setLocationId(data.subLevelId);
                  }}
                />
              </Grid>
              <Grid item container md={7}>
                <Grid item xs={12}>
                  <FormControl sx={{ paddingTop: '16px' }}>
                    <FormLabel id="demo-radio-buttons-group-label" className={classes.inputLabel}>
                      Any Issue to report?
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue=""
                      name="checkoutReason"
                      onChange={(e) => {
                        setReasonType(e.target.value);
                        formik.handleChange(e);
                      }}
                    >
                      <FormControlLabel
                        value="Miscellaneous use"
                        control={<Radio />}
                        label="Miscellaneous use"
                        className={classes.inputLabel}
                      />
                      <FormControlLabel
                        value="Job/Project"
                        control={<Radio />}
                        label="Job/Project"
                        className={classes.inputLabel}
                      />
                      <FormControlLabel
                        value="Maintenance"
                        control={<Radio />}
                        label="Maintenance"
                        className={classes.inputLabel}
                      />
                    </RadioGroup>
                    <FormHelperText>
                      {formik.touched.checkoutReason && formik.errors.checkoutReason && (
                        <div style={{ color: 'red' }}>{formik.errors.checkoutReason}</div>
                      )}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                {reasonType === 'Miscellaneous use' && (
                  <Grid item xs={12}>
                    <Box component="div" sx={{}} className={classes.inputLabel}>
                      Miscellaneous use Issue Details
                    </Box>
                    <MDInput
                      fullWidth
                      name="usagereason"
                      type="text"
                      variant="outlined"
                      as="textarea"
                      value={formik.values.usagereason}
                      error={formik.touched.usagereason && Boolean(formik.errors.usagereason)}
                      helperText={
                        formik.touched.usagereason &&
                        formik.errors.usagereason && (
                          <div style={{ color: 'red' }}>{formik.errors.usagereason}</div>
                        )
                      }
                      className={classes.fullWidth}
                      minRows="7"
                      onChange={formik.handleChange}
                    />
                  </Grid>
                )}
                {reasonType === 'Job/Project' && (
                  <Grid item xs={12}>
                    <Box component="div" sx={{}} className={classes.inputLabel}>
                      Job/Project
                    </Box>
                    <MDInput
                      fullWidth
                      name="job"
                      type="text"
                      variant="outlined"
                      value={formik.values.job}
                      error={formik.touched.job && Boolean(formik.errors.job)}
                      helperText={
                        formik.touched.job &&
                        formik.errors.job && <div style={{ color: 'red' }}>{formik.errors.job}</div>
                      }
                      onChange={formik.handleChange}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Box component="div" sx={{}} className={classes.inputLabel}>
                    Check-out Meter Reading
                  </Box>
                  <MDInput
                    fullWidth
                    name="checkoutmeter"
                    type="text"
                    variant="outlined"
                    value={formik.values.checkoutmeter}
                    error={formik.touched.checkoutmeter && Boolean(formik.errors.checkoutmeter)}
                    helperText={
                      formik.touched.checkoutmeter &&
                      formik.errors.checkoutmeter && (
                        <div style={{ color: 'red' }}>{formik.errors.checkoutmeter}</div>
                      )
                    }
                    onChange={formik.handleChange}
                  />
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className={classes.centreContent}>
            <MDButton size="medium" color="error" type="submit">
              CHECK OUT
            </MDButton>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
ScanCheckOut.propTypes = {
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
  inventoryItem: PropTypes.shape({
    inventoryId: PropTypes.string.isRequired,
    locationList: PropTypes.arrayOf(
      PropTypes.shape({
        subLevelId: PropTypes.string.isRequired,
        availability: PropTypes.bool.isRequired
      })
    )
  }).isRequired
};

export default ScanCheckOut;
