import React from 'react';
import { useLocation } from 'react-router-dom';
import { Grid, TableBody, TableCell, TableRow } from '@mui/material';

import DashboardNavbar from 'components/DashboardNavbar';
import DashboardLayout from 'layouts/DashboardLayout';
import Breadcrumbs from 'components/Breadcrumbs';
import MDButton from 'components/Button';
import MDBox from 'components/MDBox';
import LocationLabel from './LocationLabel';
import BasicTable from 'components/BasicTable';
import CompletePutting from 'modals/ScanPutting';
import { useDispatch, useSelector } from 'react-redux';
import { API } from 'constant';
import ScanActions from 'redux/ScanRedux';
import { ScanDataSelectors } from 'redux/ScanRedux';
import QRcode from 'components/QRcode';
import ScanAdjust from 'modals/ScanAdjust';
import AddWidget from 'modals/AddNewWidget';
import PickupBase from 'modals/ScanPickUp';
import ScanCheckin from 'modals/ScanCheckin';
import ScanCheckOut from 'modals/ScanCheckout';
import ScanReport from 'modals/ScanReport';

const tHeads = [
  { id: 'widgetName', label: 'Widget Name' },
  { id: 'typeColorSize', label: 'Type-Color-Size' },
  { id: 'quantity', label: 'Quantity' },
  { id: 'action1', label: 'Action' },
  { id: 'action2', label: 'Action' },
  { id: 'action3', label: 'Action' }
];

const ScanLocation = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const [showPickModal, setShowPickModal] = React.useState(false);
  const [showPutModal, setShowPutModal] = React.useState(false);
  const [showAdjustModal, setShowAdjustModal] = React.useState(false);

  const [showCheckInModal, setShowCheckInModal] = React.useState(false);
  const [showCheckOutModal, setShowCheckOutModal] = React.useState(false);
  const [showReportModal, setShowReportModal] = React.useState(false);

  const [putNewWidgetModal, setPutNewWidgetModal] = React.useState(false);

  const [modalIndex, setModalIndex] = React.useState(-1);
  const [modalData, setModalData] = React.useState(null);
  const [loader, setLoader] = React.useState(true);

  const [inventoryList, setInventoryList] = React.useState(location.state.scanData.inventory);
  const label = location.state.scanData.label;
  const scanData = useSelector(ScanDataSelectors.getScanDetail);
  React.useEffect(() => {
    dispatch(
      ScanActions.getScanAction({
        loader: 'loading-request',
        slug: `${API.GET_ITEMS_BY_SCAN}type=Sublevel&id=${location.state.id}`,
        method: 'get'
      })
    );
  }, [modalIndex, putNewWidgetModal]);

  React.useEffect(() => {
    setInventoryList(scanData.inventory);
    if (modalIndex >= 0) {
      setModalData(getModalData(scanData.inventory, scanData.label));
      // setModalData(getCCRModalData(scanData.inventory)); //TODO
    }
    setLoader(false);
  }, [scanData]);

  const getBodyData = () => {
    const body = inventoryList.map((inventory, index) => {
      const {
        item_id: { formalName = '', type = '', color = '', size = '' },
        totalQuantity = 0
      } = inventory;
      const isCCR = false; // TODO
      return {
        widgetName: formalName,
        typeColorSize: `${type}-${color}-${size}`,
        quantity: totalQuantity,
        action1: isCCR
          ? actionButtons(isCCR, 'action1', 'success', index, 'Check-In')
          : actionButtons(isCCR, 'action1', 'warning', index, 'Pick'),
        action2: isCCR
          ? actionButtons(isCCR, 'action2', 'error', index, 'Check-Out')
          : actionButtons(isCCR, 'action2', 'success', index, 'Put'),
        action3: isCCR
          ? actionButtons(isCCR, 'action2', 'primary', index, 'Report')
          : actionButtons(isCCR, 'action3', 'primary', index, 'Adjust')
      };
    });
    return body;
  };

  const actionButtons = (isCCR, type, variant, index, btnLabel) => {
    return (
      <MDButton
        fullWidth
        color={variant}
        onClick={() => {
          setLoader(true);
          setModalIndex(index);
          if (isCCR) {
            if (type === 'action1') {
              setShowCheckInModal(true);
            } else if (type === 'action2') {
              setShowCheckOutModal(true);
            } else if (type === 'action3') {
              setShowReportModal(true);
            }
          } else {
            if (type === 'action1') {
              setShowPickModal(true);
            } else if (type === 'action2') {
              setShowPutModal(true);
            } else if (type === 'action3') {
              setShowAdjustModal(true);
            }
          }
        }}
      >
        {btnLabel}
      </MDButton>
    );
  };
  const bodyData = getBodyData();

  const getModalData = (currentList, currentLocation) => {
    const currentData = currentList[modalIndex];
    return {
      inventoryData: {
        inventoryId: currentData?.item_id?._id,
        widgetName: currentData?.item_id?.commonName,
        availQuan: currentData?.availableQuantity,
        type: currentData?.item_id?.type,
        size: currentData?.item_id?.size,
        color: currentData?.item_id?.color,
        images: currentData?.item_id?.images
      },
      locationId: location.state.id,
      locationList: [
        {
          subLevelId: location.state.id,
          availableQuantity: currentData?.availableQuantity,
          warehouseId: currentLocation.warehouseData._id
        }
      ]
    };
  };

  // const getCCRModalData = (currentItem) => {
  //   const currentData = currentItem[modalIndex];
  //   return {
  //     inventoryId: currentData?.item_id?._id,
  //     locationList: currentData.isAvailable
  //       ? [
  //           {
  //             subLevelId: currentData.sub_level_id,
  //             availability: currentData.isAvailable
  //           }
  //         ]
  //       : null
  //   };
  // };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox sx={{ mr: 5 }}>
        <Grid container>
          <Grid item md={9}>
            <Breadcrumbs
              route={[
                { name: 'Home', path: '/home' },
                { name: 'Scan', path: '/scan' },
                { name: 'Location' }
              ]}
            />
          </Grid>
          <Grid
            item
            md={3}
            sx={{
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center'
            }}
          >
            <MDButton color="primary" onClick={() => setPutNewWidgetModal(true)}>
              PUT NEW WIDGET
            </MDButton>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox sx={{ m: 5 }}>
        <Grid
          container
          sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
        >
          {label && Object.keys(label).length > 0 && (
            <LocationLabel
              area={label.area}
              row={label.row}
              bay={label.bay}
              level={label.level}
              pos={label.sub_level}
            />
          )}
          <QRcode
            payload={JSON.stringify({
              type: 'Sublevel',
              id: location.state.id
            })}
            width={100}
            height={100}
          />
        </Grid>
      </MDBox>

      <MDBox className={loader ? 'loader' : ''} sx={{ mx: 5 }}>
        <BasicTable headCells={tHeads} records={bodyData} backgroundColor="#E5E7EB" color="#000000">
          <TableBody>
            {bodyData.map((item, i) => (
              <TableRow key={++i}>
                {Object.keys(item).map((key) => (
                  <TableCell key={`${key}-${++i}`}>{item[key]}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </BasicTable>
      </MDBox>
      {modalData && (
        <>
          <PickupBase
            show={showPickModal}
            setShow={() => {
              setShowPickModal(false);
              setModalIndex(-1);
              setModalData(null);
            }}
            inventoryItem={modalData}
          />
          <CompletePutting
            show={showPutModal}
            setShow={() => {
              setShowPutModal(false);
              setModalIndex(-1);
              setModalData(null);
            }}
            inventoryItem={modalData}
          />
          <ScanAdjust
            show={showAdjustModal}
            setShow={() => {
              setShowAdjustModal(false);
              setModalIndex(-1);
              setModalData(null);
            }}
            inventoryItem={modalData}
          />
          <ScanCheckin
            show={showCheckInModal}
            setShow={() => {
              setShowCheckInModal(false);
              setModalIndex(-1);
              setModalData(null);
            }}
            inventoryItem={modalData}
          />
          <ScanCheckOut
            show={showCheckOutModal}
            setShow={() => {
              setShowCheckOutModal(false);
              setModalIndex(-1);
              setModalData(null);
            }}
            inventoryItem={modalData}
          />
          <ScanReport
            show={showReportModal}
            setShow={() => {
              setShowReportModal(false);
              setModalIndex(-1);
              setModalData(null);
            }}
            inventoryItem={modalData}
          />
        </>
      )}
      {inventoryList && (
        <AddWidget
          show={putNewWidgetModal}
          setShow={() => {
            setPutNewWidgetModal(false);
          }}
          locationId={location.state.id}
        />
      )}
    </DashboardLayout>
  );
};

export default ScanLocation;
