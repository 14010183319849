/* eslint-disable complexity */
import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import MDButton from 'components/Button';
import MDInput from 'components/MDInput';
import { makeStyles } from '@mui/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import CrossIcon from 'assets/images/CrossIcon';
import PickupScanGrid from 'components/PickUpScan';
import PprActions from 'redux/PprRedux';
import { useDispatch, useSelector } from 'react-redux';
import schema from 'services/ValidationServices';

import { API } from 'constant';
import ReportActions from 'redux/ReportsRedux';
import { ReportSelectors } from 'redux/ReportsRedux';
import ReservePickup from './ReservePickup';

const useStyles = makeStyles(() => ({
  inputLabel: {
    fontSize: '16px',
    letterSpacing: '0.01em',
    color: '#000',
    marginBottom: '4px',
    fontWeight: 'normal',
    '& .MuiFormControlLabel-label': {
      fontWeight: 'normal',
      fontSize: '16px',
      color: '#000'
    }
  },
  radioLabel: {
    fontSize: '16px',
    letterSpacing: '0.01em',
    color: '#000',
    marginBottom: '4px',
    paddingTop: '5px',
    fontWeight: 'normal',
    '& .MuiTypography-root': {
      fontWeight: 'normal'
    }
  },
  centreContent: {
    justifyContent: 'center'
  },
  fullWidth: {
    width: '100%',
    borderColor: '#d2d6da',
    borderRadius: '0.375rem'
  },
  pickupBtn: {
    '&:hover': {
      backgroundColor: '#ff9330',
      color: '#fff'
    }
  },
  cursorPointer: {
    cursor: 'pointer',
    display: 'flex',
    alignSelf: 'end',
    margin: '0'
  },
  varienceText: {
    padding: '10px',
    backgroundColor: '#f2f2f2',
    width: '100%'
  }
}));

function PickupBase({ show, setShow, inventoryItem }) {
  const [pickReserved, setPickReserved] = useState(null);
  const [reservationId, setReservationId] = useState(null);
  const [loadReserved, setLoadReserved] = useState(false);

  const dispatch = useDispatch();
  const reservedData = useSelector(ReportSelectors.getReportDetail);

  useEffect(() => {
    if (pickReserved) {
      setLoadReserved(true);
    }
  }, [reservedData]);

  useEffect(() => {
    if (reservationId) {
      setPickReserved(false);
    }
  }, [reservationId]);

  const setWarehouseId = (warehouseId, reservationId) => {
    inventoryItem.locationList = inventoryItem.locationList.filter((location) => {
      return location.warehouseId === warehouseId;
    });
    setReservationId(reservationId);
  };

  useEffect(() => {
    if (pickReserved) {
      dispatch(
        ReportActions.getReportAction({
          loader: 'loading-request',
          slug: `${API.GET_ITEM}?itemId=${inventoryItem.inventoryData.inventoryId}&picked=false&cancelled=false`,
          method: 'get'
        })
      );
    }
  }, [pickReserved]);

  const classes = useStyles();
  return (
    <div>
      <Dialog
        fullWidth
        open={show}
        maxWidth={(pickReserved && loadReserved) || pickReserved === false ? 'xl' : 'xs'}
        onClose={setShow}
      >
        <MDButton
          className={classes.cursorPointer}
          sx={{ padding: '15px', minWidth: '14px' }}
          onClick={setShow}
        >
          <CrossIcon />
        </MDButton>
        {pickReserved && loadReserved ? (
          <ReservePickup reserveList={reservedData} setWarehouseId={setWarehouseId} />
        ) : pickReserved === false ? (
          <CompletePickup
            setShow={setShow}
            inventoryItem={inventoryItem}
            reservationId={reservationId}
          />
        ) : (
          <>
            <DialogContent>
              <Box component="div">Do you want to pick for a reservation made previously?</Box>
            </DialogContent>
            <DialogActions>
              <MDButton
                size="medium"
                variant="outlined"
                color="error"
                onClick={() => setPickReserved(false)}
              >
                No
              </MDButton>
              <MDButton
                size="medium"
                variant="outlined"
                color="success"
                onClick={() => setPickReserved(true)}
              >
                Yes
              </MDButton>
            </DialogActions>
          </>
        )}
      </Dialog>
    </div>
  );
}

/*eslint no-mixed-spaces-and-tabs: ["error", "smart-tabs"]*/
function CompletePickup({ setShow, inventoryItem, reservationId }) {
  const classes = useStyles();
  const [exactCount, setExactCount] = useState(false);
  const [approxCount, setApproxCount] = useState(false);
  const [varianceComment, setVarianceComment] = useState(false);
  const [reportVariance, setReportVariance] = useState(false);

  const [inventoryArr, setInventoryArr] = useState(null);
  const [inventoryData, setInventoryData] = useState(null);
  const [locationId, setLocationId] = useState(null);

  const dispatch = useDispatch();

  React.useEffect(() => {
    setInventoryData(inventoryItem.inventoryData);
    if (inventoryItem.locationId) {
      setLocationId(inventoryItem.locationId);
    } else {
      setInventoryArr(inventoryItem.locationList);
    }
  }, []);

  const pickInitialValues = {
    widgetname: inventoryData?.widgetName,
    availQuan: inventoryData?.availQuan,
    type: inventoryData?.type,
    size: inventoryData?.size,
    color: inventoryData?.color,
    remainingQuan: '',
    pickupQuan: '',
    pickreasons: '',
    countType: '',
    varianceFound: '',
    varianceType: '',
    varianceComments: ''
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: pickInitialValues,
    validationSchema: schema.pick,
    onSubmit: (values) => {
      const countConfirmation = {
        countType: values.countType
      };
      if (values.countType !== 'NONE') {
        countConfirmation.countResult = {
          varianceFound: values.varianceFound
        };
        if (values.varianceFound) {
          countConfirmation.countResult.varianceType = values.varianceType;
          countConfirmation.countResult.varianceComments = values.varianceComments;
        }
      }
      dispatch(
        PprActions.pickAction({
          loader: 'loading-request',
          slug: `${API.PICK_ITEM.replace(':id', inventoryData.inventoryId)}`,
          method: 'post',
          data: {
            pickupQuantity: values.pickupQuan,
            subLevel: locationId,
            usageReason: values.pickreasons,
            countConfirmation,
            reservationId
          },
          callback: () => {
            formik.resetForm();
            setShow();
          }
        })
      );
    }
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid
            item
            container
            xs={12}
            md={4}
            spacing={2}
            sx={{ display: 'flex', justifyContent: 'center' }}
          >
            {(locationId || inventoryArr) && (
              <>
                {!locationId && (
                  <Box sx={{ position: 'absolute', color: 'red' }}>
                    Scan location before proceeding
                  </Box>
                )}
                <PickupScanGrid
                  images={inventoryData?.images}
                  locationId={locationId}
                  inventoryArr={inventoryArr}
                  onLocationFound={(data) => {
                    setInventoryData((prev) => {
                      return { ...prev, availQuan: data.availableQuantity };
                    });
                    setLocationId(data.subLevelId);
                  }}
                />
              </>
            )}
          </Grid>

          <Grid item container xs={12} md={8} spacing={2}>
            <Grid item xs={12} md={6}>
              <Box component="div" sx={{}} className={classes.inputLabel}>
                Widget Name
              </Box>
              <MDInput
                disabled
                fullWidth
                name="widgetname"
                type="text"
                variant="outlined"
                value={formik.values.widgetname}
                error={formik.touched.widgetname && Boolean(formik.errors.widgetname)}
                helperText={
                  formik.touched.widgetname &&
                  formik.errors.widgetname && (
                    <div style={{ color: 'red' }}>{formik.errors.commonName}</div>
                  )
                }
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Box component="div" sx={{}} className={classes.inputLabel}>
                Available Quantity Here
              </Box>
              <MDInput
                disabled
                fullWidth
                name="availQuan"
                type="text"
                variant="outlined"
                value={formik.values.availQuan}
                error={formik.touched.availQuan && Boolean(formik.errors.availQuan)}
                helperText={
                  formik.touched.availQuan &&
                  formik.errors.availQuan && (
                    <div style={{ color: 'red' }}>{formik.errors.availQuan}</div>
                  )
                }
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Box component="div" sx={{}} className={classes.inputLabel}>
                Type
              </Box>
              <MDInput
                disabled
                fullWidth
                name="type"
                type="text"
                variant="outlined"
                value={formik.values.type}
                error={formik.touched.type && Boolean(formik.errors.type)}
                helperText={
                  formik.touched.type &&
                  formik.errors.type && <div style={{ color: 'red' }}>{formik.errors.type}</div>
                }
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Box component="div" sx={{}} className={classes.inputLabel}>
                Pick-Up Quantity
              </Box>
              <MDInput
                fullWidth
                name="pickupQuan"
                type="number"
                variant="outlined"
                value={formik.values.pickupQuan}
                error={formik.touched.pickupQuan && Boolean(formik.errors.pickupQuan)}
                helperText={
                  formik.touched.pickupQuan &&
                  formik.errors.pickupQuan && (
                    <div style={{ color: 'red' }}>{formik.errors.pickupQuan}</div>
                  )
                }
                onChange={(pickupQuan) => {
                  formik.handleChange(pickupQuan);
                  formik.setFieldValue(
                    'remainingQuan',
                    formik.values.availQuan - pickupQuan.target.value
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Box component="div" sx={{}} className={classes.inputLabel}>
                Size
              </Box>
              <MDInput
                disabled
                fullWidth
                name="size"
                type="text"
                variant="outlined"
                value={formik.values.size}
                error={formik.touched.size && Boolean(formik.errors.size)}
                helperText={
                  formik.touched.size &&
                  formik.errors.size && <div style={{ color: 'red' }}>{formik.errors.size}</div>
                }
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Box component="div" sx={{}} className={classes.inputLabel}>
                Remaining Quantity Here
              </Box>
              <MDInput
                disabled
                fullWidth
                name="remainingQuan"
                type="text"
                variant="outlined"
                value={formik.values.remainingQuan}
                error={formik.touched.remainingQuan && Boolean(formik.errors.remainingQuan)}
                helperText={
                  formik.touched.remainingQuan &&
                  formik.errors.remainingQuan && (
                    <div style={{ color: 'red' }}>{formik.errors.remainingQuan}</div>
                  )
                }
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Box component="div" sx={{}} className={classes.inputLabel}>
                Color
              </Box>
              <MDInput
                disabled
                fullWidth
                name="color"
                type="text"
                variant="outlined"
                value={formik.values.color}
                error={formik.touched.color && Boolean(formik.errors.color)}
                helperText={
                  formik.touched.color &&
                  formik.errors.color && <div style={{ color: 'red' }}>{formik.errors.color}</div>
                }
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Box component="div" sx={{}} className={classes.inputLabel}>
                Pick Reasons/Reference
              </Box>
              <MDInput
                fullWidth
                name="pickreasons"
                type="text"
                variant="outlined"
                as="textarea"
                value={formik.values.pickreasons}
                error={formik.touched.pickreasons && Boolean(formik.errors.pickreasons)}
                helperText={
                  formik.touched.pickreasons &&
                  formik.errors.pickreasons && (
                    <div style={{ color: 'red' }}>{formik.errors.pickreasons}</div>
                  )
                }
                className={classes.fullWidth}
                minRows="4"
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item container xs={12} spacing={2}>
              <Grid item xs={12} md={12}>
                <FormControl sx={{ paddingTop: '16px' }}>
                  <FormLabel id="demo-radio-buttons-group-label" className={classes.inputLabel}>
                    Would you be kind enough to confirm the remaining count here ?
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="countType"
                    defaultValue=""
                    onChange={(e) => {
                      if (e.target.value === 'APPROX') {
                        setApproxCount(true);
                        setExactCount(false);
                      } else if (e.target.value === 'EXACT') {
                        setApproxCount(false);
                        setExactCount(true);
                      } else {
                        setApproxCount(false);
                        setExactCount(false);
                      }
                      setReportVariance(false);
                      formik.setValues({
                        ...formik.values,
                        countType: e.target.value,
                        varianceFound: false,
                        varianceType: '',
                        varianceComments: ''
                      });
                    }}
                  >
                    <FormControlLabel
                      value="EXACT"
                      control={<Radio />}
                      label="Will count exact"
                      className={classes.inputLabel}
                    />
                    {exactCount ? (
                      <>
                        <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
                          <MDButton
                            circular
                            name="varianceFound"
                            sx={{ minWidth: '170px' }}
                            variant={reportVariance ? 'contained' : 'outlined'}
                            color="primary"
                            size="medium"
                            onClick={() => {
                              setReportVariance(true);
                              formik.setFieldValue('varianceFound', true, true);
                            }}
                          >
                            REPORT VARIANCE
                          </MDButton>
                          <MDButton
                            circular
                            name="varianceFound"
                            sx={{ minWidth: '170px' }}
                            size="medium"
                            variant={!reportVariance ? 'contained' : 'outlined'}
                            color="primary"
                            onClick={() => {
                              setReportVariance(false);
                              formik.setValues({
                                ...formik.values,
                                varianceFound: false,
                                varianceType: '',
                                varianceComments: ''
                              });
                            }}
                          >
                            Exact OK
                          </MDButton>
                        </Box>
                        {reportVariance ? (
                          <>
                            <Box
                              sx={{
                                padding: '0 15px',
                                border: '1px solid #ccc',
                                marginTop: '15px'
                              }}
                            >
                              <FormControl>
                                <FormLabel
                                  id="demo-radio-buttons-group-label"
                                  className={classes.inputLabel}
                                >
                                  Variance Type
                                </FormLabel>
                                <RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  defaultValue=""
                                  name="varianceType"
                                  sx={{ fontWeight: 'bold' }}
                                  onChange={(e) => {
                                    setVarianceComment(true);
                                    formik.handleChange(e);
                                  }}
                                >
                                  <FormControlLabel
                                    value="SHORT"
                                    control={<Radio />}
                                    label="Short"
                                    className={classes.radioLabel}
                                  />

                                  <FormControlLabel
                                    value="OVER"
                                    control={<Radio />}
                                    label="Over"
                                    className={classes.radioLabel}
                                  />
                                  <FormControlLabel
                                    value="DAMAGE"
                                    control={<Radio />}
                                    label="Damage"
                                    className={classes.radioLabel}
                                  />
                                </RadioGroup>
                              </FormControl>
                              {varianceComment ? (
                                <MDInput
                                  name="varianceComments"
                                  label="varianceComments"
                                  multiline="true"
                                  rows={4}
                                  className={classes.varienceText}
                                  onChange={formik.handleChange}
                                />
                              ) : (
                                ''
                              )}
                            </Box>
                          </>
                        ) : (
                          ''
                        )}
                      </>
                    ) : (
                      ''
                    )}

                    <FormControlLabel
                      value="APPROX"
                      control={<Radio />}
                      label="Will approximate"
                      className={classes.inputLabel}
                    />
                    {approxCount ? (
                      <>
                        <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
                          <MDButton
                            circular
                            name="varianceFound"
                            sx={{ minWidth: '170px' }}
                            variant={reportVariance ? 'contained' : 'outlined'}
                            color="primary"
                            size="medium"
                            onClick={() => {
                              setReportVariance(true);
                              formik.setFieldValue('varianceFound', true, true);
                            }}
                          >
                            REPORT VARIANCE
                          </MDButton>
                          <MDButton
                            circular
                            name="varianceFound"
                            sx={{ minWidth: '170px' }}
                            size="medium"
                            variant={!reportVariance ? 'contained' : 'outlined'}
                            color="primary"
                            onClick={() => {
                              setReportVariance(false);
                              formik.setValues({
                                ...formik.values,
                                varianceFound: false,
                                varianceType: '',
                                varianceComments: ''
                              });
                            }}
                          >
                            Approx OK
                          </MDButton>
                        </Box>
                        {reportVariance ? (
                          <>
                            <Box
                              sx={{
                                padding: '0 15px',
                                border: '1px solid #ccc',
                                marginTop: '15px'
                              }}
                            >
                              <FormControl>
                                <FormLabel
                                  id="demo-radio-buttons-group-label"
                                  className={classes.inputLabel}
                                >
                                  Variance Type
                                </FormLabel>
                                <RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  defaultValue=""
                                  name="varianceType"
                                  sx={{ fontWeight: 'bold' }}
                                  onChange={(e) => {
                                    setVarianceComment(true);
                                    formik.handleChange(e);
                                  }}
                                >
                                  <FormControlLabel
                                    value="SHORT"
                                    control={<Radio />}
                                    label="Short"
                                    className={classes.radioLabel}
                                  />

                                  <FormControlLabel
                                    value="OVER"
                                    control={<Radio />}
                                    label="Over"
                                    className={classes.radioLabel}
                                  />
                                  <FormControlLabel
                                    value="DAMAGE"
                                    control={<Radio />}
                                    label="Damage"
                                    className={classes.radioLabel}
                                  />
                                </RadioGroup>
                              </FormControl>
                              {varianceComment ? (
                                <MDInput
                                  name="varianceComments"
                                  label="varianceComments"
                                  multiline="true"
                                  rows={4}
                                  className={classes.varienceText}
                                  onChange={formik.handleChange}
                                />
                              ) : (
                                ''
                              )}
                            </Box>
                          </>
                        ) : (
                          ''
                        )}
                      </>
                    ) : (
                      ''
                    )}

                    <FormControlLabel
                      value="NONE"
                      control={<Radio />}
                      label="Not this time"
                      className={classes.inputLabel}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.centreContent}>
        <MDButton
          size="medium"
          type="submit"
          color="warning"
          className={classes.pickupBtn}
          disabled={!locationId}
        >
          COMPLETE PICK-UP
        </MDButton>
      </DialogActions>
    </form>
  );
}
export default PickupBase;

CompletePickup.propTypes = {
  setShow: PropTypes.func.isRequired,
  inventoryItem: PropTypes.shape({
    inventoryData: PropTypes.shape({
      inventoryId: PropTypes.string.isRequired,
      widgetName: PropTypes.string.isRequired,
      availQuan: PropTypes.number,
      type: PropTypes.string.isRequired,
      size: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
      images: PropTypes.array.isRequired
    }),
    locationId: PropTypes.string,
    locationList: PropTypes.arrayOf(
      PropTypes.shape({
        subLevelId: PropTypes.string.isRequired,
        availableQuantity: PropTypes.number.isRequired
      })
    )
  }).isRequired,
  onChangeInventoryItem: PropTypes.func,
  reservationId: PropTypes.string
};

PickupBase.propTypes = {
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
  inventoryItem: PropTypes.shape({
    inventoryData: PropTypes.shape({
      inventoryId: PropTypes.string.isRequired,
      widgetName: PropTypes.string.isRequired,
      availQuan: PropTypes.number,
      type: PropTypes.string.isRequired,
      size: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
      images: PropTypes.array.isRequired
    }),
    locationId: PropTypes.string,
    locationList: PropTypes.arrayOf(
      PropTypes.shape({
        subLevelId: PropTypes.string.isRequired,
        availableQuantity: PropTypes.number.isRequired,
        warehouseId: PropTypes.string.isRequired
      })
    )
  }).isRequired,
  onChangeInventoryItem: PropTypes.func
};
