/* eslint-disable indent */
import React, { useEffect, useState } from 'react';
import DashboardNavbar from 'components/DashboardNavbar';
import DashboardLayout from 'layouts/DashboardLayout';
import Breadcrumbs from 'components/Breadcrumbs';
import { Grid, Box, Paper } from '@mui/material';
import MDBox from 'components/MDBox';
import MDButton from 'components/Button';
import MDTypography from 'components/MDTypography';
import QRcode from 'components/QRcode';
import { useDispatch, useSelector } from 'react-redux';
import { ItemSelectors } from 'redux/ItemRedux';
import { API } from 'constant';
import ItemActions from 'redux/ItemRedux';
import PickupBase from 'modals/ScanPickUp';
import CompletePutting from 'modals/ScanPutting';
import ScanCheckin from 'modals/ScanCheckin';
import ScanCheckOut from 'modals/ScanCheckout';
import ScanReport from 'modals/ScanReport';
import ScanReserve from 'modals/ScanReserve';
import SearchInventoryMain from './searchInventory';

const customStyles = {
  labelSize: {
    fontSize: '16px',
    letterSpacing: '0.01em',
    color: '#000',
    marginBottom: '4px'
  }
};

const displayLocations = (locations) => {
  function groupBy(list, keyGetter) {
    const obj = {};
    list.forEach((item) => {
      let key = keyGetter(item);
      if (obj[key]) {
        obj[key] = [...obj[key], item];
      } else {
        obj[key] = [item];
      }
    });
    return obj;
  }

  const groupedByWarehouse = groupBy(locations, (loc) => loc.warehouse.name);
  // console.log({ groupedByWarehouse });

  const table = [];
  const simpleGridStyle = { border: '1px solid black', paddingX: 3, fontSize: '0.9em' };

  for (const warehouseName in groupedByWarehouse) {
    if (Object.prototype.hasOwnProperty.call(groupedByWarehouse, warehouseName)) {
      table.push(
        <>
          <Box>Warehouse: {warehouseName}</Box>
          <Grid container key={warehouseName}>
            <Grid item xs={4} sx={simpleGridStyle}>
              Label
            </Grid>
            <Grid item xs={4} sx={simpleGridStyle}>
              Quantity
            </Grid>
            <Grid item xs={4} sx={simpleGridStyle}>
              Variance pending?
            </Grid>
            {groupedByWarehouse[warehouseName].map((loc) => (
              <>
                <Grid item xs={4} sx={simpleGridStyle}>
                  {loc.label}
                </Grid>
                <Grid item xs={4} sx={simpleGridStyle}>
                  {loc.quantity}
                </Grid>
                <Grid item xs={4} sx={simpleGridStyle}>
                  {loc.outstandingVariance ? 'Yes' : 'No'}
                </Grid>
              </>
            ))}
          </Grid>
        </>
      );
    }
  }

  return table;
};

function SearchInventoryScreen() {
  const [transactionProcess, setTransactionProcess] = useState('');
  const [allTransactionData, setAllTransactionData] = useState({});
  const [tableItem, setTableItem] = useState('');
  const [warehouseData, setWarehouseData] = useState({});

  const [loader, setLoader] = useState(true);
  const [loadModal, setLoadModal] = useState(false);
  const [modalData, setModalData] = useState(null);

  const [pickModal, setPickModal] = useState(false);
  const [putModal, setPutModal] = useState(false);
  const [checkIn, setCheckIn] = useState(false);
  const [checkOut, setCheckOut] = useState(false);
  const [report, setReport] = useState(false);
  const [reserve, setReserve] = useState(false);

  const dispatch = useDispatch();
  const transactionItem = useSelector(ItemSelectors.getPprData);

  useEffect(() => {
    if (transactionItem) {
      getTransactionItems(transactionItem);
    }
  }, [transactionItem]);

  const getTransactionItems = (transactionItemResult) => {
    setLoader(false);
    setTableItem(transactionItemResult?._id);
    setTransactionProcess(
      transactionItemResult?.widgetFamily?.inventory?.policies?.inventory_process
    );
    setAllTransactionData(transactionItemResult);
    setWarehouseData(getWarehouseData(transactionItemResult.locations));
    if (loadModal) {
      if (reserve) {
        setModalData(getReserveModalData(transactionItemResult));
      } else if (pickModal || putModal) {
        setModalData(getModalData(transactionItemResult));
      } else {
        setModalData(getCCRModalData(transactionItemResult));
      }
    }
  };

  const getWarehouseData = (locations) => {
    return locations?.reduce((acc, loc) => {
      return {
        ...acc,
        [loc.warehouse.name]: [...(acc[loc.warehouse.name] || []), loc]
      };
    }, {});
  };

  React.useEffect(() => {
    if (tableItem) {
      tableItemData(tableItem);
    }
  }, [loadModal]);

  const tableItemData = (id) => {
    dispatch(
      ItemActions.itemPprRequest({
        loader: 'location-request',
        slug: `${API.GET_ITEM_FOR_TRANSACTION_PPR.replace(':id', id)}`,
        method: 'get'
      })
    );
  };

  const getModalData = (currentData) => {
    return {
      inventoryData: {
        inventoryId: currentData?._id,
        widgetName: currentData?.commonName,
        type: currentData?.type,
        size: currentData?.size,
        color: currentData?.color,
        images: currentData?.images
      },
      locationList: currentData?.locations?.map((location) => {
        return {
          subLevelId: location._id,
          availableQuantity: Math.min(location.quantity, currentData?.availableQuantity),
          warehouseId: location.warehouse._id
        };
      })
    };
  };

  const getReserveModalData = (currentItem) => {
    return {
      inventoryData: {
        inventoryId: currentItem?._id,
        widgetName: currentItem?.commonName,
        availQuan: currentItem?.availableQuantity || 0,
        type: currentItem?.type,
        size: currentItem?.size,
        color: currentItem?.color
      },
      locationList: warehouseData
    };
  };

  const getCCRModalData = (currentItem) => {
    return {
      inventoryId: currentItem?._id,
      locationList: currentItem.availabilityStatus
        ? [
            {
              subLevelId: currentItem.subLevelId,
              availability: currentItem.availabilityStatus
            }
          ]
        : null
    };
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Breadcrumbs
        title="Warehouse Management System"
        route={[{ name: 'Home', path: '/home' }, { name: 'Search Inventory' }]}
      />

      <MDBox px={5} py={3}>
        <SearchInventoryMain onItemTransaction={getTransactionItems} />
        <Grid container className={loader ? 'loader' : ''} spacing={4}>
          <Grid item xs={6} md={3}>
            <Box
              sx={{
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex'
              }}
            >
              {tableItem ? (
                <QRcode
                  payload={JSON.stringify({
                    type: 'Widget',
                    id: tableItem
                  })}
                  width={200}
                />
              ) : (
                ''
              )}
            </Box>
            {transactionProcess === 'PPR' ? (
              <Box>
                <MDButton
                  color="success"
                  variant="outlined"
                  type="submit"
                  sx={{ minWidth: '100%', padding: '13px 40px' }}
                  onClick={() => {
                    setLoader(true);
                    setLoadModal(true);
                    setPickModal(true);
                  }}
                >
                  {'Pick'}
                </MDButton>
                <MDButton
                  color="warning"
                  variant="outlined"
                  sx={{ minWidth: '100%', marginTop: '20px', padding: '13px 40px' }}
                  onClick={() => {
                    setLoader(true);
                    setLoadModal(true);
                    setPutModal(true);
                  }}
                >
                  {'Put'}
                </MDButton>
                <MDButton
                  color="primary"
                  variant="outlined"
                  sx={{ minWidth: '100%', marginTop: '20px', padding: '13px 40px' }}
                  onClick={() => {
                    setLoader(true);
                    setLoadModal(true);
                    setReserve(true);
                  }}
                >
                  {'Reserve'}
                </MDButton>
              </Box>
            ) : (
              ''
            )}
            {transactionProcess === 'CCR' ? (
              <Box>
                <MDButton
                  color="success"
                  variant="outlined"
                  sx={{ minWidth: '100%', padding: '13px 40px' }}
                  disabled={allTransactionData.availabilityStatus === 'Checked-In'}
                  onClick={() => {
                    setLoader(true);
                    setLoadModal(true);
                    setCheckIn(true);
                  }}
                >
                  {'Check In'}
                </MDButton>
                <MDButton
                  color="warning"
                  variant="outlined"
                  sx={{ minWidth: '100%', marginTop: '20px', padding: '13px 40px' }}
                  disabled={allTransactionData.availabilityStatus === 'Checked-Out'}
                  onClick={() => {
                    setLoader(true);
                    setLoadModal(true);
                    setCheckOut(true);
                  }}
                >
                  {'Check Out'}
                </MDButton>
                <MDButton
                  color="primary"
                  variant="outlined"
                  sx={{ minWidth: '100%', marginTop: '20px', padding: '13px 40px' }}
                  onClick={() => {
                    setLoader(true);
                    setLoadModal(true);
                    setReport(true);
                  }}
                >
                  {'Report'}
                </MDButton>
              </Box>
            ) : (
              ''
            )}
          </Grid>
          <Grid item xs={6} md={9}>
            <Paper sx={{ padding: '20px' }}>
              <Grid container spacing={5}>
                {transactionProcess === 'PPR' && (
                  <>
                    <Grid item sm={6} md={8}>
                      <MDTypography>Formal Name</MDTypography>
                      <MDTypography sx={customStyles.labelSize}>
                        {allTransactionData.formalName}
                      </MDTypography>
                    </Grid>
                    <Grid item sm={6} md={4}>
                      <MDTypography>Size | Type | Color</MDTypography>
                      <MDTypography sx={customStyles.labelSize}>
                        {allTransactionData.size} | {allTransactionData.type} |{' '}
                        {allTransactionData.color}
                      </MDTypography>
                    </Grid>
                    <Grid item sm={6} md={8}>
                      <MDTypography>Description</MDTypography>
                      <MDTypography sx={customStyles.labelSize}>
                        {allTransactionData.description}
                      </MDTypography>
                    </Grid>
                    <Grid item sm={6} md={4}>
                      <MDTypography>Manufacturer</MDTypography>
                      <MDTypography sx={customStyles.labelSize}>
                        {allTransactionData.manufacturer}
                      </MDTypography>
                    </Grid>
                    <Grid item sm={6} md={8}>
                      <MDTypography>Unit of Material</MDTypography>
                      <MDTypography sx={customStyles.labelSize}>
                        {allTransactionData.unitOfMaterial}
                      </MDTypography>
                    </Grid>
                    <Grid item sm={6} md={4}>
                      <MDTypography>Unit Cost</MDTypography>
                      <MDTypography sx={customStyles.labelSize}>
                        {allTransactionData.unitCost}
                      </MDTypography>
                    </Grid>
                    <Grid item sm={6} md={8}>
                      <MDTypography>Count Per Pallet</MDTypography>
                      <MDTypography sx={customStyles.labelSize}>
                        {allTransactionData.countPerPallet}
                      </MDTypography>
                    </Grid>
                    <Grid item sm={6} md={4}>
                      <MDTypography>Count Per Pallet Package</MDTypography>
                      <MDTypography sx={customStyles.labelSize}>
                        {allTransactionData.countPerPalletPackage}
                      </MDTypography>
                    </Grid>
                    <Grid item sm={6} md={8}>
                      <MDTypography>Package Count</MDTypography>
                      <MDTypography sx={customStyles.labelSize}>
                        {allTransactionData.packageCount}
                      </MDTypography>
                    </Grid>
                    <Grid item sm={6} md={4}>
                      <MDTypography>Reserved</MDTypography>
                      <MDTypography sx={customStyles.labelSize}>
                        {allTransactionData.reservedQuantity}
                      </MDTypography>
                    </Grid>
                    <Grid item xs={12}>
                      <MDTypography>Locations</MDTypography>
                      {allTransactionData.locations.length ? (
                        displayLocations(
                          allTransactionData.locations?.filter(
                            (location) =>
                              !(
                                allTransactionData.widgetFamily?.inventory?.policies
                                  ?.inventory_process === 'PPR' && location.quantity === 0
                              )
                          )
                        )
                      ) : (
                        <pre>Item not checked in at any location</pre>
                      )}
                    </Grid>
                  </>
                )}
                {transactionProcess === 'CCR' && (
                  <>
                    <Grid item sm={6} md={8}>
                      <MDTypography>Formal Name</MDTypography>
                      <MDTypography sx={customStyles.labelSize}>
                        {allTransactionData.formalName}
                      </MDTypography>
                    </Grid>
                    <Grid item sm={6} md={4}>
                      <MDTypography>Size | Type | Color</MDTypography>
                      <MDTypography sx={customStyles.labelSize}>
                        {allTransactionData.size} | {allTransactionData.type} |{' '}
                        {allTransactionData.color}
                      </MDTypography>
                    </Grid>
                    <Grid item sm={6} md={8}>
                      <MDTypography>Description</MDTypography>
                      <MDTypography sx={customStyles.labelSize}>
                        {allTransactionData.description}
                      </MDTypography>
                    </Grid>
                    <Grid item sm={6} md={4}>
                      <MDTypography>Manufacturer</MDTypography>
                      <MDTypography sx={customStyles.labelSize}>
                        {allTransactionData.manufacturer}
                      </MDTypography>
                    </Grid>
                    <Grid item sm={6} md={8}>
                      <MDTypography>Status</MDTypography>
                      <MDTypography sx={customStyles.labelSize}>
                        {allTransactionData.availabilityStatus}
                      </MDTypography>
                    </Grid>
                  </>
                )}
              </Grid>
            </Paper>
          </Grid>
          {modalData && (
            <>
              <PickupBase
                show={pickModal}
                setShow={() => {
                  setPickModal(false);
                  setLoadModal(false);
                  setModalData(null);
                }}
                inventoryItem={modalData}
              />
              <CompletePutting
                show={putModal}
                setShow={() => {
                  setPutModal(false);
                  setLoadModal(false);
                  setModalData(null);
                }}
                inventoryItem={modalData}
              />
              <ScanReserve
                show={reserve}
                setShow={() => {
                  setReserve(false);
                  setLoadModal(false);
                  setModalData(null);
                }}
                inventoryItem={modalData}
              />
              <ScanCheckin
                show={checkIn}
                setShow={() => {
                  setCheckIn(false);
                  setLoadModal(false);
                  setModalData(null);
                }}
                inventoryItem={modalData}
              />
              <ScanCheckOut
                show={checkOut}
                setShow={() => {
                  setCheckOut(false);
                  setLoadModal(false);
                  setModalData(null);
                }}
                inventoryItem={modalData}
              />
              <ScanReport
                show={report}
                setShow={() => {
                  setReport(false);
                  setLoadModal(false);
                  setModalData(null);
                }}
                inventoryItem={modalData}
              />
            </>
          )}
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}
export default SearchInventoryScreen;
