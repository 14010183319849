import React, { useState } from 'react';
import { Box, Grid } from '@mui/material';
import NotificationItem from './NotificationItem';
import { useDispatch, useSelector } from 'react-redux';
import NotificationActions from 'redux/NotificationRedux';
import { NotificationSelectors } from 'redux/NotificationRedux';

function AppNotifications() {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);

  React.useEffect(() => {
    dispatch(
      NotificationActions.getNotificationAction({
        loader: 'notification-request',
        slug: `/notification/all?page=${page}&perPage=10`,
        method: 'get'
      })
    );
  }, [page]);

  const notifications = useSelector(NotificationSelectors.allNotifications);
  const totalCount = useSelector(NotificationSelectors.getTotalCount);

  return (
    <Box
      sx={{
        position: 'absolute',
        top: 75,
        width: '30vw',
        maxHeight: '60vh',
        right: 5,
        zIndex: 1,
        background: '#f3f3f3',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Grid container direction="column" spacing={1}>
        {notifications && notifications.length ? (
          notifications.map((notification) => (
            <Grid item key={notification._id}>
              <NotificationItem data={notification} />
            </Grid>
          ))
        ) : (
          <Grid container padding={1} borderRadius={5} justifyContent="center" alignItems="center">
            <Grid item>No notifications</Grid>
          </Grid>
        )}
        {notifications && totalCount && notifications.length < totalCount ? (
          <Grid
            container
            padding={1}
            borderRadius={5}
            justifyContent="center"
            alignItems="center"
            onClick={() => {
              setPage(page + 1);
            }}
          >
            <Grid item>Load more notifications</Grid>
          </Grid>
        ) : (
          <Grid
            container
            padding={1}
            borderRadius={5}
            justifyContent="center"
            alignItems="center"
            onClick={() => {
              setPage(page + 1);
            }}
          >
            <Grid item>No more notifications</Grid>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

export default AppNotifications;
