import React, { useEffect, useState } from 'react';

import DashboardLayout from 'layouts/DashboardLayout';
import DashboardNavbar from 'components/DashboardNavbar';
import Breadcrumbs from 'components/Breadcrumbs';
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TableBody,
  TableCell,
  TableRow
} from '@mui/material';
import MDBox from 'components/MDBox';
import { useDispatch, useSelector } from 'react-redux';
import { ReportSelectors } from 'redux/ReportsRedux';
import ReportActions from 'redux/ReportsRedux';
import { API } from 'constant';
import MDButton from 'components/Button';
import BasicTable from 'components/BasicTable';
import WarehouseActions from 'redux/WarehouseRedux';
import InventoryActions from 'redux/InventoryRedux';
import { WarehouseSelectors } from 'redux/WarehouseRedux';
import { InventorySelectors } from 'redux/InventoryRedux';
import TablePagination from 'components/TablePagination';
import { WidgetSelectors } from 'redux/WidgetRedux';
import WidgetActions from 'redux/WidgetRedux';
import { IosShareOutlined } from '@mui/icons-material';
import { exportToExcel } from 'utils/exportExcel';

const tHeads = [
  { id: 'itemName', label: 'Item Name', textAlign: 'left' },
  { id: 'warehouse', label: 'Warehouse', textAlign: 'left' },
  { id: 'inventory', label: 'Inventory', textAlign: 'left' },
  { id: 'primaryWidgetFamily', label: 'Primary Widget Family', textAlign: 'left' },
  { id: 'secondaryWidgetFamily', label: 'Secondary Widget Family', textAlign: 'left' },
  { id: 'size', label: 'Size', textAlign: 'left' },
  { id: 'thickness', label: 'Thickness', textAlign: 'left' },
  { id: 'color', label: 'Color', textAlign: 'left' },
  { id: 'location', label: 'Location', textAlign: 'left' },
  { id: 'total', label: 'Total Quantity', textAlign: 'center' },
  { id: 'reserved', label: 'Reserved Quantity', textAlign: 'center' },
  { id: 'available', label: 'Available Quantity', textAlign: 'center' },
  { id: 'unitCost', label: 'Unit Cost', textAlign: 'right' },
  { id: 'cost', label: 'Inventory Cost', textAlign: 'right' },
  { id: 'costQuantity', label: 'Available Quanity Cost', textAlign: 'right' }
];

const InventoryReport = () => {
  const dispatch = useDispatch();

  /**
   * States
   */
  const [filterWarehouse, setFilterWarehouse] = useState('');
  const [filterInventory, setFilterInventory] = useState('');
  const [filterPrimaryFamily, setFilterPrimaryFamily] = useState('');
  const [filterSecondaryFamily, setFilterSecondaryFamily] = useState('');

  const [applyCount, setApplyCount] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(50);

  /**
   * Context
   */
  const warehouses = useSelector(WarehouseSelectors.getWarehouseDetail);
  const inventories = useSelector(InventorySelectors.getInventoryDetail);
  const primaryFamilies = useSelector(
    WidgetSelectors.getWidgetFamiliesByInventoryId(filterInventory)
  );
  const secondaryFamilies = useSelector(WidgetSelectors.getWidgetsByParentId(filterPrimaryFamily));

  const mapHeadsToReponse = (resp) =>
    resp.map((itemAssociation) => {
      let location = [];
      let totalQuantity = 0;
      let InventoryCost = 0;
      for (let i in itemAssociation.locations) {
        location.push(
          `${itemAssociation?.locations[i]?.locationLabel} [${itemAssociation?.locations[i]?.totalQuantity}]`
        );
        totalQuantity += itemAssociation?.locations[i]?.totalQuantity;
        InventoryCost += Math.round(
          itemAssociation?.locations[i]?.totalQuantity * itemAssociation?._id?.item_id?.unitCost
        );
      }

      location = location.join('\n\n');
      let temp = {
        id: itemAssociation?._id || '',
        location: location || '',
        itemName: itemAssociation?._id?.item_id?.formalName || '',
        total: totalQuantity || '0',
        reserved: itemAssociation?.reservedQuantity || '0',
        available: totalQuantity - itemAssociation?.reservedQuantity || '0',
        cost: `$ ${InventoryCost.toLocaleString('en-US')}` || '0',
        unitCost:
          `$ ${Math.round(itemAssociation?._id?.item_id?.unitCost).toLocaleString('en-US')}` || 0,
        inventory: itemAssociation?._id?.item_id?.widgetFamily?.inventory?.name || '',
        size: itemAssociation?._id?.item_id?.size || '',
        thickness: itemAssociation?._id?.item_id?.type || '',
        color: itemAssociation?._id?.item_id?.color || '',
        warehouse: warehouses.find((x) => x._id === itemAssociation?._id?.warehouse_id)?.name || ''
      };
      temp.costQuantity = Math.round(itemAssociation?._id?.item_id?.unitCost) * temp.available;
      temp.costQuantity = `$ ${temp.costQuantity.toLocaleString('en-US')}`;
      if (itemAssociation?._id?.item_id?.widgetFamily?.parent?.name) {
        temp = {
          ...temp,
          primaryWidgetFamily: itemAssociation?._id?.item_id?.widgetFamily?.parent?.name || '',
          secondaryWidgetFamily: itemAssociation?._id?.item_id?.widgetFamily?.name || ''
        };
      } else {
        temp = {
          ...temp,
          primaryWidgetFamily: itemAssociation?._id?.item_id?.widgetFamily?.name || ''
        };
      }
      return temp;
    });

  const inventoryReportData = mapHeadsToReponse(useSelector(ReportSelectors.getReportDetail));
  const reportType = useSelector(ReportSelectors.getReportType);
  const count = useSelector(ReportSelectors.getReportCount);
  const totalInventoryCost = useSelector(ReportSelectors.getTotalInventoryCost);

  const buildParams = () => {
    let params = [];
    if (filterWarehouse) params.push(`warehouseId=${filterWarehouse}`);
    if (filterInventory) params.push(`inventoryId=${filterInventory}`);
    if (filterSecondaryFamily) {
      params.push(`widgetFamilyId=${filterSecondaryFamily}`);
    } else if (filterPrimaryFamily) {
      params.push(`widgetFamilyId=${filterPrimaryFamily}`);
    }
    if (page) params.push(`page=${page}`);
    if (perPage) params.push(`perPage=${perPage}`);
    return params.length ? `?${params.join('&')}` : '';
  };

  /**
   * Effects
   */
  useEffect(() => {
    (!warehouses || warehouses.length === 0) &&
      dispatch(
        WarehouseActions.warehouseDataAction({
          loader: 'loading-request',
          slug: API.GET_WAREHOUSE_DATA,
          method: 'get'
        })
      );
    (!inventories || inventories.length === 0) &&
      dispatch(
        InventoryActions.getInventoryAction({
          loader: 'loading-request',
          slug: API.GET_INVENTORY,
          method: 'get'
        })
      );
  }, []);

  useEffect(() => {
    filterInventory &&
      (!primaryFamilies || primaryFamilies.length === 0) &&
      dispatch(
        WidgetActions.widgetRequest({
          loader: 'location-request',
          slug: `${API.GET_WIDGET_FAMILY_BY_INVENTORY}${filterInventory}`,
          method: 'get'
        })
      );
  }, [filterInventory]);

  useEffect(() => {
    dispatch(
      ReportActions.getReportAction({
        loader: 'loading-request',
        slug: API.REPORT_INVENTORY + buildParams(),
        method: 'get',
        reportType: 'inventory'
      })
    );
  }, [applyCount, page, perPage]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Breadcrumbs
        title="Inventory Report"
        route={[
          { name: 'Home', path: '/home' },
          { name: 'Reports', path: '/reports' },
          { name: 'Inventory Report' }
        ]}
      />
      <MDBox px={5} py={3}>
        <Grid
          container
          spacing={2}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'space-evenly'
          }}
        >
          <Grid item xs={4}>
            <FormControl fullWidth sx={{ marginBottom: '10px' }}>
              <InputLabel>Filter by Warehouse</InputLabel>
              <Select
                fullWidth
                labelId="warehouse-filter-label"
                id="warehouse-filter"
                value={filterWarehouse}
                label="Filter by Warehouse"
                onChange={(e) => setFilterWarehouse(e.target.value)}
              >
                <MenuItem key="default" value="">
                  <em>All</em>
                </MenuItem>
                {warehouses &&
                  warehouses.map((warehouse) => (
                    <MenuItem key={warehouse._id} value={warehouse._id}>
                      {warehouse.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControl fullWidth sx={{ marginBottom: '10px' }}>
              <InputLabel>Filter by Inventory</InputLabel>
              <Select
                fullWidth
                labelId="inventory-filter-label"
                id="inventory-filter"
                value={filterInventory}
                label="Filter by Inventory"
                onChange={(e) => {
                  setFilterInventory(e.target.value);
                  setFilterPrimaryFamily('');
                  setFilterSecondaryFamily('');
                }}
              >
                <MenuItem key="default" value="">
                  <em>All</em>
                </MenuItem>
                {inventories &&
                  inventories.map((inventory) => (
                    <MenuItem key={inventory._id} value={inventory._id}>
                      {inventory.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={4}>
            <FormControl fullWidth sx={{ marginBottom: '10px' }}>
              <InputLabel>Filter Primary Family</InputLabel>
              <Select
                select
                fullWidth
                id="primaryWidgetFilter"
                labelId="primaryWidgetFilterLabel"
                name="primaryWidgetFamilyId"
                value={filterPrimaryFamily}
                onChange={(e) => {
                  setFilterSecondaryFamily('');
                  setFilterPrimaryFamily(e.target.value);
                }}
              >
                <MenuItem key={'none'} value={''}>
                  Family
                </MenuItem>
                {primaryFamilies &&
                  [...primaryFamilies]
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((fam) => (
                      <MenuItem key={fam._id} value={fam._id}>
                        {fam.name}
                      </MenuItem>
                    ))}
              </Select>
            </FormControl>
            <FormControl fullWidth sx={{ marginBottom: '10px' }}>
              <InputLabel>Filter Secondary Family</InputLabel>
              <Select
                select
                fullWidth
                id="secondaryWidgetFilter"
                labelId="secondaryWidgetFilterLabel"
                name="secondaryWidgetFamilyId"
                value={filterSecondaryFamily}
                onChange={(e) => {
                  setFilterSecondaryFamily(e.target.value);
                }}
              >
                <MenuItem key={'none'} value={''}>
                  Sub Family
                </MenuItem>
                {secondaryFamilies &&
                  [...secondaryFamilies]
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((fam) => (
                      <MenuItem key={fam._id} value={fam._id}>
                        {fam.name}
                      </MenuItem>
                    ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={2}>
            <MDButton
              fullWidth
              size="medium"
              color="primary"
              variant="outlined"
              sx={{ marginBottom: '10px' }}
              onClick={() => {
                setFilterInventory('');
                setFilterWarehouse('');
                setFilterPrimaryFamily('');
                setFilterSecondaryFamily('');
              }}
            >
              RESET
            </MDButton>
            <MDButton
              fullWidth
              size="medium"
              color="primary"
              sx={{ marginBottom: '10px' }}
              onClick={() => {
                setPage(1);
                setApplyCount(applyCount + 1);
              }}
            >
              APPLY
            </MDButton>
          </Grid>

          <Grid item xs={2}>
            <MDButton
              fullWidth
              size="medium"
              color="primary"
              sx={{ marginBottom: '10px', height: '80%' }}
              onClick={() => {
                const sortedTableData = [...inventoryReportData];
                sortedTableData.sort((a, b) => {
                  if (a.primaryWidgetFamily !== b.primaryWidgetFamily) {
                    return a.primaryWidgetFamily > b.primaryWidgetFamily ? -1 : 1;
                  }
                  if (a.secondaryWidgetFamily !== b.secondaryWidgetFamily) {
                    return a.secondaryWidgetFamily > b.secondaryWidgetFamily ? -1 : 1;
                  }
                  if (a.size !== b.size) {
                    return a.size > b.size ? -1 : 1;
                  }
                  return a.itemName > b.itemName ? -1 : 1;
                });
                exportToExcel(
                  tHeads,
                  sortedTableData,
                  'Inventory'
                );
              }}
            >
              <IosShareOutlined />
              {'  '}Export to excel
            </MDButton>
          </Grid>
        </Grid>
        {reportType === 'inventory' ? (
          <>
            <b>Total Inventory Cost:</b> $
            {totalInventoryCost
              ? totalInventoryCost.toLocaleString('en-US')
              : Number(0).toLocaleString('en-US')}
            <Box sx={{ overflowX: 'auto', overflowY: 'auto', height: '60vh' }}>
              <BasicTable headCells={tHeads} backgroundColor="#E5E5E5" color="#8D8D8D">
                <TableBody>
                  {inventoryReportData && inventoryReportData.length
                    ? inventoryReportData.map((item) => (
                        <TableRow key={item.id}>
                          {tHeads.map((head, i) => (
                            <TableCell
                              key={i}
                              sx={{
                                textAlign: `${head.textAlign} !important`,
                                whiteSpace: 'pre !important'
                              }}
                            >
                              {item[head.id]}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))
                    : 'No records'}
                </TableBody>
              </BasicTable>
            </Box>
          </>
        ) : (
          'Loading'
        )}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '15px 10px'
          }}
        >
          <Box sx={{ fontSize: '14px', color: '#000' }}>
            Per page:{' '}
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={perPage}
              label="Page Number"
              onChange={(e) => {
                setPerPage(e.target.value);
              }}
            >
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
              <MenuItem value={200}>200</MenuItem>
              <MenuItem value={500}>500</MenuItem>
              <MenuItem value={1000}>1000</MenuItem>
              <MenuItem value={10000000}>All</MenuItem>
            </Select>
          </Box>
          <Box>
            <TablePagination count={Math.ceil(count / perPage)} page={page} setPage={setPage} />
          </Box>
          <Box sx={{ fontSize: '14px', color: '#000' }}>
            {!!count && (
              <>
                {(page - 1) * perPage + 1} to {perPage * page > count ? count : perPage * page} of{' '}
                {count}
              </>
            )}
          </Box>
        </Box>
      </MDBox>
    </DashboardLayout>
  );
};

export default InventoryReport;
