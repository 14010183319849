import { AuthorizedAPI } from 'config';
import { toast } from 'react-toastify';
import { call, put, takeEvery } from 'redux-saga/effects';
import ReportActions from 'redux/ReportsRedux';
import { ReportTypes } from 'redux/ReportsRedux';
import ApiServices from 'services/API/ApiServices';

export function* onRequestGetReportData({ payload }) {
  const response = yield call(
    ApiServices[payload?.method],
    AuthorizedAPI,
    payload?.slug,
    payload?.data
  );
  if (response?.status === 200 && response.data?.success) {
    yield put(
      ReportActions.getReportSuccess({
        loader: payload?.loader,
        getReportDetail: response.data?.data,
        reportType: payload?.reportType,
        count: response.data?.count,
        totalInventoryCost: response.data?.totalInventoryCost
      })
    );
  } else {
    payload.onFailedGetLabelData(response.data.error);
    yield put(
      ReportActions.getReportFailure({
        loader: payload?.loader,
        error: response?.data
      })
    );
  }
}

export function* onRequestCancelReservation({ payload }) {
  const response = yield call(
    ApiServices[payload?.method],
    AuthorizedAPI,
    payload?.slug.replace(':reservationId', payload?.reservationId),
    payload?.data
  );
  if (response?.status === 200) {
    if (response?.data?.success) {
      toast.success(response?.data?.message || 'Successful', {
        theme: 'colored'
      });
      yield put(
        ReportActions.cancelReservationSuccess({
          loader: payload?.loader,
          reservationId: payload?.reservationId
        })
      );
    } else {
      toast.error(response?.data?.message || 'Failed to cancel reservation', {
        theme: 'colored'
      });
      yield put(
        ReportActions.cancelReservationSuccess({
          loader: payload?.loader,
          reservationId: 'NA'
        })
      );
    }
  } else {
    toast.error('Failed to cancel reservation', {
      theme: 'colored'
    });
    yield put(
      ReportActions.cancelReservationFailure({
        loader: payload?.loader,
        error: response?.data
      })
    );
  }
}

export default [
  takeEvery(ReportTypes.GET_REPORT_ACTION, onRequestGetReportData),
  takeEvery(ReportTypes.CANCEL_RESERVATION_ACTION, onRequestCancelReservation)
];
