import { Grid, Stack } from '@mui/material';
import MDTypography from 'components/MDTypography';
import PropTypes from 'prop-types';

const LocationLabel = ({ area, row, bay, level, pos }) => {
  return (
    <Grid
      item
      md={5}
      sx={{
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        py: 2,
        px: 5,
        backgroundColor: '#F5F5F5',
        minHeight: '120px'
      }}
    >
      <LabelLevel label={'AREA'} value={area} />
      <LabelDivider />
      <LabelLevel label={'RACK'} value={row} />
      <LabelDivider />
      <LabelLevel label={'LOCATION'} value={bay} />
      <LabelDivider />
      <LabelLevel label={'LEVEL'} value={level} />
      <LabelDivider />
      <LabelLevel label={'POS'} value={pos} />
    </Grid>
  );
};

const LabelLevel = ({ label, value }) => {
  return (
    <Grid item md={2}>
      <Stack spacing={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <MDTypography
          variant="h6"
          component="span"
          sx={{
            lineHeight: '10px'
          }}
        >
          {label}
        </MDTypography>
        <MDTypography
          variant="h1"
          component="span"
          sx={{
            fontWeight: 'bold',
            lineHeight: '20px'
          }}
        >
          {value}
        </MDTypography>
      </Stack>
    </Grid>
  );
};

const LabelDivider = () => {
  return (
    <MDTypography
      variant="h1"
      component="span"
      sx={{
        fontWeight: 'bold',
        lineHeight: '20px',
        marginTop: '25px'
      }}
    >
      {'-'}
    </MDTypography>
  );
};

LabelLevel.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
};

LocationLabel.propTypes = {
  area: PropTypes.string.isRequired,
  row: PropTypes.string.isRequired,
  bay: PropTypes.string.isRequired,
  level: PropTypes.string.isRequired,
  pos: PropTypes.string.isRequired
};

export default LocationLabel;
