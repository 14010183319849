import React from 'react';
import { Box, Grid } from '@mui/material';
import MDButton from 'components/Button';
import MDInput from 'components/MDInput';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import CrossIcon from 'assets/images/CrossIcon';
import { useDispatch } from 'react-redux';
import { API } from 'constant';
import { useFormik } from 'formik';
import schema from 'services/ValidationServices';
import PprActions from 'redux/PprRedux';

const useStyles = makeStyles(() => ({
  inputLabel: {
    fontSize: '16px',
    letterSpacing: '0.01em',
    color: '#000',
    marginBottom: '4px',
    fontWeight: 'normal',
    '& .MuiFormControlLabel-label': {
      fontWeight: 'normal',
      fontSize: '16px',
      color: '#000'
    }
  },
  centreContent: {
    justifyContent: 'center'
  },
  fullWidth: {
    width: '100%',
    borderColor: '#d2d6da',
    borderRadius: '0.375rem'
  },
  pickupBtn: {
    '&:hover': {
      backgroundColor: '#ff9330',
      color: '#fff'
    }
  },
  cursorPointer: {
    cursor: 'pointer',
    display: 'flex',
    alignSelf: 'end',
    margin: '0'
  }
}));

function ScanReport({ show, setShow, inventoryItem }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      issuedetail: '',
      reporting: ''
    },
    validationSchema: schema.report,
    onSubmit: (values) => {
      dispatch(
        PprActions.pickAction({
          loader: 'loading-request',
          slug: `${API.REPORT.replace(':id', inventoryItem.inventoryId)}`,
          method: 'post',
          data: {
            reportingFor: values.reporting,
            details: values.issuedetail
          },
          callback: () => {
            formik.resetForm();
            setShow();
          }
        })
      );
    }
  });

  return (
    <div>
      <Dialog open={show} maxWidth="xl" onClose={setShow}>
        <MDButton
          className={classes.cursorPointer}
          sx={{ padding: '15px', minWidth: '14px' }}
          onClick={setShow}
        >
          <CrossIcon />
        </MDButton>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent style={{ padding: '20px 47px' }}>
            <Grid container spacing={5} sx={{ alignItems: 'flex-start' }}>
              <Grid item container xs={12} spacing={2}>
                <Grid item xs={12} md={12}>
                  <FormControl sx={{ paddingTop: '16px' }}>
                    <FormLabel id="demo-radio-buttons-group-label" className={classes.inputLabel}>
                      Reporting for
                    </FormLabel>
                    <RadioGroup
                      name="reporting"
                      value={formik.values.reporting}
                      onChange={formik.handleChange}
                    >
                      <FormControlLabel
                        value="LOCATION"
                        control={<Radio />}
                        label="Location"
                        className={classes.inputLabel}
                      />
                      <FormControlLabel
                        value="ISSUE"
                        control={<Radio />}
                        label="Issue"
                        className={classes.inputLabel}
                      />
                      <FormControlLabel
                        value="INCIDENT"
                        control={<Radio />}
                        className={classes.inputLabel}
                        label="Incident"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Box component="div" sx={{}} className={classes.inputLabel}>
                    Issue / Incident Details
                  </Box>
                  <MDInput
                    fullWidth
                    name="issuedetail"
                    type="text"
                    variant="outlined"
                    as="textarea"
                    value={formik.values.issuedetail}
                    error={formik.touched.issuedetail && Boolean(formik.errors.issuedetail)}
                    helperText={
                      formik.touched.issuedetail &&
                      formik.errors.issuedetail && (
                        <div style={{ color: 'red' }}>{formik.errors.issuedetail}</div>
                      )
                    }
                    className={classes.fullWidth}
                    minRows="7"
                    onChange={formik.handleChange}
                  />
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className={classes.centreContent}>
            <MDButton size="medium" color="primary" type="submit">
              REPORT
            </MDButton>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
ScanReport.propTypes = {
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
  inventoryItem: PropTypes.shape({
    inventoryId: PropTypes.string.isRequired
  }).isRequired
};
export default ScanReport;
