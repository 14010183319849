import { AuthorizedAPI } from 'config';
import { call, put, takeEvery } from 'redux-saga/effects';
import NotificationActions from 'redux/NotificationRedux';
import { NotificationTypes } from 'redux/NotificationRedux';
import ApiServices from 'services/API/ApiServices';

export function* onRequestGetNotificationData({ payload }) {
  const response = yield call(
    ApiServices[payload?.method],
    AuthorizedAPI,
    payload?.slug,
    payload?.data
  );
  if (response?.status === 200) {
    yield put(
      NotificationActions.getNotificationSuccess({
        loader: payload?.loader,
        getNotificationDetail: response?.data?.data,
        totalCount: response?.data?.totalCount,
        unreadCount: response?.data?.unreadCount
      })
    );
  } else {
    yield put(
      NotificationActions.getNotificationFailure({
        loader: payload?.loader,
        error: response?.data
      })
    );
  }
}
export function* onRequestToggleReadNotificationData({ payload }) {
  const response = yield call(
    ApiServices[payload?.method],
    AuthorizedAPI,
    payload?.slug,
    payload?.data
  );
  if (response?.status === 200) {
    yield put(
      NotificationActions.toggleReadNotificationSuccess({
        loader: payload?.loader,
        status: response?.data?.data?.status,
        notificationId: payload.notificationId
      })
    );
  } else {
    yield put(
      NotificationActions.getNotificationFailure({
        loader: payload?.loader,
        error: response?.data
      })
    );
  }
}
export function* onRequestDismissNotificationData({ payload }) {
  const response = yield call(
    ApiServices[payload?.method],
    AuthorizedAPI,
    payload?.slug,
    payload?.data
  );
  if (response?.status === 200) {
    yield put(
      NotificationActions.dismissNotificationSuccess({
        loader: payload?.loader,
        notificationId: payload.notificationId
      })
    );
  } else {
    yield put(
      NotificationActions.getNotificationFailure({
        loader: payload?.loader,
        error: response?.data
      })
    );
  }
}

export default [
  takeEvery(NotificationTypes.GET_NOTIFICATION_ACTION, onRequestGetNotificationData),
  takeEvery(NotificationTypes.TOGGLE_READ_NOTIFICATION_ACTION, onRequestToggleReadNotificationData),
  takeEvery(NotificationTypes.DISMISS_NOTIFICATION_ACTION, onRequestDismissNotificationData)
];
