import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import _ from 'underscore';
import { getFetchingValue, getErrorValue } from '../services/Utils';

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  getScanAction: ['payload'],
  getScanSuccess: ['data'],
  getScanFailure: ['error']
});

export const ScanTypes = Types;
const ScanActions = Creators;
export default ScanActions;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  scanData: {},
  getScanDataLoading: false,
  getScanDataError: {}
});

/* ------------- Selectors ------------- */
export const ScanDataSelectors = {
  getScanDetail: (state) => state.scan.scanData
};

/* ------------- Reducers ------------- */
export const onGetScanDataAction = (state, { payload }) =>
  state.merge({
    fetching: _.uniq([state.fetching, payload?.loader]),
    error: getErrorValue(state?.error, payload?.loader)
  });

export const onGetScanDataSuccess = (state, { data }) =>
  state.merge({
    fetching: getFetchingValue(state.fetching, data?.loader),
    error: getErrorValue(state?.error, data?.loader),
    scanData: data.getScanDetail
  });

export const onGetScanDataFailure = (state, { error }) =>
  state.merge({
    fetching: _.without(state.fetching, error?.loader),
    error: { ...state.error, [error?.loader]: error?.error }
  });

/* ------------- Hookup Reducers To Types ------------- */
export const scanReducer = createReducer(INITIAL_STATE, { 
  [Types.GET_SCAN_ACTION]: onGetScanDataAction,
  [Types.GET_SCAN_SUCCESS]: onGetScanDataSuccess,
  [Types.GET_SCAN_FAILURE]: onGetScanDataFailure
});
