/* eslint-disable indent */
// import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

const useStyles = makeStyles({
  bind: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    backgroundColor: '#fff',
    padding: '8px'
  },
  size: {
    fontSize: '12px',
    textTransform: 'uppercase',
    textAlign: 'center',
    marginRight: '15px',
    whiteSpace: 'nowrap'
  },
  sizePos: {
    fontSize: '12px',
    textTransform: 'uppercase',
    textAlign: 'center'
  }
});

const getColorForLevel = (level) => {
  switch (parseInt(level) % 5) {
    case 0:
      return '#FFF305';
    case 1:
      return '#630436';
    case 2:
      return '#00009C';
    case 3:
      return '#228C22';
    case 4:
      return '#FF0000';
    default:
      return '#00009C';
  }
};

const LocationLabel = ({ zone, area, row, bay, level, position, levelData }) => {
  const classes = useStyles();
  return (
    <MDBox
      sx={{
        width: 400,
        height: 138,
        backgroundColor: getColorForLevel(level),
        padding: '32px 40px'
      }}
    >
      <div className={classes.bind}>
        <div>
          <MDTypography variant="h6" className={classes.size}>
            Zone
          </MDTypography>
          <MDTypography variant="h5" color="black" style={{ whiteSpace: 'nowrap' }}>
            {zone}&nbsp;&nbsp;-
          </MDTypography>
        </div>
        <div>
          <MDTypography variant="h6" className={classes.size}>
            Area
          </MDTypography>
          <MDTypography variant="h5" color="black" style={{ whiteSpace: 'nowrap' }}>
            {area}&nbsp; &nbsp;-
          </MDTypography>
        </div>
        <div>
          <MDTypography variant="h6" className={classes.size}>
            Rack
          </MDTypography>
          <MDTypography variant="h5" color="black">
            {row}&nbsp; &nbsp;-
          </MDTypography>
        </div>
        <div>
          <MDTypography variant="h6" className={classes.size}>
            Location
          </MDTypography>
          <MDTypography variant="h5" color="black">
            {bay}&nbsp; &nbsp;-
          </MDTypography>
        </div>
        <div>
          <MDTypography variant="h6" className={classes.size}>
            Level
          </MDTypography>
          <MDTypography variant="h5" color="black">
            {level}&nbsp; &nbsp;-
          </MDTypography>
        </div>
        <div>
          <MDTypography variant="h6" className={classes.sizePos}>
            Pos
          </MDTypography>
          <MDTypography variant="h5" color="black">
            {position || ' '}
          </MDTypography>
        </div>
      </div>
    </MDBox>
  );
};

LocationLabel.propTypes = {
  zone: PropTypes.string.isRequired,
  area: PropTypes.string.isRequired,
  row: PropTypes.string.isRequired,
  bay: PropTypes.string.isRequired,
  level: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  levelData: PropTypes.string.isRequired
};

export default LocationLabel;
