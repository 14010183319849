import React, { useState } from 'react';
import { Box } from '@mui/material';
import MDButton from 'components/Button';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import CodeScanner from 'components/CodeScanner';
import QRcode from 'components/QRcode';
import MDTypography from 'components/MDTypography';

const useStyles = makeStyles(() => ({
  barCodeWrapper: {
    position: 'relative',
    width: '80%',
    height: '150px'
  },
  boxLeft: {
    width: '25px',
    height: '25px',
    borderTop: '5px solid #1A73E8',
    borderLeft: '5px solid #1A73E8',
    position: 'absolute',
    top: '0',
    left: '0'
  },
  boxRight: {
    width: '25px',
    height: '25px',
    borderTop: '5px solid #1A73E8',
    borderRight: '5px solid #1A73E8',
    position: 'absolute',
    top: '0',
    right: '0'
  },
  boxBottomLeft: {
    width: '25px',
    height: '25px',
    borderLeft: '5px solid #1A73E8',
    borderBottom: '5px solid #1A73E8',
    position: 'absolute',
    bottom: '0',
    left: '0'
  },
  boxBottomRight: {
    width: '25px',
    height: '25px',
    borderBottom: '5px solid #1A73E8',
    borderRight: '5px solid #1A73E8',
    position: 'absolute',
    right: '0',
    bottom: '0'
  }
}));

/*eslint no-mixed-spaces-and-tabs: ["error", "smart-tabs"]*/
function PickupScanGrid({ images, locationId, inventoryArr, onLocationFound, needLocationCheck }) {
  const classes = useStyles();
  const [scanBtn, setScanBtn] = useState(true);
  const [scanner, setScanner] = useState(false);
  const [qrCode, setQRCode] = useState(null);
  const [inventoryData, setInventoryData] = useState(null);
  const [errorText, setErrorText] = useState('');

  React.useEffect(() => {
    setInventoryData(inventoryArr);
    if (locationId) {
      setQRCode({ type: 'Sublevel', id: locationId });
      setScanBtn(false);
    }
  }, []);

  const checkLocationId = (result) => {
    setScanner(false);
    if (result.type === 'Sublevel') {
      if (needLocationCheck) {
        onLocationFound(result);
        setQRCode(result);
        setErrorText('');
      } else if (Array.isArray(inventoryData)) {
        const itemData = inventoryData?.find((item) => item.subLevelId === result.id); // TODO: need to use correct id
        if (itemData) {
          onLocationFound({ ...itemData, images });
          setQRCode(result);
          setErrorText('');
        } else {
          setErrorText('This item is not present in this location');
          setScanBtn(true);
        }
      }
    } else {
      setErrorText('Location not found');
      setScanBtn(true);
    }
  };

  return (
    <>
      {images?.[0]?.url && (
        <Box
          src={images?.[0]?.url}
          component="img"
          sx={{
            height: 233,
            width: 350,
            maxHeight: { xs: 233, md: 205 },
            maxWidth: { xs: 350, md: 304 }
          }}
        />
      )}
      <Box className={classes.barCodeWrapper}>
        {/* <Box className={classes.boxLeft}></Box>
			<Box className={classes.boxRight}></Box>
			<Box className={classes.boxBottomLeft}></Box>
			<Box className={classes.boxBottomRight}></Box> */}

        <Box
          sx={{
            position: 'absolute',
            top: '40px',
            width: '100%',
            display: 'grid',
            justifyContent: 'center'
          }}
        >
          {scanner && (
            <CodeScanner
              width="100%"
              height="100%"
              onDetect={(value) => {
                checkLocationId(JSON.parse(value));
              }}
            />
          )}
          {scanBtn && (
            <MDButton
              size="medium"
              sx={{
                margin: '10px auto 0 auto',
                width: '100%',
                display: 'flex',
                justifyContent: 'center'
              }}
              color="primary"
              onClick={() => {
                setScanner(true);
                setScanBtn(false);
              }}
            >
              Scan Location
            </MDButton>
          )}
          {errorText && (
            <MDTypography sx={{ fontSize: '16px', color: '#ff0000' }}>{errorText} </MDTypography>
          )}
          {qrCode && <QRcode payload={JSON.stringify(qrCode)} width={200} height={200} />}
        </Box>
        {/* {scanner ? (
          <CodeScanner
            onDetect={JSON.stringify({
              type: 'Sublevel',
              id: images._id
            })}
          />
        ) : (
          ''
        )} */}
      </Box>
    </>
  );
}
PickupScanGrid.propTypes = {
  images: PropTypes.any,
  locationId: PropTypes.string,
  inventoryArr: PropTypes.any,
  onLocationFound: PropTypes.func,
  needLocationCheck: PropTypes.bool
};

export default PickupScanGrid;
