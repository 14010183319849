import React from 'react';
import { Dialog } from '@mui/material';
import { useFormik } from 'formik';
import schema from 'services/ValidationServices';
import { useDispatch } from 'react-redux';
import { API } from 'constant';

import PropTypes from 'prop-types';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import CrossIcon from 'assets/images/CrossIcon';
import MDInput from 'components/MDInput';
import AuthActions from 'redux/AuthRedux';

const styles = {
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '2px  0px 10px 5px'
  },
  description: { display: 'flex', justifyContent: 'center', fontSize: '14px', margin: '20px' }
};

const ForgotPasswordModal = ({ modalOpen, setModalClose, setChangePasswordLink }) => {
  const dispatch = useDispatch();
  const [message, setMessage] = React.useState(null);
  const [messageColor, setMessageColor] = React.useState('black');

  const handleClose = () => {
    setModalClose(false);
    setMessage(null);
    setMessageColor('black');
    formik.resetForm();
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      emailPhone: ''
    },
    validationSchema: schema.forgotPassword,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      const onInvalidMail = (errorMessage) => {
        resetForm();
        setSubmitting(false);
        setMessage(errorMessage);
        setMessageColor('error');
      };
      const setLink = (link) => {
        resetForm();
        setModalClose();
        setChangePasswordLink(link);
      };
      const validateMail = () => {
        setMessage('Valid Username');
        setMessageColor('success');
      };
      dispatch(
        AuthActions.forgotPasswordRequest({
          loader: 'login-request',
          slug: API.FORGOT_PASSWORD,
          method: 'post',
          data: { email: values.emailPhone },
          onInvalidMail,
          onValidMail: setChangePasswordLink ? setLink : validateMail
        })
      );
    }
  });
  return (
    <>
      <Dialog
        sx={{
          padding: '20px'
        }}
        open={modalOpen}
        onClose={handleClose}
      >
        <MDBox sx={{ padding: '30px' }}>
          <MDBox sx={styles.titleContainer}>
            <MDTypography variant="h4">
              {setChangePasswordLink ? 'Forgot Password' : 'Validate Username'}
            </MDTypography>
            <MDButton sx={{ minWidth: '14px' }} onClick={handleClose}>
              <CrossIcon />
            </MDButton>
          </MDBox>

          <MDTypography sx={styles.description}>
            {`Enter your Email / Phone number \nto ${
              setChangePasswordLink ? 'OTP to reset password' : 'validate'
            }`}
          </MDTypography>
          <MDBox>
            <MDInput
              sx={{
                minWidth: '400px'
              }}
              name="emailPhone"
              type="email"
              label="Email / Phone Number"
              value={formik.values.emailPhone}
              error={formik.touched.emailPhone && Boolean(formik.errors.emailPhone)}
              helperText={formik.touched.emailPhone && formik.errors.emailPhone}
              onChange={formik.handleChange}
            />
          </MDBox>
          <MDButton
            sx={{
              minWidth: '400px',
              marginTop: '30px'
            }}
            color="info"
            type="submit"
            onClick={formik.handleSubmit}
          >
            {setChangePasswordLink ? 'Send OTP' : 'Validate'}
          </MDButton>
          {message && (
            <MDTypography mb={2} fontSize={14} textAlign="center" color={messageColor}>
              {message}
            </MDTypography>
          )}
        </MDBox>
      </Dialog>
    </>
  );
};

ForgotPasswordModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  setModalClose: PropTypes.func.isRequired,
  setChangePasswordLink: PropTypes.func.isRequired
};

export default ForgotPasswordModal;
