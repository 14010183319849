import React, { useState } from 'react';
import PropTypes from 'prop-types';

import BasicTable from 'components/BasicTable';
import { Box, DialogContent, TableBody, TableCell, TableRow } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  inputLabel: {
    fontSize: '16px',
    letterSpacing: '0.01em',
    color: '#000',
    marginBottom: '4px',
    fontWeight: 'normal',
    '& .MuiFormControlLabel-label': {
      fontWeight: 'normal',
      fontSize: '16px',
      color: '#000'
    }
  }
}));

const ReservePickup = ({ reserveList, setWarehouseId }) => {
  const classes = useStyles();
  const [tableBody, setTableBody] = useState([]);

  React.useEffect(() => {
    setTableBody(
      reserveList.map((item) => {
        return {
          id: item._id,
          warehouseId: item.warehouse_id._id,
          warehouseName: item.warehouse_id.name,
          job: item.job,
          performedBy: item.performedBy.fullName,
          quantity: item.remainingQuantity
        };
      })
    );
  }, []);

  const headCells = [
    {
      id: 'warehouseName',
      label: 'Warehouse name'
    },
    {
      id: 'job',
      label: 'Job'
    },
    {
      id: 'performedBy',
      label: 'Performed By'
    },
    {
      id: 'quantity',
      label: 'Quantity'
    }
  ];

  return (
    <DialogContent sx={{ margin: '0px 10px 20px 10px' }}>
      <Box component="div" sx={{}} className={classes.inputLabel}>
        Select a reservation to pick items
      </Box>
      <BasicTable
        headCells={headCells}
        records={tableBody}
        backgroundColor="#E5E5E5"
        color="#8D8D8D"
      >
        <TableBody>
          {tableBody.map((item) => (
            <TableRow key={item.id} onClick={() => setWarehouseId(item.warehouseId, item.id)}>
              <TableCell>{item?.warehouseName}</TableCell>
              <TableCell>{item?.job}</TableCell>
              <TableCell>{item?.performedBy}</TableCell>
              <TableCell>{item?.quantity}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </BasicTable>
    </DialogContent>
  );
};
export default ReservePickup;

ReservePickup.propTypes = {
  reserveList: PropTypes.any.isRequired,
  setWarehouseId: PropTypes.func.isRequired
};
