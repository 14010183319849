import { AuthorizedAPI } from 'config';
import { toast } from 'react-toastify';
import { takeLatest, call, put } from 'redux-saga/effects';
import PprActions from 'redux/PprRedux';
import { PprTypes } from 'redux/PprRedux';
import ApiServices from 'services/API/ApiServices';
// import {
//   getError,
// } from '../services/Utils';

export function* onRequestPick({ payload }) {
  const response = yield call(
    ApiServices[payload?.method],
    AuthorizedAPI,
    payload?.slug,
    payload?.data
  );
  payload?.callback();
  if (response?.status === 200) {
    toast.success('successful', {
      theme: 'colored'
    });
    yield put(
      PprActions.pickSuccess({
        loader: payload?.loader,
        pick: response?.data?.data
      })
    );
  } else {
    toast.error('Something went wrong', {
      theme: 'colored'
    });
    payload.onFailedPick(response.data.error);
    yield put(
      PprActions.pickFailure({
        loader: payload?.loader,
        error: response?.data
      })
    );
  }
}

export default [takeLatest(PprTypes.PICK_ACTION, onRequestPick)];
