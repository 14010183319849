import PropTypes from 'prop-types';
const ReportsIcon = ({ width = 96, height = 96, color = '#007aff', ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.31 16.95C11.12 16.95 10.93 16.88 10.78 16.73L9.28 15.23C8.99 14.94 8.99 14.46 9.28 14.17C9.57 13.88 10.05 13.88 10.34 14.17L11.31 15.14L14.78 11.67C15.07 11.38 15.55 11.38 15.84 11.67C16.13 11.96 16.13 12.44 15.84 12.73L11.84 16.73C11.7 16.88 11.5 16.95 11.31 16.95Z"
      fill={color}
    />
    <path
      d="M14.5 6.75H10.5C9.54 6.75 7.75 6.75 7.75 4C7.75 1.25 9.54 1.25 10.5 1.25H14.5C15.46 1.25 17.25 1.25 17.25 4C17.25 4.96 17.25 6.75 14.5 6.75ZM10.5 2.75C9.51 2.75 9.25 2.75 9.25 4C9.25 5.25 9.51 5.25 10.5 5.25H14.5C15.75 5.25 15.75 4.99 15.75 4C15.75 2.75 15.49 2.75 14.5 2.75H10.5Z"
      fill={color}
    />
    <path
      d="M15.5 22.75H9.5C3.88 22.75 2.75 20.17 2.75 16V10C2.75 5.44 4.4 3.49 8.46 3.28C8.87 3.26 9.23 3.57 9.25 3.99C9.27 4.41 8.95 4.75 8.54 4.77C5.7 4.93 4.25 5.78 4.25 10V16C4.25 19.7 4.98 21.25 9.5 21.25H15.5C20.02 21.25 20.75 19.7 20.75 16V10C20.75 5.78 19.3 4.93 16.46 4.77C16.05 4.75 15.73 4.39 15.75 3.98C15.77 3.57 16.12 3.25 16.54 3.27C20.6 3.49 22.25 5.44 22.25 9.99V15.99C22.25 20.17 21.12 22.75 15.5 22.75Z"
      fill={color}
    />
  </svg>
);
export default ReportsIcon;

ReportsIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string
};
