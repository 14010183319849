import React from 'react';
// import FormControlLabel from '@mui/material/FormControlLabel';
import PropTypes from 'prop-types';
import MDTypography from 'components/MDTypography';
import { Grid, Box, Paper } from '@mui/material';
import QRcode from 'components/QRcode';

const PrintWidgetLabel = (props) => {
  const previewBarcode = (info) => {
    const quantity = 2;
    const list = [];
    for (let i = 0; i <= quantity; i++) {
      list.push(info);
    }
    let table = list.map((item) => {
      return (
        <Grid item md={4} key={item._id} sx={{ textAlign: 'center' }}>
          <Paper sx={{ padding: '20px' }}>
            <MDTypography component="div">{item.formalName}</MDTypography>
            <QRcode
              payload={JSON.stringify({ type: 'Widget', id: item._id })}
              width={100}
              height={100}
            />
            <MDTypography component="div" sx={{ fontSize: '14px', fontWeight: 'bold' }}>
              {item._id}
            </MDTypography>
          </Paper>
        </Grid>
      );
    });
    return table;
  };

  return (
    <Box>
      <Grid container spacing={3}>
        {props.info.map((qrinfo) => previewBarcode(qrinfo))}
      </Grid>
    </Box>
  );
};

PrintWidgetLabel.propTypes = {
  info: PropTypes.any
};

export default PrintWidgetLabel;
