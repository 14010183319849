import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Grid,
  MenuItem,
  OutlinedInput,
  Chip,
  Select,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  DialogContentText
} from '@mui/material';
import DashboardNavbar from 'components/DashboardNavbar';
import DashboardLayout from 'layouts/DashboardLayout';
import ImageUploadSingle from 'components/ImageUploadSingle';
import MDButton from 'components/Button';
import { useFormik } from 'formik';
// import schema from 'services/ValidationServices';
import MDInput from 'components/MDInput';
import WarehouseActions from 'redux/WarehouseRedux';
import { getChildLocationType } from 'utils/nestedTableTools';
import { getPropertiesOfLocationType } from 'utils/nestedTableTools';
import { getInitialvaluesFromParentData } from 'utils/nestedTableTools';
import LOGGER from 'services/Logger';
import WarehouseLocationsActions from 'redux/WarehouseLocationsRedux';
import { getAPIslugOfLocationType } from 'utils/nestedTableTools';
import { toTitleCase } from 'utils/nestedTableTools';
import { useParams } from 'react-router-dom';
import { WarehouseLocationsSelectors } from 'redux/WarehouseLocationsRedux';
import { API } from 'constant';
import NestedDataTable from 'components/NestedTable';
import Breadcrumbs from 'components/Breadcrumbs';
import { WarehouseSelectors } from 'redux/WarehouseRedux';
import { InventorySelectors } from 'redux/InventoryRedux';
import InventoryActions from 'redux/InventoryRedux';
import { useNavigate } from 'react-router-dom';
import schema from 'services/ValidationServices';
import { makeStyles } from '@mui/styles';
import { AuthSelectors } from 'redux/AuthRedux';
import PendingAssociationsModal from 'components/PendingAssociationsModal';

const bottomButtonStyling = {
  width: '100%',
  textTransform: 'uppercase',
  borderRadius: '100px',
  padding: '13px 30px'
};
const useStyles = makeStyles(() => ({
  fitImage: {
    '& .MuiBox-root': {
      padding: 0,
      '& .MuiBox-root': {
        marginBottom: 0
      }
    },
    '& img': {
      width: '100%'
    }
  }
}));

const AddForm = ({ addFormOpen, setAddFormOpen, selected, warehouseId }) => {
  const dispatch = useDispatch();
  const data = addFormOpen !== 'zone' ? selected : { location: 'warehouse', id: warehouseId };

  const childLocationType = getChildLocationType(data.location);
  const fields = getPropertiesOfLocationType(childLocationType);

  const formik = useFormik({
    initialValues: getInitialvaluesFromParentData(data),
    onSubmit: (values) => {
      LOGGER.log('Form values and parent info', values, data);
      const formData = { ...values };
      formData[`${data.location}_id`] = data.id;
      dispatch(
        WarehouseLocationsActions.addLocationRequest({
          loader: 'location-request',
          slug: getAPIslugOfLocationType(childLocationType),
          method: 'post',
          data: formData,
          parent: {
            id: data.id,
            type: data.location
          }
        })
      );
      setAddFormOpen(false);
    }
  });

  return (
    <Dialog
      open={addFormOpen}
      onClose={() => {
        setAddFormOpen(false);
      }}
    >
      <DialogTitle>
        Add new{' '}
        {childLocationType === 'row'
          ? 'rack'
          : childLocationType === 'bay'
          ? 'location'
          : childLocationType}{' '}
        details
      </DialogTitle>
      <DialogContent>
        {/* <DialogContentText>Some more text if needed</DialogContentText> */}
        {fields &&
          fields.map((fieldName) => (
            <TextField
              fullWidth
              key={fieldName}
              margin="dense"
              label={toTitleCase(fieldName)}
              type="text"
              name={fieldName}
              variant="standard"
              value={formik.values[fieldName]}
              error={formik.touched[fieldName] && Boolean(formik.errors[fieldName])}
              helperText={formik.touched[fieldName] && formik.errors[fieldName]}
              onChange={formik.handleChange}
            />
          ))}
        {childLocationType === 'sublevel' ? (
          <>
            Type:{' '}
            <Select
              label="Type"
              name="type"
              value={formik.values.type}
              onChange={formik.handleChange}
            >
              <MenuItem value="POSITION">Position</MenuItem>
              <MenuItem value="BIN">Bin</MenuItem>
              <MenuItem value="PALLET">Pallet</MenuItem>
            </Select>
            {/* Positions:{' '}
            <Select
              multiple
              name="postitions"
              value={formik.values.positions}
              input={<OutlinedInput id="select-multiple-chip" label="Positions" />}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 48 * 4.5 + 8,
                    width: 250
                  }
                }
              }}
              onChange={(event) => {
                const {
                  target: { value }
                } = event;
                formik.setFieldValue(
                  'positions',
                  // On autofill we get a stringified value.
                  typeof value === 'string' ? value.split(',') : value
                );
              }}
            >
              {['LDB', 'LDF', 'LUB', 'LUF', 'RDB', 'RDF', 'RUB', 'RUF'].map((position) => (
                <MenuItem
                  key={position}
                  value={position}
                  // style={{
                  //   fontWeight: theme.typography.fontWeightMedium
                  // }}
                >
                  {position}
                </MenuItem>
              ))}
            </Select> */}
          </>
        ) : null}
      </DialogContent>
      <DialogActions>
        <MDButton
          onClick={() => {
            setAddFormOpen(false);
          }}
        >
          Cancel
        </MDButton>
        <MDButton onClick={formik.handleSubmit}>Save</MDButton>
      </DialogActions>
    </Dialog>
  );
};

AddForm.propTypes = {
  addFormOpen: PropTypes.any,
  setAddFormOpen: PropTypes.any,
  selected: PropTypes.any,
  warehouseId: PropTypes.any
};

const WarehouseNestedDetails = ({ disabled }) => {
  const [selected, setSelected] = React.useState(null);
  const [addFormOpen, setAddFormOpen] = React.useState(false);
  const dispatch = useDispatch();

  const { warehouseId } = useParams();
  LOGGER.log('warehouseID', warehouseId);
  const data = useSelector(WarehouseLocationsSelectors.getChildrenOfParent(warehouseId));

  const populateChildren = (id, type) => {
    LOGGER.log('populating:', id, type);
    dispatch(
      WarehouseLocationsActions.locationRequest({
        loader: 'location-request',
        slug: API.GET_CHILDREN_FROM_PARENT,
        method: 'post',
        data: { id, type }
      })
    );
  };

  React.useEffect(() => {
    populateChildren(warehouseId, 'warehouse');
  }, []);

  return (
    <>
      <Box px={3} py={3}>
        {data &&
          data.map((data) => (
            <NestedDataTable
              key={data.id}
              data={data}
              disabled={disabled}
              selected={selected}
              setSelected={setSelected}
              populateChildren={populateChildren}
            />
          ))}
        {/* Debugging */}
        {/* <pre>{JSON.stringify(selected, null, 4)}</pre> */}
        {/* Bottom buttons */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            columnGap: '20px',
            margin: '20px'
          }}
        >
          <MDButton
            size="medium"
            sx={bottomButtonStyling}
            color="primary"
            variant="contained"
            disabled={disabled}
            onClick={() => {
              setAddFormOpen('zone');
            }}
          >
            Add zone
          </MDButton>
          <MDButton
            size="medium"
            sx={bottomButtonStyling}
            disabled={disabled || selected?.location !== 'zone'}
            color={selected?.location === 'zone' ? 'primary' : 'secondary'}
            variant="contained"
            onClick={() => {
              setAddFormOpen(true);
            }}
          >
            Add area
          </MDButton>
          <MDButton
            size="medium"
            sx={bottomButtonStyling}
            disabled={disabled || selected?.location !== 'area'}
            color={selected?.location === 'area' ? 'primary' : 'secondary'}
            variant="contained"
            onClick={() => {
              setAddFormOpen(true);
            }}
          >
            Add rack
          </MDButton>
          <MDButton
            size="medium"
            sx={bottomButtonStyling}
            disabled={disabled || selected?.location !== 'row'}
            color={selected?.location === 'row' ? 'primary' : 'secondary'}
            variant="contained"
            onClick={() => {
              setAddFormOpen(true);
            }}
          >
            Add location
          </MDButton>
          <MDButton
            size="medium"
            sx={bottomButtonStyling}
            disabled={disabled || selected?.location !== 'bay'}
            color={selected?.location === 'bay' ? 'primary' : 'secondary'}
            variant="contained"
            onClick={() => {
              setAddFormOpen(true);
            }}
          >
            Add Level
          </MDButton>
          <MDButton
            size="medium"
            sx={bottomButtonStyling}
            disabled={disabled || !(selected?.location === 'level')}
            color={selected?.location === 'level' ? 'primary' : 'secondary'}
            variant="contained"
            onClick={() => {
              setAddFormOpen(true);
            }}
          >
            Add Sublevel
          </MDButton>
        </Box>
      </Box>
      {addFormOpen && (
        <AddForm
          addFormOpen={addFormOpen}
          setAddFormOpen={setAddFormOpen}
          selected={selected}
          warehouseId={warehouseId}
        />
      )}
    </>
  );
};

WarehouseNestedDetails.propTypes = {
  disabled: PropTypes.bool
};

function EditWarehouseDetails() {
  const classes = useStyles();

  const canModifyWarehouse = useSelector(AuthSelectors.hasPermission('Modify::Warehouse'));

  const { warehouseId } = useParams();
  const navigate = useNavigate();
  const navigateTo = (to) => {
    navigate(to);
  };
  const warehouseData = useSelector(WarehouseSelectors.getWarehouseDetailById(warehouseId));

  const inventoryTypes = useSelector(InventorySelectors.getInventoryDetail);

  React.useEffect(() => {
    dispatch(
      InventoryActions.getInventoryAction({
        loader: 'loading-request',
        slug: API.GET_INVENTORY,
        method: 'get'
      })
    );
  }, []);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250
      }
    }
  };

  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      warehousename: warehouseData.name,
      address: warehouseData.address || '',
      preferredInventories: [...warehouseData.preferredInventories] || [],
      specs: warehouseData.specs || '',
      image: warehouseData.image_url ? [{ src: warehouseData.image_url }] : []
    },
    validationSchema: schema.warehouseForm,
    onSubmit: (values) => {
      canModifyWarehouse &&
        dispatch(
          WarehouseActions.editWarehouseAction({
            loader: 'loading-request',
            slug: `/warehouse/${warehouseData._id}`,
            method: 'patch',
            data: {
              name: values.warehousename,
              address: values.address,
              specs: values.specs,
              preferredInventories: values.preferredInventories,
              image: values.image
            }
          })
        );
    }
  });

  const [deleteAlertOpen, setDeleteAlertOpen] = React.useState(null);
  const handleDeleteAlertClose = () => {
    setDeleteAlertOpen(false);
  };
  const handleDeleteAlertOpen = () => {
    setDeleteAlertOpen(true);
  };

  const preDeletePendingAssociations = useSelector(WarehouseSelectors.getPreDeleteAssociations);
  const clearPreDeleteAssociations = () => {
    dispatch(WarehouseActions.clearWarehouseAssociationAction());
  };

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <Breadcrumbs
          title="Edit Warehouse Details"
          route={[
            { name: 'Home', path: '/home' },
            { name: 'Setup', path: '/setup' },
            { name: 'Warehouse', path: '/setup/warehouse' },
            { name: warehouseData.name || '' }
          ]}
        />
        {preDeletePendingAssociations ? (
          <PendingAssociationsModal
            data={preDeletePendingAssociations}
            type="warehouse"
            dismissAction={clearPreDeleteAssociations}
          />
        ) : null}
        <Box mx={3} my={3}>
          <form onSubmit={formik.handleSubmit}>
            <Box sx={{ backgroundColor: '#fff', padding: '30px' }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6}>
                  <Box component="div" sx={{ marginBottom: '15px' }}>
                    <Box
                      component="div"
                      sx={{
                        fontSize: '16px',
                        letterSpacing: '0.01em',
                        color: '#000',
                        marginBottom: '4px'
                      }}
                    >
                      Warehouse name
                    </Box>
                    <MDInput
                      fullWidth
                      name="warehousename"
                      type="text"
                      variant="outlined"
                      disabled={!canModifyWarehouse}
                      value={formik.values.warehousename}
                      error={formik.touched.warehousename && Boolean(formik.errors.warehousename)}
                      helperText={
                        formik.touched.warehousename &&
                        formik.errors.warehousename && (
                          <div style={{ color: 'red' }}>{formik.errors.warehousename}</div>
                        )
                      }
                      onChange={formik.handleChange}
                    />
                  </Box>
                  <Box component="div" sx={{ marginBottom: '15px' }}>
                    <Box
                      component="div"
                      sx={{
                        fontSize: '16px',
                        letterSpacing: '0.01em',
                        color: '#000',
                        marginBottom: '4px'
                      }}
                    >
                      Address
                    </Box>
                    <MDInput
                      fullWidth
                      name="address"
                      type="text"
                      variant="outlined"
                      disabled={!canModifyWarehouse}
                      value={formik.values.address}
                      error={formik.touched.address && Boolean(formik.errors.address)}
                      helperText={
                        formik.touched.address &&
                        formik.errors.address && (
                          <div style={{ color: 'red' }}>{formik.errors.address}</div>
                        )
                      }
                      onChange={formik.handleChange}
                    />
                  </Box>
                  <Box component="div" sx={{ marginBottom: '15px' }}>
                    <Box
                      component="div"
                      sx={{
                        fontSize: '16px',
                        letterSpacing: '0.01em',
                        color: '#000',
                        marginBottom: '4px'
                      }}
                    >
                      Inventories hosted
                    </Box>
                    <Select
                      multiple
                      select
                      fullWidth
                      variant="outlined"
                      name="preferredInventories"
                      disabled={!canModifyWarehouse}
                      input={<OutlinedInput />}
                      value={formik.values.preferredInventories}
                      error={
                        formik.touched.preferredInventories &&
                        Boolean(formik.errors.preferredInventories)
                      }
                      helperText={
                        formik.touched.preferredInventories && formik.errors.preferredInventories
                      }
                      renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          {selected.map((value) => (
                            <Chip
                              key={value}
                              label={inventoryTypes.find((x) => x._id === value)?.name || 'unknown'}
                            />
                          ))}
                        </Box>
                      )}
                      MenuProps={MenuProps}
                      onChange={(e) => {
                        const value = e.target.value;
                        formik.setFieldValue(
                          'preferredInventories',
                          // On autofill we get a stringified value.
                          typeof value === 'string' ? value.split(',') : value
                        );
                      }}
                    >
                      <MenuItem disabled key={'none'} value={''}>
                        None Selected
                      </MenuItem>
                      {inventoryTypes &&
                        inventoryTypes.map((inventory) => (
                          <MenuItem key={inventory._id} value={inventory._id}>
                            {inventory.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </Box>
                  <Box component="div" sx={{ marginBottom: '15px' }}>
                    <Box
                      component="div"
                      sx={{
                        fontSize: '16px',
                        letterSpacing: '0.01em',
                        color: '#000',
                        marginBottom: '4px'
                      }}
                    >
                      Other attributes
                    </Box>
                    <MDInput
                      fullWidth
                      type="text"
                      variant="outlined"
                      name="specs"
                      disabled={!canModifyWarehouse}
                      value={formik.values.specs}
                      error={formik.touched.specs && Boolean(formik.errors.specs)}
                      helperText={formik.touched.specs && formik.errors.specs}
                      onChange={formik.handleChange}
                    />
                  </Box>
                </Grid>
                <Grid item sx={{ textAlign: 'end' }} xs={12} sm={6} md={6}>
                  <MDButton
                    size="large"
                    color="error"
                    variant="outlined"
                    disabled={!canModifyWarehouse}
                    onClick={handleDeleteAlertOpen}
                  >
                    Delete Warehouse
                  </MDButton>
                  <Dialog
                    open={deleteAlertOpen}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    onClose={handleDeleteAlertClose}
                  >
                    <DialogTitle id="alert-dialog-title">Confirm Warehouse Delete</DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this warehouse?
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button autoFocus onClick={handleDeleteAlertClose}>
                        No
                      </Button>
                      <Button
                        onClick={() => {
                          canModifyWarehouse &&
                            dispatch(
                              WarehouseActions.deleteWarehouseAction({
                                loader: 'loading-request',
                                slug: '/warehouse/',
                                method: 'delete',
                                warehouseId: warehouseData._id,
                                navigateTo
                              })
                            );
                          handleDeleteAlertClose();
                        }}
                      >
                        Yes
                      </Button>
                    </DialogActions>
                  </Dialog>
                  <Box sx={{ marginTop: '30px' }} className={classes.fitImage}>
                    <ImageUploadSingle
                      heading="Upload Warehouse Image"
                      accept="image/*"
                      disabled={!canModifyWarehouse}
                      images={formik.values.image}
                      setImages={(images) => {
                        formik.setFieldValue('image', images);
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Box
                sx={{
                  marginTop: '60px',
                  display: 'flex',
                  justifyContent: 'center',
                  columnGap: '20px'
                }}
              >
                <MDButton
                  size="medium"
                  color="error"
                  variant="outlined"
                  onClick={() => {
                    navigate('/setup/warehouse');
                  }}
                >
                  CANCEL
                </MDButton>
                {/* ---edit-- */}
                <MDButton
                  size="large"
                  color="primary"
                  variant="outlined"
                  type="submit"
                  disabled={!canModifyWarehouse}
                >
                  SAVE
                </MDButton>
                {/* <MDButton
                  size="large"
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    navigate(`/setup/warehouse/warehouse-details/${location.state.id}`);
                  }}
                >
                  SHOW DETAILS
                </MDButton> */}
              </Box>
            </Box>
          </form>
          <WarehouseNestedDetails disabled={!canModifyWarehouse} />
        </Box>
      </DashboardLayout>
    </>
  );
}
export default EditWarehouseDetails;
