import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { API } from 'constant';
import ScanActions from 'redux/ScanRedux';
import { ScanDataSelectors } from 'redux/ScanRedux';
import DashboardLayout from 'layouts/DashboardLayout';
import DashboardNavbar from 'components/DashboardNavbar';
import Breadcrumbs from 'components/Breadcrumbs';
import MDTypography from 'components/MDTypography';
import CodeScanner from 'components/CodeScanner';

const useStyles = makeStyles(() => ({
  scanContainer: {
    background: '#dddddd',
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: 'calc(100vh - 150px)',
    textAlign: 'center'
  },
  barCodeWrapper: {
    position: 'relative',
    width: '80%',
    height: '240px',
    margin: '30px auto 30px auto'
  },
  containerInner: {
    maxWidth: '60%',
    margin: '0 auto'
  },
  boxLeft: {
    width: '25px',
    height: '25px',
    borderTop: '5px solid #ff3b30',
    borderLeft: '5px solid #ff3b30',
    position: 'absolute',
    top: '0',
    left: '0'
  },
  boxRight: {
    width: '25px',
    height: '25px',
    borderTop: '5px solid #ff3b30',
    borderRight: '5px solid #ff3b30',
    position: 'absolute',
    top: '0',
    right: '0'
  },
  boxBottomLeft: {
    width: '25px',
    height: '25px',
    borderLeft: '5px solid #ff3b30',
    borderBottom: '5px solid #ff3b30',
    position: 'absolute',
    bottom: '0',
    left: '0'
  },
  boxBottomRight: {
    width: '25px',
    height: '25px',
    borderBottom: '5px solid #ff3b30',
    borderRight: '5px solid #ff3b30',
    position: 'absolute',
    right: '0',
    bottom: '0'
  },
  redShadow: {
    background: 'linear-gradient(to bottom, #ff3b30 -65%, rgba(196, 196, 196, 0))',
    height: '100%',
    opacity: '0.5'
  }
}));
function ScanHome() {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [qrCode, setQRCode] = React.useState(null);
  const scanData = useSelector(ScanDataSelectors.getScanDetail);

  React.useEffect(() => {
    if (qrCode) {
      dispatch(
        ScanActions.getScanAction({
          loader: 'loading-request',
          slug: `${API.GET_ITEMS_BY_SCAN}type=${qrCode.type}&id=${qrCode.id}`,
          method: 'get'
        })
      );
    }
  }, [qrCode]);

  React.useEffect(() => {
    if (qrCode) {
      if (qrCode.type === 'Widget') {
        const widgetType = scanData.item.widgetFamily.inventory.policies.inventory_process;
        if (widgetType === 'CCR') {
          navigate('/scan/ccr', { state: { scanData, id: qrCode.id } });
        } else {
          navigate('/scan/ppr', { state: { scanData, id: qrCode.id } });
        }
      } else {
        navigate('/scan/location-scan', { state: { scanData, id: qrCode.id } });
      }
    }
  }, [scanData]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Breadcrumbs route={[{ name: 'Home', path: '/home' }, { name: 'Scan' }]} />
      <Box className={classes.scanContainer}>
        <Box className={classes.containerInner}>
          <MDTypography variant="h4" sx={{ lineHeight: '12px' }}>
            Scan the barcode
          </MDTypography>
          <MDTypography variant="body" sx={{ fontSize: '16px' }}>
            Line up the barcode inside the red corners and lorem ipsum
          </MDTypography>{' '}
          <Box className={classes.barCodeWrapper}>
            {!qrCode && (
              <CodeScanner
                width="100%"
                height="100%"
                onDetect={(value) => {
                  setQRCode(JSON.parse(value));
                }}
              />
            )}
            <Box className={classes.boxLeft}></Box>
            <Box className={classes.boxRight}></Box>
            <Box className={classes.boxBottomLeft}></Box>
            <Box className={classes.boxBottomRight}></Box>

            <Box
              sx={{
                position: 'absolute',
                top: '40px',
                borderTop: '2px solid #ff3b30',
                left: '0',
                width: '100%'
              }}
              className={classes.redShadow}
            ></Box>
          </Box>
          <MDTypography variant="body" sx={{ fontSize: '14px', marginTop: '20px' }}>
            Scanning
          </MDTypography>
        </Box>
      </Box>
    </DashboardLayout>
  );
}
export default ScanHome;
