/* eslint-disable indent */
import React, { useEffect, useState } from 'react';
import BasicTable from 'components/BasicTable';
import PropTypes from 'prop-types';
import { Grid, TableBody, TableRow, TableCell, Select, MenuItem, Box, Button } from '@mui/material';
import MDButton from 'components/Button';
import MDTypography from 'components/MDTypography';
import { useDispatch, useSelector } from 'react-redux';
import { InventorySelectors } from 'redux/InventoryRedux';
import { ItemSelectors } from 'redux/ItemRedux';
import InventoryActions from 'redux/InventoryRedux';
import { API } from 'constant';
import ItemActions from 'redux/ItemRedux';
import { WidgetSelectors } from 'redux/WidgetRedux';
import WidgetActions from 'redux/WidgetRedux';

function SearchInventoryMain({ onItemTransaction }) {
  const [page] = React.useState(1);
  const [perPage] = React.useState(10000);
  const [inventory, setInventory] = React.useState('');
  const [family, setFamily] = React.useState('');
  const [subFamily, setSubFamily] = React.useState('');
  const [inventoryAllData, setInventoryAllData] = useState([]);
  const [tableFilter, setTableFilter] = useState([]);
  const [tableItem, setTableItem] = useState('');

  const dispatch = useDispatch();
  const inventoryData = useSelector(InventorySelectors.getInventoryDetail);
  const primaryFamilies = useSelector(WidgetSelectors.getWidgetFamiliesByInventoryId(inventory));
  const secondaryFamilies = useSelector(WidgetSelectors.getWidgetsByParentId(family));
  const allFilteredItems = useSelector(ItemSelectors.getItems);
  const transactionItem = useSelector(ItemSelectors.getPprData);

  useEffect(() => {
    dispatch(ItemActions.clearItem({ loader: 'loading-request', list: [], count: 0 }));
    dispatch(ItemActions.clearPprItem({ loader: 'loading-request', pprData: [] }));
    setInventory('');
    setFamily('');
    setSubFamily('');
    setTableFilter([]);
    setTableItem('');
    dispatch(
      InventoryActions.getInventoryAction({
        loader: 'loading-request',
        slug: API.GET_INVENTORY,
        method: 'get'
      })
    );
  }, []);

  useEffect(() => {
    if (inventoryData?.length) {
      setInventoryAllData(inventoryData);
    }
    if (Object.keys(transactionItem).length) {
      onItemTransaction(transactionItem);
    }

    if (allFilteredItems?.length && allFilteredItems) {
      setTableFilter(allFilteredItems);
    }
  }, [inventoryData, allFilteredItems, transactionItem]);

  useEffect(() => {
    if (inventory) {
      dispatch(
        WidgetActions.widgetRequest({
          loader: 'location-request',
          slug: `${API.GET_WIDGET_FAMILY_BY_INVENTORY}${inventory}`,
          method: 'get'
        })
      );
    }
  }, [inventory]);

  const filterHandler = () => {
    dispatch(
      ItemActions.itemRequest({
        loader: 'loading-request',
        slug: API.GET_ITEMS_BY_INVENTORY,
        method: 'get',
        page: page - 1,
        perPage,
        inventoryId: inventory,
        family: subFamily || family || null
      })
    );
  };

  const headCells = [
    {
      id: 'commonName',
      label: 'NAME'
    },
    {
      id: 'manufacturer',
      label: 'MANUFACTURER'
    },
    {
      id: 'size',
      label: 'SIZE'
    },
    {
      id: 'totalQuantity',
      label: 'AVAILABILITY'
    },
    {
      id: 'label',
      label: 'LOCATION'
    }
  ];

  const getTableItem = (item) => {
    const id = item._id;
    setTableItem(id);
    tableItemData(id);
  };

  const tableItemData = (id) => {
    dispatch(
      ItemActions.itemPprRequest({
        loader: 'location-request',
        slug: `${API.GET_ITEM_FOR_TRANSACTION_PPR.replace(':id', id)}`,
        method: 'get'
      })
    );
  };

  return (
    <>
      <Box sx={{ display: 'flex', marginBottom: '20px' }}>
        <MDTypography>Filters</MDTypography>
        <Button
          sx={{ marginLeft: '20px' }}
          onClick={() => {
            setInventory('');
            setFamily('');
            setSubFamily('');
            setTableFilter('');
            setTableItem('');
          }}
        >
          Clear Filters
        </Button>
      </Box>
      <Grid container spacing={4}>
        <Grid item xs={6} md={3}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Select
              displayEmpty
              value={inventory}
              style={{ marginBottom: '20px' }}
              onChange={(e) => {
                setInventory(e.target.value);
                setFamily('');
                setSubFamily('');
                setTableFilter('');
                setTableItem('');
              }}
            >
              <MenuItem disabled key={'none'} value={''}>
                Select Inventory
              </MenuItem>
              {inventoryAllData &&
                inventoryAllData.map((item) => (
                  <MenuItem key={item._id} value={item._id}>
                    {item.name}
                  </MenuItem>
                ))}
            </Select>
            <Select
              displayEmpty
              value={family}
              style={{ marginBottom: '20px' }}
              onChange={(e) => {
                setFamily(e.target.value);
                setSubFamily('');
              }}
            >
              <MenuItem disabled key={'none'} value={''}>
                Select Family
              </MenuItem>
              {primaryFamilies &&
                primaryFamilies.map((item) => (
                  <MenuItem key={item._id} value={item._id}>
                    {item.name}
                  </MenuItem>
                ))}
            </Select>
            <Select
              displayEmpty
              id="demo-simple-select"
              value={subFamily}
              onChange={(e) => {
                setSubFamily(e.target.value);
              }}
            >
              <MenuItem disabled key={'none'} value={''}>
                Select Sub Family
              </MenuItem>
              {secondaryFamilies &&
                secondaryFamilies.map((item) => (
                  <MenuItem key={item._id} value={item._id}>
                    {item.name}
                  </MenuItem>
                ))}
            </Select>
            <MDButton
              color="primary"
              sx={{ minWidth: '100%', marginTop: '50px', padding: '13px 40px' }}
              onClick={() => filterHandler()}
            >
              {'APPLY'}
            </MDButton>
          </Box>
        </Grid>
        <Grid item xs={6} md={9}>
          <div
            style={{
              marginTop: '-87px',
              marginBottom: '20px',
              marginLeft: '20px',
              height: '293px',
              overflowY: 'scroll',
              overflowX: 'hidden'
            }}
          >
            <BasicTable
              headCells={headCells}
              records={tableFilter}
              backgroundColor="#E5E5E5"
              color="#8D8D8D"
            >
              <TableBody>
                {tableFilter.length
                  ? tableFilter.map((item) => (
                      <TableRow
                        key={item._id}
                        sx={tableItem === item._id ? { border: '2px solid #007AFF' } : {}}
                        onClick={() => getTableItem(item)}
                      >
                        <TableCell>{item?.commonName}</TableCell>
                        <TableCell>{item?.manufacturer}</TableCell>
                        <TableCell>{item?.size}</TableCell>
                        <TableCell>{item?.totalQuantity}</TableCell>
                        <TableCell>{item?.lastReportedLocation}</TableCell>
                      </TableRow>
                    ))
                  : 'No Data'}
              </TableBody>
            </BasicTable>
          </div>
        </Grid>
      </Grid>
    </>
  );
}

SearchInventoryMain.propTypes = {
  onItemTransaction: PropTypes.func.isRequired
};

export default SearchInventoryMain;
