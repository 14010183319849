/* eslint-disable complexity */
import React from 'react';
import { Box, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import MDButton from 'components/Button';
import MDInput from 'components/MDInput';
import { makeStyles } from '@mui/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import CrossIcon from 'assets/images/CrossIcon';
import PickupScanGrid from 'components/PickUpScan';
import { useFormik } from 'formik';
import schema from 'services/ValidationServices';
import { useDispatch } from 'react-redux';
import PprActions from 'redux/PprRedux';
import { API } from 'constant';

const useStyles = makeStyles(() => ({
  inputLabel: {
    fontSize: '16px',
    letterSpacing: '0.01em',
    color: '#000',
    marginBottom: '4px',
    fontWeight: 'normal',
    '& .MuiFormControlLabel-label': {
      fontWeight: 'normal',
      fontSize: '16px',
      color: '#000'
    }
  },
  centreContent: {
    justifyContent: 'center'
  },
  fullWidth: {
    width: '100%',
    borderColor: '#d2d6da',
    borderRadius: '0.375rem'
  },
  pickupBtn: {
    '&:hover': {
      backgroundColor: '#ff9330',
      color: '#fff'
    }
  },
  cursorPointer: {
    cursor: 'pointer',
    display: 'flex',
    alignSelf: 'end',
    margin: '0'
  }
}));

function ScanCheckin({ show, setShow, inventoryItem }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [showIssueDescription, setShowIssueDescription] = React.useState(false);
  const [locationId, setLocationId] = React.useState(null);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      checkInMeter: '',
      issueDetail: '',
      hasIssue: false
    },
    validationSchema: schema.checkin,
    onSubmit: (values) => {
      dispatch(
        PprActions.pickAction({
          loader: 'loading-request',
          slug: `${API.CHECK_IN.replace(':id', inventoryItem.inventoryId)}`,
          method: 'post',
          data: {
            subLevel: locationId,
            checkInMeterReading: values.checkInMeter,
            hasIssue: values.hasIssue,
            issueDescription: values.issueDetail
          },
          callback: () => {
            formik.resetForm();
            setShow();
          }
        })
      );
    }
  });

  return (
    <div>
      <Dialog fullWidth open={show} maxWidth="sm" onClose={setShow}>
        <MDButton
          className={classes.cursorPointer}
          sx={{ padding: '15px', minWidth: '14px' }}
          onClick={setShow}
        >
          <CrossIcon />
        </MDButton>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent
            sx={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}
          >
            <Grid container sx={{ marginLeft: 'auto' }}>
              <Grid item container md={5}>
                <PickupScanGrid
                  needLocationCheck
                  images={[]}
                  inventoryArr={[]}
                  onLocationFound={(data) => {
                    setLocationId(data.id);
                  }}
                />
              </Grid>
              <Grid item container md={7}>
                <Grid item xs={12}>
                  <Box component="div" sx={{}} className={classes.inputLabel}>
                    Check-In Meter Reading
                  </Box>
                  <MDInput
                    fullWidth
                    name="checkInMeter"
                    type="number"
                    variant="outlined"
                    value={formik.values.checkInMeter}
                    error={formik.touched.checkInMeter && Boolean(formik.errors.checkInMeter)}
                    helperText={
                      formik.touched.checkInMeter &&
                      formik.errors.checkInMeter && (
                        <div style={{ color: 'red' }}>{formik.errors.checkInMeter}</div>
                      )
                    }
                    onChange={formik.handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl sx={{ paddingTop: '16px' }}>
                    <FormLabel id="demo-radio-buttons-group-label" className={classes.inputLabel}>
                      Any Issue to Report?
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue=""
                      name="hasIssue"
                      onChange={(e) => {
                        const hasIssue = e.target.value === 'yes';
                        formik.setFieldValue('hasIssue', hasIssue, true);
                        setShowIssueDescription(hasIssue);
                      }}
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio />}
                        label="Yes"
                        className={classes.inputLabel}
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio />}
                        label="No"
                        className={classes.inputLabel}
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                {showIssueDescription && (
                  <Grid item xs={12}>
                    <Box component="div" sx={{}} className={classes.inputLabel}>
                      Issue Details
                    </Box>
                    <MDInput
                      fullWidth
                      name="issueDetail"
                      type="text"
                      variant="outlined"
                      as="textarea"
                      value={formik.values.issueDetail}
                      error={formik.touched.issueDetail && Boolean(formik.errors.issueDetail)}
                      helperText={
                        formik.touched.issueDetail &&
                        formik.errors.issueDetail && (
                          <div style={{ color: 'red' }}>{formik.errors.issueDetail}</div>
                        )
                      }
                      className={classes.fullWidth}
                      minRows="7"
                      onChange={formik.handleChange}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className={classes.centreContent}>
            <MDButton size="medium" color="primary" type="submit">
              CHECK IN
            </MDButton>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
ScanCheckin.propTypes = {
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
  inventoryItem: PropTypes.shape({
    inventoryId: PropTypes.string.isRequired
  }).isRequired
};

export default ScanCheckin;
