import EquipmentIcon from 'assets/images/EquimpmentIcon';
import FleetIcon from 'assets/images/FleetIcon';
import UserIcon from 'assets/images/UserIcon';
import TruckIcon from 'assets/images/TruckIcon';
import TvIcon from 'assets/images/TvIcon';
import TargetIcon from 'assets/images/TargetIcon';
import ServerIcon from 'assets/images/ServerIcon';
import GiftIcon from 'assets/images/GiftIcon';
import DatabaseIcon from 'assets/images/DatabaseIcon';
import PackageIcon from 'assets/images/PackageIcon';
import CodepenIcon from 'assets/images/CodepenIcon';
import BriefcaseIcon from 'assets/images/BriefcaseIcon';
import RawMaterialIcon from 'assets/images/RawMaterialIcon';
import RoleIcon1 from 'assets/images/roleIcon1.svg';
import RoleIcon4 from 'assets/images/roleIcon4.svg';
import RoleIcon5 from 'assets/images/roleIcon5.svg';
import RoleIcon6 from 'assets/images/roleIcon6.svg';
import RoleIcon7 from 'assets/images/roleIcon7.svg';

export const GetIconFromSlug = (slug) => {
  switch (slug) {
    case 'equipment':
      return <EquipmentIcon />;
    case 'fleet':
      return <FleetIcon />;
    case 'user':
      return <UserIcon />;
    case 'truck':
      return <TruckIcon />;
    case 'tv':
      return <TvIcon />;
    case 'target':
      return <TargetIcon />;
    case 'server':
      return <ServerIcon />;
    case 'package':
      return <PackageIcon />;
    case 'gift':
      return <GiftIcon />;
    case 'database':
      return <DatabaseIcon />;
    case 'codepen':
      return <CodepenIcon />;
    case 'briefcase':
      return <BriefcaseIcon />;
    
    case 'rawmaterial':
      return <RawMaterialIcon />;
    case 'role-1':
      return <img src={RoleIcon1} alt="roleIcon1" />;
    case 'role-2':
    case 'role-4':
      return <img src={RoleIcon4} alt="roleIcon4" />;
    case 'role-5':
      return <img src={RoleIcon5} alt="roleIcon5" />;
    case 'role-6':
      return <img src={RoleIcon6} alt="roleIcon6" />;
    case 'role-7':
      return <img src={RoleIcon7} alt="roleIcon7" />;
    default:
      return <RawMaterialIcon />;
  }
};

export const roleIconSlugs = ['role-1', 'role-4', 'role-5', 'role-6', 'role-7'];
export const iconSlugs = [
  'equipment',
  'fleet',
  'rawmaterial',
  'user',
  'truck',
  'tv',
  'target',
  'server',
  'package',
  'gift',
  'database',
  'codepen',
  'briefcase'
];
