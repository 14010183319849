import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { API } from 'constant';
import { Box, Grid, TableBody, TableCell, TableRow } from '@mui/material';
import DashboardNavbar from 'components/DashboardNavbar';
import DashboardLayout from 'layouts/DashboardLayout';
import Breadcrumbs from 'components/Breadcrumbs';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import BasicTable from 'components/BasicTable';
import ReportActions from 'redux/ReportsRedux';
import { ReportSelectors } from 'redux/ReportsRedux';
import { exportToExcel } from 'utils/exportExcel';

const tHeads = [
  { id: 'name', label: 'Name' },
  { id: 'family', label: 'Family' },
  { id: 'reservedQuantity', label: 'Reserved Quantity' },
  { id: 'forJob', label: 'For Job #' },
  { id: 'pickupDate', label: 'Pickup Date' },
  { id: 'reservedBy', label: 'Reserved By' }
];

const allHeads = [
  { id: 'name', label: 'Name' },
  { id: 'family', label: 'Family' },
  { id: 'subFamily', label: 'Sub Family' },
  { id: 'typeSizeColor', label: 'Type - Size - Color' },
  { id: 'warehouse', label: 'Warehouse' },
  { id: 'totalQuantity', label: 'Total Quantity' },
  { id: 'reservedQuantity', label: 'Reserved Quantity' },
  { id: 'forJob', label: 'For Job #' },
  { id: 'pickupDate', label: 'Expected Pick Date' },
  { id: 'reservationDate', label: 'Reservation Date' },
  { id: 'reservedBy', label: 'Reserved By' },
  { id: 'daysUntilPickup', label: '# of days until Pickup' }
];

const ReservationTable = ({ md, heading, headData, bodyData }) => {
  return (
    <Grid item md={md}>
      <Grid
        item
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <MDTypography md={9} variant="h4" my={2}>
          {heading}
        </MDTypography>
        <MDTypography
          fullWidth
          md={3}
          color="primary"
          sx={{ cursor: 'pointer' }}
          onClick={() => {
            exportToExcel(headData, bodyData, 'Inventory_Reservation');
          }}
        >
          Export to Excel
        </MDTypography>
      </Grid>
      <Box sx={{ overflowX: 'auto', overflowY: 'auto', height: '40vh' }}>
        <BasicTable
          headCells={headData}
          records={bodyData}
          backgroundColor="#E5E5E5"
          color="#8D8D8D"
        >
          <TableBody>
            {bodyData.map((item, i) => (
              <TableRow key={++i}>
                {Object.keys(item).map((key) => (
                  <TableCell key={`${key}-${++i}`}>{item[key]}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </BasicTable>
      </Box>
    </Grid>
  );
};

const InventoryReservationReport = () => {
  const dispatch = useDispatch();
  const data = useSelector(ReportSelectors.getReportDetail);

  React.useEffect(() => {
    dispatch(
      ReportActions.getReportAction({
        loader: 'loading-request',
        slug: API.GET_ITEM,
        method: 'get',
        reportType: 'reservation'
      })
    );
  }, []);

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <Breadcrumbs
          title="Warehouse Management System Reports"
          route={[
            { name: 'Home', path: '/home' },
            { name: 'Reports', path: '/reports' },
            { name: 'Inventory Reservation Report' }
          ]}
        />
        <MDBox px={5} py={3}>
          <Grid container spacing={5}>
            <ReservationTable
              md={6}
              heading={'Reservations approaching pickup in 10 days'}
              headData={tHeads}
              bodyData={getBodyData(data, approaching10Days)}
            />
            <ReservationTable
              md={6}
              heading={'Reservations with overdue pickup date'}
              headData={tHeads}
              bodyData={getBodyData(data, overDueDate)}
            />
          </Grid>
          <div style={{ marginTop: '50px' }}></div>
          <ReservationTable
            md={12}
            py={10}
            heading={'All reservations'}
            headData={allHeads}
            bodyData={getAllData(data)}
            sx={{ width: '100%', overflowX: 'auto', overflowY: 'auto' }}
          />
        </MDBox>
      </DashboardLayout>
    </>
  );
};

const overDueDate = (timeStamp) => {
  const checkDate = new Date(timeStamp).getTime();
  const endDate = new Date().getTime();
  return checkDate < endDate;
};

const approaching10Days = (timeStamp) => {
  const checkDate = new Date(timeStamp).getTime();
  const now = new Date().getTime();
  const endDate = now + 24 * 60 * 60 * 1000 * 10;
  return checkDate >= now && checkDate < endDate;
};

const getBodyData = (data, filterDate) => {
  const bodyData = data
    .filter((item) => filterDate(item.pickupDate))
    .map((item) => {
      return {
        name: item?.performedOn?.commonName || '-',
        family:
          item?.performedOn?.widgetFamily?.parent?.name ||
          item?.performedOn?.widgetFamily?.name ||
          '-',
        reservedQuantity: item?.reserveQuantity || '-',
        forJob: item?.job || '-',
        pickupDate: item?.pickupDate ? new Date(item?.pickupDate).toLocaleDateString() : '-',
        reservedBy: item?.performedBy?.fullName || '-'
      };
    });
  return bodyData;
};

const getAllData = (data) => {
  const daysRemaining = (date) => {
    const checkDate = new Date(date).getTime();
    const now = new Date().getTime();
    const dif = checkDate > now ? new Date(date).getTime() - new Date().getTime() : 0;
    return Math.floor(dif / (1000 * 3600 * 24));
  };

  const bodyData = data.map((item) => {
    const attributeText = `${item?.performedOn?.type || 'NIL'} - ${
      item?.performedOn?.size || 'NIL'
    } - ${item?.performedOn?.color || 'NIL'}`;
    return {
      name: item?.performedOn?.commonName || '-',
      family:
        item?.performedOn?.widgetFamily?.parent?.name ||
        item?.performedOn?.widgetFamily?.name ||
        '-',
      subFamily: item?.performedOn?.widgetFamily?.parent?.name
        ? item?.performedOn?.widgetFamily?.name || '-'
        : '-',
      typeSizeColor: attributeText,
      warehouse: item?.warehouse_id?.name || '-',
      totalQuantity: item?.availableAtWarehouse || '-',
      reservedQuantity: item?.reserveQuantity || '-',
      forJob: item?.job || '-',
      pickupDate: item?.pickupDate ? new Date(item?.pickupDate).toLocaleDateString() : '-',
      reservationDate: item?.createdAt ? new Date(item?.createdAt).toLocaleDateString() : '-',
      reservedBy: item?.performedBy?.fullName || '-',
      daysUntilPickup: item?.pickupDate ? daysRemaining(item?.pickupDate) : '-'
    };
  });
  return bodyData;
};

ReservationTable.propTypes = {
  md: PropTypes.number.isRequired,
  heading: PropTypes.string.isRequired,
  headData: PropTypes.array.isRequired,
  bodyData: PropTypes.array.isRequired
};

export default InventoryReservationReport;
