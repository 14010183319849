import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid
} from '@mui/material';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import NotificationActions from 'redux/NotificationRedux';
// import { useNavigate } from 'react-router-dom';

function getColourFromLevel(level) {
  switch (level) {
    case 1:
      return '#F4C6C6';
    case 2:
      return '#F3D3AD';
    case 3:
      return '#D0D0D0';
    case 4:
      return '#FFFFFF';
    default:
      return '#FFFFFF';
  }
}

function NotificationItem({ data }) {
  const {
    _id,
    message,
    // event,
    createdAt,
    tag,
    dueDate,
    level,
    readStatus,
    // actionURL,
    dismissURL,
    readToggleURL
  } = data;

  const [dialogOpen, setDialogOpen] = useState(false);
  const dispatch = useDispatch();
  // const navigate = useNavigate();

  return (
    <>
      <Grid
        container
        direction="column"
        padding={1}
        borderRadius={5}
        sx={{ background: getColourFromLevel(level), maxHeight: 100 }}
        onClick={() => {
          setDialogOpen(true);
        }}
      >
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item xs={8}>
            <Box sx={{ fontWeight: !readStatus ? 'bold' : 'normal', fontSize: 12 }}>{message}</Box>
          </Grid>
          <Grid item xs="auto">
            <Box sx={{ fontSize: 12 }}>{moment(createdAt).fromNow()}</Box>
          </Grid>
        </Grid>
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item xs={6}>
            <Box sx={{ fontSize: 12 }}>{tag}</Box>
          </Grid>
          <Grid item xs={3}>
            <Box sx={{ fontSize: 12 }}>{dueDate ? `Due: ${dueDate}` : ''}</Box>
          </Grid>
        </Grid>
      </Grid>

      <Dialog
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false);
        }}
      >
        <Grid container alignItems="center" justifyContent="space-between" px={3} mt={3}>
          <Grid item sx={{ fontSize: 15 }}>
            {tag}
          </Grid>
          <Grid item sx={{ fontSize: 15 }}>
            {moment(createdAt).fromNow()}
          </Grid>
        </Grid>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
        <Grid item xs={4}>
          <Box sx={{ fontSize: 15, ml: 3 }}>{dueDate ? `Due: ${dueDate}` : ''}</Box>
        </Grid>
        <DialogActions>
          {/* {actionURL ? (
            <Button
              disabled
              sx={{ bgcolor: '#ADC4E5', textAlignColor: 'red', fontSize: 12 }}
              onClick={() => {
                navigate(actionURL.web || '/');
              }}
            >
              Take me there
            </Button>
          ) : null} */}
          <Button
            disabled={!dismissURL}
            sx={{ bgcolor: '#ADC4E5', fontSize: 12 }}
            onClick={() => {
              dispatch(
                NotificationActions.dismissNotificationAction({
                  loader: 'notification-dismiss',
                  slug: dismissURL,
                  method: 'post',
                  notificationId: _id
                })
              );
            }}
          >
            Dismiss
          </Button>
          <Button
            autoFocus
            disabled={!readToggleURL}
            sx={{
              backgroundColor: readStatus ? '#F4C6C6' : '#ADC4E5',
              fontSize: 12,
              textColor: 'red'
            }}
            onClick={() => {
              dispatch(
                NotificationActions.toggleReadNotificationAction({
                  loader: 'notification-readToggle',
                  slug: readToggleURL,
                  method: 'post',
                  notificationId: _id
                })
              );
            }}
          >
            {readStatus ? 'Mark as unread' : 'Mark as read'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

NotificationItem.propTypes = {
  data: PropTypes.shape({
    _id: PropTypes.string,
    message: PropTypes.string,
    event: PropTypes.string,
    createdAt: PropTypes.any,
    tag: PropTypes.any,
    dueDate: PropTypes.any,
    level: PropTypes.any,
    actionURL: PropTypes.any,
    dismissURL: PropTypes.any,
    readStatus: PropTypes.any,
    readToggleURL: PropTypes.any
  })
};

export default NotificationItem;
