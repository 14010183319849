import React from 'react';
import { Dialog } from '@mui/material';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { API } from 'constant';
import schema from 'services/ValidationServices';

import PropTypes from 'prop-types';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import CrossIcon from 'assets/images/CrossIcon';
import MDInput from 'components/MDInput';
import AuthActions from 'redux/AuthRedux';

const styles = {
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '2px  0px 10px 5px'
  },
  description: { display: 'flex', justifyContent: 'center', fontSize: '14px', margin: '20px' }
};

const ResetPassword = ({ modalOpen, setModalClose, changePasswordLink }) => {
  const dispatch = useDispatch();
  const handleClose = () => setModalClose(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      password: '',
      confirmPassword: ''
    },
    validationSchema: schema.resetPassword,
    onSubmit: (values) => {
      const link = changePasswordLink.replace('https://wms.plaidware.com/change-password/', '');
      dispatch(
        AuthActions.forgotPasswordRequest({
          loader: 'login-request',
          slug: API.CHANGE_PASSWORD,
          method: 'post',
          data: { password: values.password, token: link }
        })
      );
    }
  });
  return (
    <>
      <Dialog
        sx={{
          padding: '20px'
        }}
        open={modalOpen}
        onClose={handleClose}
      >
        <MDBox sx={{ padding: '30px', width: '460px' }}>
          <MDBox sx={styles.titleContainer}>
            <MDTypography variant="h4">{'Forgot Password'}</MDTypography>
            <MDButton sx={{ minWidth: '14px', justifyContent: 'flex-end' }} onClick={handleClose}>
              <CrossIcon />
            </MDButton>
          </MDBox>

          <MDBox>
            <MDInput
              sx={{
                minWidth: '400px'
              }}
              name="password"
              type="password"
              label="Password"
              value={formik.values.password}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              onChange={formik.handleChange}
            />
          </MDBox>
          <MDBox>
            <MDInput
              sx={{
                minWidth: '400px',
                marginTop: '20px'
              }}
              name="confirmPassword"
              type="password"
              label="Confirm Password"
              value={formik.values.confirmPassword}
              error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
              helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
              onChange={formik.handleChange}
            />
          </MDBox>
          <MDButton
            sx={{
              minWidth: '400px',
              marginTop: '30px'
            }}
            color="info"
            type="submit"
            onClick={formik.handleSubmit}
          >
            sign in
          </MDButton>
        </MDBox>
      </Dialog>
    </>
  );
};

ResetPassword.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  setModalClose: PropTypes.func.isRequired,
  changePasswordLink: PropTypes.string.isRequired
};

export default ResetPassword;
