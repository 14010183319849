/* eslint-disable complexity */
import React, { useState } from 'react';
import { Box, Grid } from '@mui/material';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import MDButton from 'components/Button';
import MDInput from 'components/MDInput';
import { makeStyles } from '@mui/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import CrossIcon from 'assets/images/CrossIcon';
import PprActions from 'redux/PprRedux';
import { useDispatch } from 'react-redux';
import schema from 'services/ValidationServices';
import { API } from 'constant';
import Button from '@mui/material/Button';
import SearchIcon from '@mui/icons-material/Search';
import QRcode from 'components/QRcode';
import SearchInventoryMain from 'pages/searchInventory/searchInventory';

const useStyles = makeStyles(() => ({
  inputLabel: {
    fontSize: '16px',
    letterSpacing: '0.01em',
    color: '#000',
    marginBottom: '4px',
    fontWeight: 'normal',
    '& .MuiFormControlLabel-label': {
      fontWeight: 'normal',
      fontSize: '16px',
      color: '#000'
    }
  },
  centreContent: {
    justifyContent: 'center'
  },
  fullWidth: {
    width: '100%',
    borderColor: '#d2d6da',
    borderRadius: '0.375rem'
  },
  pickupBtn: {
    '&:hover': {
      backgroundColor: '#ff9330',
      color: '#fff'
    }
  },
  cursorPointer: {
    cursor: 'pointer',
    display: 'flex',
    alignSelf: 'end',
    margin: '0'
  },
  textWhite: {
    color: 'white',
    '& .MuiButton-startIcon': {
      color: 'white'
    }
  }
}));

function AddWidget({ show, setShow, locationId }) {
  const classes = useStyles();

  const [viewSearchInventory, setViewSearchInventory] = React.useState(false);
  const [itemData, setItemData] = useState({});
  const [exactCount, setExactCount] = useState(false);
  const [approxCount, setApproxCount] = useState(false);
  const [varianceComment, setVarianceComment] = useState(false);
  const [reportVariance, setReportVariance] = useState(false);

  const dispatch = useDispatch();

  const putInitialValues = {
    widgetname: itemData?.commonName,
    availQuan: itemData?.availableQuantity,
    type: itemData?.type,
    size: itemData?.size,
    color: itemData?.color,
    totalQuan: '',
    putQuan: '',
    putreasons: '',
    varianceFound: false,
    countType: '',
    varianceType: '',
    varianceComments: ''
  };

  const getTransactionData = (transactionData) => {
    setViewSearchInventory(false);
    setItemData(transactionData);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: putInitialValues,
    validationSchema: schema.put,
    onSubmit: (values) => {
      const countConfirmation = {
        countType: values.countType
      };
      if (values.countType !== 'NONE') {
        countConfirmation.countResult = {
          varianceFound: values.varianceFound
        };
        if (values.varianceFound) {
          countConfirmation.countResult.varianceType = values.varianceType;
          countConfirmation.countResult.varianceComments = values.varianceComments;
        }
      }
      dispatch(
        PprActions.pickAction({
          loader: 'loading-request',
          slug: `${API.PUT_ITEM.replace(':id', itemData._id)}`,
          method: 'post',
          data: {
            putQuantity: Number(values.putQuan),
            subLevel: locationId,
            usageReason: values.putreasons,
            countConfirmation
          },
          callback: () => {
            formik.resetForm();
            setShow();
          }
        })
      );
    }
  });
  return (
    <div>
      <Dialog fullWidth open={show} maxWidth="xl" onClose={setShow}>
        <MDButton
          className={classes.cursorPointer}
          sx={{ padding: '15px', minWidth: '14px' }}
          onClick={setShow}
        >
          <CrossIcon />
        </MDButton>
        {viewSearchInventory ? (
          <DialogContent sx={{ pt: '24px' }}>
            <SearchInventoryMain onItemTransaction={getTransactionData} />
          </DialogContent>
        ) : (
          <form onSubmit={formik.handleSubmit}>
            <DialogContent>
              <Grid container spacing={2} sx={{ alignItems: 'flex-start' }}>
                <Grid
                  item
                  container
                  xs={12}
                  md={4}
                  spacing={2}
                  sx={{ display: 'flex', justifyContent: 'center' }}
                >
                  <Box
                    sx={{
                      border: '1px solid #ccc',
                      width: '70%',
                      padding: '20px',
                      minHeight: '250px',
                      justifyContent: 'center'
                    }}
                  >
                    <Button
                      variant="contained"
                      startIcon={<SearchIcon />}
                      className={classes.textWhite}
                      sx={{ width: '100%' }}
                      onClick={() => setViewSearchInventory(true)}
                    >
                      SEARCH ITEM
                    </Button>
                    <Button
                      variant="contained"
                      startIcon={<SearchIcon />}
                      className={classes.textWhite}
                      sx={{ marginTop: '20px', width: '100%' }}
                    >
                      SCAN ITEM
                    </Button>
                  </Box>
                  <Box sx={{ padding: '10px', marginTop: '20px' }}>
                    <QRcode payload={{ type: 'Sublevel', id: locationId }} />
                  </Box>
                </Grid>
                <Grid item container xs={12} md={8} spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Box component="div" sx={{}} className={classes.inputLabel}>
                      Widget Name
                    </Box>
                    <MDInput
                      disabled
                      fullWidth
                      name="widgetname"
                      type="text"
                      variant="outlined"
                      value={formik.values.widgetname}
                      error={formik.touched.widgetname && Boolean(formik.errors.widgetname)}
                      helperText={
                        formik.touched.widgetname &&
                        formik.errors.widgetname && (
                          <div style={{ color: 'red' }}>{formik.errors.commonName}</div>
                        )
                      }
                      onChange={formik.handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box component="div" sx={{}} className={classes.inputLabel}>
                      Available Quantity Here
                    </Box>
                    <MDInput
                      disabled
                      fullWidth
                      name="availQuan"
                      type="text"
                      variant="outlined"
                      value={formik.values.availQuan}
                      error={formik.touched.availQuan && Boolean(formik.errors.availQuan)}
                      helperText={
                        formik.touched.availQuan &&
                        formik.errors.availQuan && (
                          <div style={{ color: 'red' }}>{formik.errors.availQuan}</div>
                        )
                      }
                      onChange={formik.handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box component="div" sx={{}} className={classes.inputLabel}>
                      Type
                    </Box>
                    <MDInput
                      disabled
                      fullWidth
                      name="type"
                      type="text"
                      variant="outlined"
                      value={formik.values.type}
                      error={formik.touched.type && Boolean(formik.errors.type)}
                      helperText={
                        formik.touched.type &&
                        formik.errors.type && (
                          <div style={{ color: 'red' }}>{formik.errors.type}</div>
                        )
                      }
                      onChange={formik.handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box component="div" sx={{}} className={classes.inputLabel}>
                      Put Quantity
                    </Box>
                    <MDInput
                      fullWidth
                      name="putQuan"
                      type="number"
                      variant="outlined"
                      value={formik.values.putQuan}
                      error={formik.touched.putQuan && Boolean(formik.errors.putQuan)}
                      helperText={
                        formik.touched.putQuan &&
                        formik.errors.putQuan && (
                          <div style={{ color: 'red' }}>{formik.errors.putQuan}</div>
                        )
                      }
                      onChange={(putQuan) => {
                        formik.handleChange(putQuan);
                        formik.setFieldValue(
                          'totalQuan',
                          Number(formik.values.availQuan) + Number(putQuan.target.value)
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box component="div" sx={{}} className={classes.inputLabel}>
                      Size
                    </Box>
                    <MDInput
                      disabled
                      fullWidth
                      name="size"
                      type="text"
                      variant="outlined"
                      value={formik.values.size}
                      error={formik.touched.size && Boolean(formik.errors.size)}
                      helperText={
                        formik.touched.size &&
                        formik.errors.size && (
                          <div style={{ color: 'red' }}>{formik.errors.size}</div>
                        )
                      }
                      onChange={formik.handleChange}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Box component="div" sx={{}} className={classes.inputLabel}>
                      New Total Quantity Here
                    </Box>
                    <MDInput
                      disabled
                      fullWidth
                      name="totalQuan"
                      type="text"
                      variant="outlined"
                      value={formik.values.totalQuan}
                      error={formik.touched.totalQuan && Boolean(formik.errors.totalQuan)}
                      helperText={
                        formik.touched.totalQuan &&
                        formik.errors.totalQuan && (
                          <div style={{ color: 'red' }}>{formik.errors.totalQuan}</div>
                        )
                      }
                      onChange={formik.handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Grid item container xs={12} spacing={2}>
                      <Grid item xs={12} md={6}>
                        <Grid item xs={12} md={12}>
                          <Box component="div" sx={{}} className={classes.inputLabel}>
                            Color
                          </Box>
                          <MDInput
                            disabled
                            fullWidth
                            name="color"
                            type="text"
                            variant="outlined"
                            value={formik.values.color}
                            error={formik.touched.color && Boolean(formik.errors.color)}
                            helperText={
                              formik.touched.color &&
                              formik.errors.color && (
                                <div style={{ color: 'red' }}>{formik.errors.color}</div>
                              )
                            }
                            onChange={formik.handleChange}
                          />
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <FormControl sx={{ paddingTop: '16px' }}>
                            <FormLabel
                              id="demo-radio-buttons-group-label"
                              className={classes.inputLabel}
                            >
                              Would you be kind enough to confirm the remaining count here ?
                            </FormLabel>
                            <RadioGroup
                              aria-labelledby="demo-radio-buttons-group-label"
                              name="countType"
                              defaultValue=""
                              onChange={(e) => {
                                if (e.target.value === 'APPROX') {
                                  setApproxCount(true);
                                  setExactCount(false);
                                } else if (e.target.value === 'EXACT') {
                                  setApproxCount(false);
                                  setExactCount(true);
                                } else {
                                  setApproxCount(false);
                                  setExactCount(false);
                                }
                                setReportVariance(false);
                                formik.setValues({
                                  ...formik.values,
                                  countType: e.target.value,
                                  varianceFound: false,
                                  varianceType: '',
                                  varianceComments: ''
                                });
                              }}
                            >
                              <FormControlLabel
                                value="EXACT"
                                control={<Radio />}
                                label="Will count exact"
                                className={classes.inputLabel}
                              />
                              {exactCount ? (
                                <>
                                  <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
                                    <MDButton
                                      circular
                                      name="varianceFound"
                                      sx={{ minWidth: '170px' }}
                                      variant={reportVariance ? 'contained' : 'outlined'}
                                      color="primary"
                                      size="medium"
                                      onClick={() => {
                                        setReportVariance(true);
                                        formik.setFieldValue('varianceFound', true, true);
                                      }}
                                    >
                                      REPORT VARIANCE
                                    </MDButton>
                                    <MDButton
                                      circular
                                      name="varianceFound"
                                      sx={{ minWidth: '170px' }}
                                      size="medium"
                                      variant={!reportVariance ? 'contained' : 'outlined'}
                                      color="primary"
                                      onClick={() => {
                                        setReportVariance(false);
                                        formik.setValues({
                                          ...formik.values,
                                          varianceFound: false,
                                          varianceType: '',
                                          varianceComments: ''
                                        });
                                      }}
                                    >
                                      Exact OK
                                    </MDButton>
                                  </Box>
                                  {reportVariance ? (
                                    <>
                                      <Box
                                        sx={{
                                          padding: '0 15px',
                                          border: '1px solid #ccc',
                                          marginTop: '15px'
                                        }}
                                      >
                                        <FormControl>
                                          <FormLabel
                                            id="demo-radio-buttons-group-label"
                                            className={classes.inputLabel}
                                          >
                                            Variance Type
                                          </FormLabel>
                                          <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            defaultValue=""
                                            name="varianceType"
                                            value={formik.varianceType}
                                            sx={{ fontWeight: 'bold' }}
                                            onChange={(e) => {
                                              setVarianceComment(true);
                                              formik.handleChange(e);
                                            }}
                                          >
                                            <FormControlLabel
                                              value="SHORT"
                                              control={<Radio />}
                                              label="Short"
                                              className={classes.radioLabel}
                                            />

                                            <FormControlLabel
                                              value="OVER"
                                              control={<Radio />}
                                              label="Over"
                                              className={classes.radioLabel}
                                            />
                                            <FormControlLabel
                                              value="DAMAGE"
                                              control={<Radio />}
                                              label="Damage"
                                              className={classes.radioLabel}
                                            />
                                          </RadioGroup>
                                        </FormControl>
                                        {varianceComment ? (
                                          <MDInput
                                            name="varianceComments"
                                            label="varianceComments"
                                            multiline="true"
                                            rows={4}
                                            className={classes.varienceText}
                                            onChange={formik.handleChange}
                                          />
                                        ) : (
                                          ''
                                        )}
                                      </Box>
                                    </>
                                  ) : (
                                    ''
                                  )}
                                </>
                              ) : (
                                ''
                              )}

                              <FormControlLabel
                                value="APPROX"
                                control={<Radio />}
                                label="Will approximate"
                                className={classes.inputLabel}
                              />
                              {approxCount ? (
                                <>
                                  <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
                                    <MDButton
                                      circular
                                      name="varianceFound"
                                      sx={{ minWidth: '170px' }}
                                      variant={reportVariance ? 'contained' : 'outlined'}
                                      color="primary"
                                      size="medium"
                                      onClick={() => {
                                        setReportVariance(true);
                                        formik.setFieldValue('varianceFound', true, true);
                                      }}
                                    >
                                      REPORT VARIANCE
                                    </MDButton>
                                    <MDButton
                                      circular
                                      name="varianceFound"
                                      sx={{ minWidth: '170px' }}
                                      size="medium"
                                      variant={!reportVariance ? 'contained' : 'outlined'}
                                      color="primary"
                                      onClick={() => {
                                        setReportVariance(false);
                                        formik.setValues({
                                          ...formik.values,
                                          varianceFound: false,
                                          varianceType: '',
                                          varianceComments: ''
                                        });
                                      }}
                                    >
                                      Approx OK
                                    </MDButton>
                                  </Box>
                                  {reportVariance ? (
                                    <>
                                      <Box
                                        sx={{
                                          padding: '0 15px',
                                          border: '1px solid #ccc',
                                          marginTop: '15px'
                                        }}
                                      >
                                        <FormControl>
                                          <FormLabel
                                            id="demo-radio-buttons-group-label"
                                            className={classes.inputLabel}
                                          >
                                            Variance Type
                                          </FormLabel>
                                          <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            defaultValue=""
                                            name="varianceType"
                                            sx={{ fontWeight: 'bold' }}
                                            value={formik.varianceType}
                                            onChange={(e) => {
                                              setVarianceComment(true);
                                              formik.handleChange(e);
                                            }}
                                          >
                                            <FormControlLabel
                                              value="SHORT"
                                              control={<Radio />}
                                              label="Short"
                                              className={classes.radioLabel}
                                            />

                                            <FormControlLabel
                                              value="OVER"
                                              control={<Radio />}
                                              label="Over"
                                              className={classes.radioLabel}
                                            />
                                            <FormControlLabel
                                              value="DAMAGE"
                                              control={<Radio />}
                                              label="Damage"
                                              className={classes.radioLabel}
                                            />
                                          </RadioGroup>
                                        </FormControl>
                                        {varianceComment ? (
                                          <MDInput
                                            name="varianceComments"
                                            label="varianceComments"
                                            multiline="true"
                                            rows={4}
                                            className={classes.varienceText}
                                            onChange={formik.handleChange}
                                          />
                                        ) : (
                                          ''
                                        )}
                                      </Box>
                                    </>
                                  ) : (
                                    ''
                                  )}
                                </>
                              ) : (
                                ''
                              )}

                              <FormControlLabel
                                value="NONE"
                                control={<Radio />}
                                label="Not this time"
                                className={classes.inputLabel}
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Box component="div" sx={{}} className={classes.inputLabel}>
                          Put Reasons/Reference
                        </Box>
                        <MDInput
                          fullWidth
                          name="putreasons"
                          type="text"
                          variant="outlined"
                          as="textarea"
                          value={formik.values.putreasons}
                          error={formik.touched.putreasons && Boolean(formik.errors.putreasons)}
                          helperText={
                            formik.touched.putreasons &&
                            formik.errors.putreasons && (
                              <div style={{ color: 'red' }}>{formik.errors.putreasons}</div>
                            )
                          }
                          className={classes.fullWidth}
                          minRows="4"
                          onChange={formik.handleChange}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions className={classes.centreContent}>
              <MDButton size="medium" type="submit" color="primary">
                COMPLETE PUTTING
              </MDButton>
            </DialogActions>
          </form>
        )}
      </Dialog>
    </div>
  );
}
AddWidget.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.func,
  locationId: PropTypes.string
};

export default AddWidget;
