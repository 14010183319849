import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import _ from 'underscore';
import { getFetchingValue, getErrorValue } from '../services/Utils';

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  warehouseDataAction: ['payload'],
  warehouseDataSuccess: ['data'],
  warehouseDataFailure: ['error'],

  createWarehouseAction: ['payload'],
  createWarehouseSuccess: ['data'],
  createWarehouseFailure: ['error'],

  editWarehouseAction: ['payload'],
  editWarehouseSuccess: ['data'],
  editWarehouseFailure: ['error'],

  deleteWarehouseAction: ['payload'],
  deleteWarehouseSuccess: ['data'],
  deleteWarehouseAssociationFailure: ['data'],

  clearWarehouseAssociationAction: ['payload']
});

export const WarehouseTypes = Types;
const WarehouseActions = Creators;
export default WarehouseActions;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  warehouseDetail: [],
  error: {},
  createWarehouseLoading: false,
  createWarehouseError: {},
  editWarehouseLoading: false,
  editWarehouseError: {},
  deleteAssociations: []
});

/* ------------- Selectors ------------- */
export const WarehouseSelectors = {
  getWarehouseDetail: (state) => state.warehouse.warehouseDetail,
  getWarehouseDetailById: (id) => (state) =>
    state.warehouse.warehouseDetail.find((x) => x._id === id),
  createWarehouseDetail: (state) => state.warehouse.createWarehouse,
  editWarehouseDetail: (state) => state.warehouse.editWarehouse,
  getPreDeleteAssociations: (state) => state.warehouse.deleteAssociations
};

/* ------------- Reducers ------------- */
export const onWarehouseDataAction = (state, { payload }) =>
  state.merge({
    fetching: _.uniq([state.fetching, payload?.loader]),
    error: getErrorValue(state?.error, payload?.loader)
  });

export const onWarehouseDataSuccess = (state, { data }) =>
  state.merge({
    fetching: getFetchingValue(state.fetching, data?.loader),
    error: getErrorValue(state?.error, data?.loader),
    warehouseDetail: data.warehouseDetail
  });

export const onWarehouseDataFailure = (state, { error }) =>
  state.merge({
    fetching: _.without(state.fetching, error?.loader),
    error: { ...state.error, [error?.loader]: error?.error }
  });

export const onCreateWarehouseAction = (state, { payload }) =>
  state.merge({
    fetching: _.uniq([state.fetching, payload?.loader]),
    error: getErrorValue(state?.error, payload?.loader)
  });

export const onCreateWarehouseSuccess = (state, { data }) =>
  state.merge({
    fetching: getFetchingValue(state.fetching, data?.loader),
    error: getErrorValue(state?.error, data?.loader),
    warehouseDetail: [...state.warehouseDetail, data.createdWarehouse]
  });

export const onCreateWarehouseFailure = (state, { error }) =>
  state.merge({
    fetching: _.without(state.fetching, error?.loader),
    error: { ...state.error, [error?.loader]: error?.error }
  });

export const onEditWarehouseAction = (state, { payload }) =>
  state.merge({
    fetching: _.uniq([state.fetching, payload?.loader]),
    error: getErrorValue(state?.error, payload?.loader)
  });

export const onEditWarehouseSuccess = (state, { data }) =>
  state.merge({
    fetching: getFetchingValue(state.fetching, data?.loader),
    error: getErrorValue(state?.error, data?.loader),
    warehouseDetail: [
      ...state.warehouseDetail.filter((x) => x._id !== data?.editedWarehouse?._id),
      data.editedWarehouse
    ]
  });

export const onDeleteWarehouseAction = (state, { payload }) =>
  state.merge({
    fetching: _.uniq([state.fetching, payload?.loader]),
    error: getErrorValue(state?.error, payload?.loader)
  });

export const onDeleteWarehouseSuccess = (state, { data }) =>
  state.merge({
    fetching: getFetchingValue(state.fetching, data?.loader),
    error: getErrorValue(state?.error, data?.loader),
    warehouseDetail: data?.deletedWarehouseID
      ? [...state.warehouseDetail.filter((x) => x._id !== data?.deletedWarehouseID)]
      : state.warehouseDetail
  });

export const onEditWarehouseFailure = (state, { error }) =>
  state.merge({
    fetching: _.without(state.fetching, error?.loader),
    error: { ...state.error, [error?.loader]: error?.error }
  });

export const onDeleteWarehouseAssociationFailure = (state, { data }) =>
  state.merge({
    fetching: getFetchingValue(state.fetching, data?.loader),
    error: getErrorValue(state?.error, data?.loader),
    deleteAssociations: data?.associations
  });

export const onClearWarehouseAssociationAction = (state) =>
  state.merge({
    deleteAssociations: []
  });

/* ------------- Hookup Reducers To Types ------------- */
export const warehouseReducer = createReducer(INITIAL_STATE, {
  [Types.WAREHOUSE_DATA_ACTION]: onWarehouseDataAction,
  [Types.WAREHOUSE_DATA_SUCCESS]: onWarehouseDataSuccess,
  [Types.WAREHOUSE_DATA_FAILURE]: onWarehouseDataFailure,

  [Types.CREATE_WAREHOUSE_ACTION]: onCreateWarehouseAction,
  [Types.CREATE_WAREHOUSE_SUCCESS]: onCreateWarehouseSuccess,
  [Types.CREATE_WAREHOUSE_FAILURE]: onCreateWarehouseFailure,

  [Types.EDIT_WAREHOUSE_ACTION]: onEditWarehouseAction,
  [Types.EDIT_WAREHOUSE_SUCCESS]: onEditWarehouseSuccess,
  [Types.EDIT_WAREHOUSE_FAILURE]: onEditWarehouseFailure,
  [Types.DELETE_WAREHOUSE_ACTION]: onDeleteWarehouseAction,
  [Types.DELETE_WAREHOUSE_SUCCESS]: onDeleteWarehouseSuccess,
  [Types.DELETE_WAREHOUSE_ASSOCIATION_FAILURE]: onDeleteWarehouseAssociationFailure,
  [Types.CLEAR_WAREHOUSE_ASSOCIATION_ACTION]: onClearWarehouseAssociationAction
});
