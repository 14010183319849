import DashboardNavbar from 'components/DashboardNavbar';
import DashboardLayout from 'layouts/DashboardLayout';
import Breadcrumbs from 'components/Breadcrumbs';
import MDBox from 'components/MDBox';
import BasicTable from 'components/BasicTable';
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TableBody,
  TableCell,
  TableRow
} from '@mui/material';
import { useEffect, useState } from 'react';
import { API } from 'constant';
import ReportActions from 'redux/ReportsRedux';
import { useDispatch, useSelector } from 'react-redux';
import { ReportSelectors } from 'redux/ReportsRedux';
import MDButton from 'components/MDButton';
import TablePagination from 'components/TablePagination';
import { exportToExcel } from 'utils/exportExcel';

const tHeads = [
  { id: 'name', label: 'Name' },
  { id: 'family', label: 'Family' },
  { id: 'subFamily', label: 'Sub Family' },
  { id: 'typeSizeColor', label: 'Type - Size - Color' },
  { id: 'warehouse', label: 'Warehouse' },
  { id: 'totalQuantity', label: 'Total Quantity' },
  { id: 'reservedQuantity', label: 'Reserved Quantity' },
  { id: 'remainingQuantity', label: 'Remaining Quantity' },
  { id: 'forJob', label: 'For Job #' },
  { id: 'pickupDate', label: 'Expected Pick Date' },
  { id: 'reservationDate', label: 'Reservation Date' },
  { id: 'reservedBy', label: 'Reserved By' },
  { id: 'cancelButton', label: '' }
];

const mapHeadsToResponse = (data) => {
  return data.map((item) => {
    const attributeText = `${item?.performedOn?.type || 'NIL'} - ${
      item?.performedOn?.size || 'NIL'
    } - ${item?.performedOn?.color || 'NIL'}`;
    return {
      _id: item?._id,
      name: item?.performedOn?.commonName || '-',
      family:
        item?.performedOn?.widgetFamily?.parent?.name ||
        item?.performedOn?.widgetFamily?.name ||
        '-',
      subFamily: item?.performedOn?.widgetFamily?.parent?.name
        ? item?.performedOn?.widgetFamily?.name || '-'
        : '-',
      typeSizeColor: attributeText,
      warehouse: item?.warehouse_id?.name || '-',
      totalQuantity: item?.availableAtWarehouse || '-',
      reservedQuantity: item?.reserveQuantity || '-',
      pickedQuantity: item?.pickedQuantity || '-',
      remainingQuantity: item?.remainingQuantity || '-',
      forJob: item?.job || '-',
      pickupDate: item?.pickupDate ? new Date(item?.pickupDate).toLocaleDateString() : '-',
      reservationDate: item?.createdAt ? new Date(item?.createdAt).toLocaleDateString() : '-',
      reservedBy: item?.performedBy?.fullName || '-'
    };
  });
};

const Reservations = () => {
  const dispatch = useDispatch();
  const reportData = useSelector(ReportSelectors.getReportDetail);
  const [bodyData, setBodyData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const reportType = useSelector(ReportSelectors.getReportType);
  const count = useSelector(ReportSelectors.getReportCount);

  const [jobList, setJobList] = useState([]);
  const [reservedByList, setReservedByList] = useState([]);

  const [selectedJob, setSelectedJob] = useState('');
  const [selectedReservedBy, setSelectedReservedBy] = useState('');

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(50);

  useEffect(() => {
    dispatch(
      ReportActions.getReportAction({
        loader: 'loading-request',
        slug: `${API.REPORT_RESERVES}?picked=false&cancelled=false&page=${page}&perPage=${perPage}`,
        method: 'get',
        reportType: 'reservation'
      })
    );
  }, [page, perPage]);

  useEffect(() => {
    const data = mapHeadsToResponse(reportData);
    setBodyData(data);
    setTableData(data);

    const job = [];
    const reservedBy = [];
    data.forEach((currentData) => {
      !job.includes(currentData.forJob) && job.push(currentData.forJob);
      !reservedBy.includes(currentData.reservedBy) && reservedBy.push(currentData.reservedBy);
    });
    setJobList(job);
    setReservedByList(reservedBy);
  }, [reportData]);

  useEffect(() => {
    if (selectedJob || selectedReservedBy) {
      const filteredData = bodyData.filter((currentData) => {
        return (
          (!selectedJob || currentData.forJob === selectedJob) &&
          (!selectedReservedBy || currentData.reservedBy === selectedReservedBy)
        );
      });
      setTableData(filteredData);
    }
  }, [selectedJob, selectedReservedBy]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Breadcrumbs
        title="Warehouse Management System Reservations"
        route={[{ name: 'Home', path: '/home' }, { name: 'Reservations' }]}
      />
      <MDBox px={5} py={3}>
        <Grid
          container
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'space-evenly'
          }}
        >
          <Grid item xs={3}>
            <FormControl fullWidth sx={{ marginBottom: '10px' }}>
              <InputLabel>Filter by Job</InputLabel>
              <Select
                fullWidth
                labelId="job-filter-label"
                id="job-filter"
                value={selectedJob}
                label="Filter by Job"
                onChange={(e) => setSelectedJob(e.target.value)}
              >
                <MenuItem value="">
                  <em>All</em>
                </MenuItem>
                {jobList.map((job, i) => (
                  <MenuItem key={i} value={job}>
                    {job}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth sx={{ marginBottom: '10px' }}>
              <InputLabel>Filter by ReservedBy</InputLabel>
              <Select
                fullWidth
                labelId="reservedBy-filter-label"
                id="reservedBy-filter"
                value={selectedReservedBy}
                label="Filter by ReservedBy"
                onChange={(e) => setSelectedReservedBy(e.target.value)}
              >
                <MenuItem value="">
                  <em>All</em>
                </MenuItem>
                {reservedByList.map((reservedBy, i) => (
                  <MenuItem key={i} value={reservedBy}>
                    {reservedBy}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={2}>
            <MDButton
              fullWidth
              size="medium"
              color="primary"
              variant="outlined"
              sx={{ marginBottom: '10px' }}
              onClick={() => {
                setSelectedJob('');
                setSelectedReservedBy('');
                setTableData(bodyData);
              }}
            >
              RESET
            </MDButton>
          </Grid>
          <Grid item xs={2}>
            <MDButton
              fullWidth
              size="medium"
              color="primary"
              sx={{ marginBottom: '10px' }}
              onClick={() => {
                const sortedTableData = [...tableData].sort((a, b) => {
                  if (a.forJob !== b.forJob) {
                    return a.forJob > b.forJob ? -1 : 1;
                  }
                  return a.createdAt > b.createdAt ? -1 : 1;
                });
                exportToExcel(tHeads, sortedTableData, 'Reservations');
              }}
            >
              Export as Excel
            </MDButton>
          </Grid>
        </Grid>
        <Box>
          {reportType === 'reservation' ? (
            <Box sx={{ overflowX: 'auto', overflowY: 'auto', height: '63vh' }}>
              <BasicTable
                headCells={tHeads}
                records={tableData}
                backgroundColor="#E5E5E5"
                color="#8D8D8D"
              >
                <TableBody>
                  {tableData && tableData.length
                    ? tableData.map((reservation) => (
                        <TableRow key={reservation._id}>
                          {tHeads.map((head, i) => {
                            if (head.id === 'cancelButton') {
                              return (
                                <MDButton
                                  fullWidth
                                  key={reservation._id + i}
                                  size="small"
                                  color={reservation.pickedQuantity > 0 ? 'warning' : 'error'}
                                  onClick={() => {
                                    dispatch(
                                      ReportActions.cancelReservationAction({
                                        loader: 'cancel-request',
                                        slug: API.CANCEL_RESERVATION,
                                        method: 'delete',
                                        data: {
                                          cancellationReason: 'testReason'
                                        },
                                        reservationId: reservation._id
                                      })
                                    );
                                  }}
                                >
                                  {reservation.pickedQuantity > 0 ? 'CLOSE' : 'CANCEL'}
                                </MDButton>
                              );
                            } else
                              return (
                                <TableCell key={reservation._id + i}>
                                  {reservation[head.id]}
                                </TableCell>
                              );
                          })}
                        </TableRow>
                      ))
                    : 'No records'}
                </TableBody>
              </BasicTable>
            </Box>
          ) : (
            'Loading'
          )}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '15px 10px'
            }}
          >
            <Box sx={{ fontSize: '14px', color: '#000' }}>
              Per page:{' '}
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={perPage}
                label="Page Number"
                onChange={(e) => {
                  setPerPage(e.target.value);
                }}
              >
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={200}>200</MenuItem>
                <MenuItem value={1000000}>All</MenuItem>
              </Select>
            </Box>
            <Box>
              <TablePagination count={Math.ceil(count / perPage)} page={page} setPage={setPage} />
            </Box>
            <Box sx={{ fontSize: '14px', color: '#000' }}>
              {!!count && (
                <>
                  {(page - 1) * perPage + 1} to {perPage * page > count ? count : perPage * page} of{' '}
                  {count}
                </>
              )}
            </Box>
          </Box>
        </Box>
      </MDBox>
    </DashboardLayout>
  );
};

export default Reservations;
