import PropTypes from 'prop-types';

const SetupIcon = ({ width = 96, height = 96, color = '#007aff', ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 25 24"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.75 7.25H16.75C16.34 7.25 16 6.91 16 6.5C16 6.09 16.34 5.75 16.75 5.75H22.75C23.16 5.75 23.5 6.09 23.5 6.5C23.5 6.91 23.16 7.25 22.75 7.25Z"
      fill={color}
    />
    <path
      d="M6.75 7.25H2.75C2.34 7.25 2 6.91 2 6.5C2 6.09 2.34 5.75 2.75 5.75H6.75C7.16 5.75 7.5 6.09 7.5 6.5C7.5 6.91 7.16 7.25 6.75 7.25Z"
      fill={color}
    />
    <path
      d="M10.75 10.75C8.41 10.75 6.5 8.84 6.5 6.5C6.5 4.16 8.41 2.25 10.75 2.25C13.09 2.25 15 4.16 15 6.5C15 8.84 13.09 10.75 10.75 10.75ZM10.75 3.75C9.23 3.75 8 4.98 8 6.5C8 8.02 9.23 9.25 10.75 9.25C12.27 9.25 13.5 8.02 13.5 6.5C13.5 4.98 12.27 3.75 10.75 3.75Z"
      fill={color}
    />
    <path
      d="M22.75 18.25H18.75C18.34 18.25 18 17.91 18 17.5C18 17.09 18.34 16.75 18.75 16.75H22.75C23.16 16.75 23.5 17.09 23.5 17.5C23.5 17.91 23.16 18.25 22.75 18.25Z"
      fill={color}
    />
    <path
      d="M8.75 18.25H2.75C2.34 18.25 2 17.91 2 17.5C2 17.09 2.34 16.75 2.75 16.75H8.75C9.16 16.75 9.5 17.09 9.5 17.5C9.5 17.91 9.16 18.25 8.75 18.25Z"
      fill={color}
    />
    <path
      d="M14.75 21.75C12.41 21.75 10.5 19.84 10.5 17.5C10.5 15.16 12.41 13.25 14.75 13.25C17.09 13.25 19 15.16 19 17.5C19 19.84 17.09 21.75 14.75 21.75ZM14.75 14.75C13.23 14.75 12 15.98 12 17.5C12 19.02 13.23 20.25 14.75 20.25C16.27 20.25 17.5 19.02 17.5 17.5C17.5 15.98 16.27 14.75 14.75 14.75Z"
      fill={color}
    />
  </svg>
);
export default SetupIcon;

SetupIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string
};
