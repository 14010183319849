import React from 'react';
import DashboardNavbar from 'components/DashboardNavbar';
import DashboardLayout from 'layouts/DashboardLayout';
import Breadcrumbs from 'components/Breadcrumbs';
import { Box, Grid } from '@mui/material';
import MDButton from 'components/Button';
import MDInput from 'components/MDInput';
import { makeStyles } from '@mui/styles';
import Avatar from '@mui/material/Avatar';
import { useDispatch, useSelector } from 'react-redux';
import { ScanDataSelectors } from 'redux/ScanRedux';
import { useLocation } from 'react-router-dom';
import ScanActions from 'redux/ScanRedux';
import { API } from 'constant';
import QRcode from 'components/QRcode';
import ScanCheckin from 'modals/ScanCheckin';
import ScanCheckOut from 'modals/ScanCheckout';
import ScanReport from 'modals/ScanReport';

const useStyles = makeStyles(() => ({
  inputLabel: {
    fontSize: '16px',
    letterSpacing: '0.01em',
    color: '#000',
    marginBottom: '4px',
    paddingTop: '16px'
  },
  fullWidth: {
    width: '100%',
    borderColor: '#d2d6da',
    borderRadius: '0.375rem'
  },
  alignRight: {
    marginLeft: 'auto'
  }
}));

const CheckInOutReport = () => {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();

  const [item, setItem] = React.useState(null);
  const [itemAvailability, setItemAvailability] = React.useState(true);

  const [loader, setLoader] = React.useState(true);
  const [loadModal, setLoadModal] = React.useState(false);
  const [modalData, setModalData] = React.useState(null);

  const [showCheckInModal, setShowCheckInModal] = React.useState(false);
  const [showCheckOutModal, setShowCheckOutModal] = React.useState(false);
  const [showReportModal, setShowReportModal] = React.useState(false);

  const scanData = useSelector(ScanDataSelectors.getScanDetail);
  React.useEffect(() => {
    dispatch(
      ScanActions.getScanAction({
        loader: 'loading-request',
        slug: `${API.GET_ITEMS_BY_SCAN}type=Widget&id=${location.state.id}`,
        method: 'get'
      })
    );
  }, [showCheckInModal, showCheckOutModal, showReportModal]);

  React.useEffect(() => {
    const availabilityStatus = scanData.inventory[0] ? scanData.inventory[0].isAvailable : true;
    setItem(scanData.item);
    setItemAvailability(availabilityStatus);
    setModalData(getModalData(scanData, availabilityStatus));
    setLoader(false);
  }, [scanData]);

  const getModalData = (currentScanData, availabilityStatus) => {
    const { item: currentItem, inventory: currentList } = currentScanData;
    return {
      inventoryId: currentItem._id,
      locationList: availabilityStatus
        ? [
            {
              subLevelId: currentList[0].sub_level_id,
              availability: currentList[0].isAvailable
            }
          ]
        : null
    };
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Breadcrumbs
        title="Warehouse Management System Reports"
        route={[
          { name: 'Home', path: '/home' },
          { name: 'Scan', path: '/scan' },
          { name: 'Location Label' }
        ]}
      />
      <Box className={loader ? 'loader' : ''}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            padding: '15px 48px',
            borderTop: '1px solid #ccc',
            borderBottom: '1px solid #ccc'
          }}
        >
          <Avatar
            src={item?.images?.[0]}
            alt="widget"
            sx={{ borderRadius: '0', width: '250px', height: 'auto' }}
          >
            {' '}
          </Avatar>
          <Box className={classes.alignRight}>
            <QRcode
              payload={JSON.stringify({ type: 'Sublevel', id: location.state.id })}
              width={100}
              height={100}
            ></QRcode>
          </Box>
        </Box>
        <Box sx={{ padding: '0 48px' }}>
          <Grid container spacing={2} sx={{ alignItems: 'flex-start' }}>
            <Grid item container xs={12} md={6}>
              <Grid item container xs={12} md={12}>
                <Box component="div" sx={{}} className={classes.inputLabel}>
                  Formal Name
                </Box>
                <MDInput
                  disabled
                  fullWidth
                  name="formalName"
                  type="text"
                  variant="outlined"
                  value={item?.formalName}
                />
              </Grid>
              <Grid item container xs={12} md={12}>
                <Box component="div" sx={{}} className={classes.inputLabel}>
                  Description
                </Box>
                <MDInput
                  disabled
                  fullWidth
                  name="description"
                  type="text"
                  variant="outlined"
                  value={item?.description}
                />
              </Grid>
              <Grid item container xs={12} md={12}>
                <Box component="div" sx={{}} className={classes.inputLabel}>
                  Manufacturer
                </Box>
                <MDInput
                  fullWidth
                  disabled
                  name="manufacturer"
                  type="text"
                  variant="outlined"
                  value={item?.manufacturer}
                />
              </Grid>
              <Grid item container xs={12} md={12}>
                <Box component="div" sx={{}} className={classes.inputLabel}>
                  Type
                </Box>
                <MDInput
                  fullWidth
                  disabled
                  name="type"
                  type="text"
                  variant="outlined"
                  value={item?.type}
                />
              </Grid>
              <Grid item container xs={12} md={12}>
                <Box component="div" sx={{}} className={classes.inputLabel}>
                  Model /VIN#
                </Box>
                <MDInput fullWidth name="model" type="text" variant="outlined" />
              </Grid>
              <Grid item container xs={12} md={12}>
                <Box component="div" sx={{}} className={classes.inputLabel}>
                  Last Service Meter Reading
                </Box>
                <MDInput fullWidth name="lastReading" type="text" variant="outlined" />
              </Grid>
              <Grid item container xs={12} md={12}>
                <Box component="div" sx={{}} className={classes.inputLabel}>
                  Last Check-Out Meter Reading
                </Box>
                <MDInput
                  fullWidth
                  name="checkoutReading"
                  type="text"
                  variant="outlined"
                  placeholder="pickup truck"
                />
              </Grid>
              <Grid item container xs={12} md={12}>
                <Box component="div" sx={{}} className={classes.inputLabel}>
                  Last Check-In Meter Reading
                </Box>
                <MDInput
                  fullWidth
                  name="checkinReading"
                  type="text"
                  variant="outlined"
                  placeholder="pickup truck"
                />
              </Grid>
              <Grid item container xs={12} md={12}>
                <Box component="div" sx={{}} className={classes.inputLabel}>
                  Last service Date
                </Box>
                <MDInput
                  fullWidth
                  name="service Date"
                  type="text"
                  variant="outlined"
                  placeholder="pickup truck"
                />
              </Grid>
            </Grid>
            <Grid item container xs={12} md={6}>
              <Grid item container xs={12} md={12}>
                <Box component="div" sx={{}} className={classes.inputLabel}>
                  Common Name
                </Box>
                <MDInput
                  fullWidth
                  disabled
                  name="commonName"
                  value={item?.commonName}
                  type="text"
                  variant="outlined"
                />
              </Grid>
              <Grid item container xs={12} md={12}>
                <Box component="div" sx={{}} className={classes.inputLabel}>
                  Color
                </Box>
                <MDInput
                  fullWidth
                  disabled
                  name="color"
                  type="text"
                  variant="outlined"
                  value={item?.color}
                />
              </Grid>
              <Grid item container xs={12} md={12}>
                <Box component="div" sx={{}} className={classes.inputLabel}>
                  Size
                </Box>
                <MDInput
                  fullWidth
                  disabled
                  name="size"
                  type="text"
                  variant="outlined"
                  value={item?.size}
                />
              </Grid>
              <Grid item container xs={12} md={12}>
                <Box component="div" sx={{}} className={classes.inputLabel}>
                  Last Check-Out By
                </Box>
                <MDInput
                  fullWidth
                  name="checkedOutBy"
                  type="text"
                  variant="outlined"
                  placeholder="pickup truck"
                />
              </Grid>
              <Grid item container xs={12} md={12}>
                <Box component="div" sx={{}} className={classes.inputLabel}>
                  Last Check-In By
                </Box>
                <MDInput
                  fullWidth
                  name="checkinBy"
                  type="text"
                  variant="outlined"
                  placeholder="pickup truck"
                />
              </Grid>
              <Grid item container xs={12} md={12}>
                <Box component="div" sx={{}} className={classes.inputLabel}>
                  Last Reported Date &amp; Time
                </Box>
                <MDInput
                  fullWidth
                  name="dateTime"
                  type="text"
                  variant="outlined"
                  placeholder="pickup truck"
                />
              </Grid>
              <Grid item container xs={12} md={12}>
                <Box component="div" sx={{}} className={classes.inputLabel}>
                  Issue Reported
                </Box>
                <MDInput
                  fullWidth
                  name="issueReported"
                  type="text"
                  variant="outlined"
                  placeholder="pickup truck"
                />
              </Grid>
              <Grid item container xs={12} md={12}>
                <Box component="div" sx={{}} className={classes.inputLabel}>
                  Toll Tag#
                </Box>
                <MDInput
                  fullWidth
                  name="tollTag"
                  type="text"
                  variant="outlined"
                  placeholder="pickup truck"
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '25px 0' }}
        >
          <MDButton
            size="medium"
            color="success"
            type="submit"
            disabled={itemAvailability}
            sx={{ marginRight: '15px' }}
            onClick={() => {
              setShowCheckInModal(true);
              setLoader(true);
            }}
          >
            CHECK-IN
          </MDButton>
          <MDButton
            size="medium"
            disabled={!itemAvailability}
            color="error"
            type="submit"
            sx={{ marginRight: '15px' }}
            onClick={() => {
              setShowCheckOutModal(true);
              setLoader(true);
            }}
          >
            CHECK-OUT
          </MDButton>
          <MDButton
            size="medium"
            color="primary"
            type="submit"
            sx={{ marginRight: '15px' }}
            onClick={() => {
              setShowReportModal(true);
              setLoader(true);
            }}
          >
            REPORT
          </MDButton>
        </Box>

        {modalData && (
          <>
            <ScanCheckin
              show={showCheckInModal}
              setShow={() => {
                setShowCheckInModal(false);
                setModalData(null);
              }}
              inventoryItem={modalData}
            />
            <ScanCheckOut
              show={showCheckOutModal}
              setShow={() => {
                setShowCheckOutModal(false);
                setModalData(null);
              }}
              inventoryItem={modalData}
            />
            <ScanReport
              show={showReportModal}
              setShow={() => {
                setShowReportModal(false);
                setModalData(null);
              }}
              inventoryItem={modalData}
            />
          </>
        )}
      </Box>
    </DashboardLayout>
  );
};

export default CheckInOutReport;
