import React from 'react';
import PropTypes from 'prop-types';
import { GetIconFromSlug } from 'utils/inventorySlugTools';
import { Edit as EditIcon } from '@mui/icons-material';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  styled,
  useRadioGroup
} from '@mui/material';

const StyledFormControlLabel = styled((props) => <FormControlLabel {...props} />)(
  ({ theme, checked }) => ({
    '.MuiFormControlLabel-label': checked && {
      border: `1px solid ${theme.palette.primary.light}`,
      borderRadius: '10px'
    }
  })
);

function MyFormControlLabel(props) {
  const radioGroup = useRadioGroup();
  return <StyledFormControlLabel checked={props.value === radioGroup.value} {...props} />;
}
MyFormControlLabel.propTypes = {
  value: PropTypes.any
};

function IconSelector({ iconList, value, onChange }) {
  const [iconSlugOpen, setIconSlugOpen] = React.useState(false);

  return (
    <>
      {GetIconFromSlug(value)}
      <EditIcon
        fontSize="medium"
        onClick={() => {
          setIconSlugOpen(true);
        }}
      />

      <Dialog
        open={iconSlugOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClose={() => {
          setIconSlugOpen(false);
        }}
      >
        <DialogTitle id="alert-dialog-title">Select Icon</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <RadioGroup
              row
              sx={{
                marginTop: '15px'
              }}
              aria-labelledby="demo-error-radios"
              name="icon_slug"
              value={value}
              onChange={(...rest) => {
                onChange(...rest);
                setIconSlugOpen(false);
              }}
            >
              {iconList.map((iconSlug) => (
                <MyFormControlLabel
                  key={iconSlug}
                  value={iconSlug}
                  control={<Radio style={{ display: 'none' }} />}
                  label={GetIconFromSlug(iconSlug)}
                />
              ))}
            </RadioGroup>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}

IconSelector.propTypes = {
  value: PropTypes.any,
  iconList: PropTypes.any,
  onChange: PropTypes.any
};

export default IconSelector;
