import DashboardNavbar from 'components/DashboardNavbar';
import DashboardLayout from 'layouts/DashboardLayout';
import WarehouseIcon from 'assets/images/WarehouseIcon';
import InventoryIcon from 'assets/images/InventoryIcon';
import ProfileCircleIcon from 'assets/images/ProfileCircleIcon';
import LabelIcon from 'assets/images/LabelIcon';
import TileBasic from 'components/TileBasic';
import Breadcrumbs from 'components/Breadcrumbs';
import { useSelector } from 'react-redux';
import { AuthSelectors } from 'redux/AuthRedux';

function SetupHome() {
  const setupModules = [
    {
      name: 'Warehouse',
      path: '/setup/warehouse',
      disabled: !useSelector(AuthSelectors.hasUIModule('Warehouse')),
      icon: <WarehouseIcon width={96} height={96} color="#007AFF" />
    },
    {
      name: 'Inventory',
      path: '/setup/inventory',
      disabled: !useSelector(AuthSelectors.hasUIModule('Inventory')),
      icon: <InventoryIcon width={96} height={96} color="#007AFF" />
    },
    {
      name: 'User Access',
      path: '/setup/users-access',
      // disabled: true,
      disabled: !useSelector(AuthSelectors.hasUIModule('Users')),
      icon: <ProfileCircleIcon width={96} height={96} color="#007AFF" />
    },
    {
      name: 'Labeling',
      path: '/setup/labeling',
      disabled: !useSelector(AuthSelectors.hasUIModule('Labels')),
      icon: <LabelIcon width={96} height={96} color="#007AFF" />
    }
  ];
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Breadcrumbs
        title="Warehouse Management System Setup"
        route={[{ name: 'Home', path: '/home' }, { name: 'Setup' }]}
      />
      <TileBasic tiles={setupModules} />
    </DashboardLayout>
  );
}

export default SetupHome;
