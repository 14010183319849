import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import _ from 'underscore';
import { getFetchingValue, getErrorValue } from '../services/Utils';

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  getReportAction: ['payload'],
  getReportSuccess: ['data'],
  getReportFailure: ['error'],
  cancelReservationAction: ['payload'],
  cancelReservationSuccess: ['data'],
  cancelReservationFailure: ['error']
});

export const ReportTypes = Types;
const ReportActions = Creators;
export default ReportActions;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  reportData: [],
  reportType: null,
  count: 0,
  totalInventoryCost: 0,
  getReportsLoading: false,
  getReportsError: {},
  cancelReservationMessage: null
});

/* ------------- Selectors ------------- */
export const ReportSelectors = {
  getReportDetail: (state) => state.report.reportData,
  getReportType: (state) => state.report.reportType,
  getReportCount: (state) => state.report.count,
  getTotalInventoryCost: (state) => state.report.totalInventoryCost
};

/* ------------- Reducers ------------- */
export const onGetReportAction = (state, { payload }) =>
  state.merge({
    fetching: _.uniq([state.fetching, payload?.loader]),
    error: getErrorValue(state?.error, payload?.loader)
  });

export const onGetReportSuccess = (state, { data }) =>
  state.merge({
    fetching: getFetchingValue(state.fetching, data?.loader),
    error: getErrorValue(state?.error, data?.loader),
    reportData: data?.getReportDetail,
    reportType: data?.reportType,
    count: data?.count || 0,
    totalInventoryCost: data?.totalInventoryCost || 0
  });

export const onGetReportFailure = (state, { error }) =>
  state.merge({
    fetching: _.without(state.fetching, error?.loader),
    error: { ...state.error, [error?.loader]: error?.error }
  });

export const onCancelReservationAction = (state, { payload }) =>
  state.merge({
    fetching: _.uniq([...state.fetching, payload?.loader]),
    error: getErrorValue(state?.error, payload?.loader)
  });

export const onCancelReservationSuccess = (state, { data }) =>
  state.merge({
    fetching: getFetchingValue(state.fetching, data?.loader),
    error: getErrorValue(state?.error, data?.loader),
    reportData: state.reportData.filter((_) => _._id !== data.reservationId) // remove from array
  });

export const onCancelReservationFailure = (state, { error }) =>
  state.merge({
    fetching: _.without(state.fetching, error?.loader),
    error: { ...state.error, [error?.loader]: error?.error }
  });

/* ------------- Hookup Reducers To Types ------------- */
export const reportReducer = createReducer(INITIAL_STATE, {
  [Types.GET_REPORT_ACTION]: onGetReportAction,
  [Types.GET_REPORT_SUCCESS]: onGetReportSuccess,
  [Types.GET_REPORT_FAILURE]: onGetReportFailure,
  [Types.CANCEL_RESERVATION_ACTION]: onCancelReservationAction,
  [Types.CANCEL_RESERVATION_SUCCESS]: onCancelReservationSuccess,
  [Types.CANCEL_RESERVATION_FAILURE]: onCancelReservationFailure
});
