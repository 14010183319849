import { all } from 'redux-saga/effects';
import AuthSaga from './Auth';
import WarehouseSaga from './Warehouse';
import UsersSaga from './Users';
import ProductSaga from './Product';
import InventorySaga from './Inventory';
import RolesSaga from './Roles';
import WarehouseLocationsSaga from './WarehouseLocations';
import WidgetSaga from './Widget';
import LabellingSaga from './Labelling';
import ItemSaga from './Item';
import ReportSaga from './Report';
import PprSaga from './Ppr';
import ScanSaga from './Scan';
import NotificationSaga from './Notification';

export default function* rootSaga() {
  yield all([...AuthSaga]);
  yield all([...WarehouseSaga]);
  yield all([...UsersSaga]);
  yield all([...ProductSaga]);
  yield all([...InventorySaga]);
  yield all([...RolesSaga]);
  yield all([...WarehouseLocationsSaga]);
  yield all([...WidgetSaga]);
  yield all([...LabellingSaga]);
  yield all([...ItemSaga]);
  yield all([...ReportSaga]);
  yield all([...PprSaga]);
  yield all([...ScanSaga]);
  yield all([...NotificationSaga]);
}
