/* eslint-disable indent */
import React from 'react';

import MDTypography from 'components/MDTypography';
import { Box } from '@mui/material';
import QRcode from 'components/QRcode';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  separator: {
    position: 'relative',
    '&::after': {
      content: ' "-" ',
      fontWeight: 'bold',
      display: 'inline-block',
      fontSize: '16px',
      position: 'absolute',
      right: '-2px',
      top: '8px'
    }
  },
  gridOuter: {
    backgroundColor: '#fff',
    display: 'flex',
    alignItems: 'center',
    padding: '5px 0',
    clear: 'both'
  },
  wrapper: {
    '&:nth-child(odd) > :first-child': {
      float: 'right'
    }
  }
});

const getColorForLevel = (level) => {
  switch (parseInt(level) % 5) {
    case 0:
      return '#FFF305';
    case 1:
      return '#630436';
    case 2:
      return '#00009C';
    case 3:
      return '#228C22';
    case 4:
      return '#FF0000';
    default:
      return '#00009C';
  }
};

const TotemLabeling = ({ labels, keyName, warehouseData }) => {
  const classes = useStyles();
  // eslint-disable-next-line no-unsafe-optional-chaining
  const [zl, al, rl, bl, ll, sl] = labels.label?.split('=');
  return (
    <>
      <Box
        sx={{
          width: 400,
          backgroundColor: getColorForLevel(ll),
          padding: '5px 10px'
        }}
        className={keyName % 2 ? classes.wrapper : null}
      >
        <Box sx={{ backgroundColor: '#fff', width: '50%' }}>
          <QRcode
            payload={JSON.stringify({
              type: 'Sublevel',
              id: labels.sublevel.id,
              warehouseId: warehouseData.id
            })}
            width={100}
            height={100}
          />
        </Box>
        <Box>
          <Box className={classes.gridOuter}>
            <Box sx={{ width: '20%' }} className={classes.separator}>
              <MDTypography
                variant="p"
                component="div"
                sx={{
                  fontSize: '10px',
                  fontWeight: 'bold',
                  lineHeight: '10px'
                }}
              >
                ZONE
              </MDTypography>
              <MDTypography
                variant="p"
                component="div"
                sx={{
                  fontSize: '14px',
                  fontWeight: 'bold',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  margin: '0 5px',
                  height: '25px',
                  background: 'transparent'
                }}
              >
                {zl}
              </MDTypography>
            </Box>
            <Box sx={{ width: '20%' }} className={classes.separator}>
              <MDTypography
                variant="p"
                component="div"
                sx={{
                  fontSize: '10px',
                  fontWeight: 'bold',
                  lineHeight: '10px'
                }}
              >
                AREA
              </MDTypography>
              <MDTypography
                variant="p"
                component="div"
                sx={{
                  fontSize: '14px',
                  fontWeight: 'bold',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  margin: '0 5px',
                  height: '25px',
                  background: 'transparent'
                }}
              >
                {al}
              </MDTypography>
            </Box>
            <Box sx={{ width: '20%' }} className={classes.separator}>
              <MDTypography
                variant="p"
                component="div"
                sx={{
                  fontSize: '10px',
                  fontWeight: 'bold',
                  lineHeight: '10px'
                }}
              >
                RACK
              </MDTypography>
              <MDTypography
                variant="p"
                component="div"
                sx={{
                  fontSize: '14px',
                  fontWeight: 'bold',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  margin: '0 5px',
                  height: '25px',
                  background: 'transparent'
                }}
              >
                {rl}
              </MDTypography>
            </Box>
            <Box sx={{ width: '20%' }} className={classes.separator}>
              <MDTypography
                variant="p"
                component="div"
                sx={{
                  fontSize: '10px',
                  fontWeight: 'bold',
                  lineHeight: '10px'
                }}
              >
                LOCATION
              </MDTypography>
              <MDTypography
                variant="p"
                component="div"
                sx={{
                  fontSize: '14px',
                  fontWeight: 'bold',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  margin: '0 5px',
                  height: '25px',
                  background: 'transparent'
                }}
              >
                {bl}
              </MDTypography>
            </Box>
            <Box sx={{ width: '20%' }} className={classes.separator}>
              <MDTypography
                variant="p"
                component="div"
                sx={{
                  fontSize: '10px',
                  fontWeight: 'bold',
                  lineHeight: '10px'
                }}
              >
                LEVEL
              </MDTypography>
              <MDTypography
                variant="p"
                component="div"
                sx={{
                  fontSize: '14px',
                  fontWeight: 'bold',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  margin: '0 5px',
                  height: '25px',
                  color: '#fff',
                  whiteSpace: 'nowrap',
                  backgroundColor: getColorForLevel(ll)
                }}
              >
                {ll}
              </MDTypography>
            </Box>
            <Box sx={{ width: '20%' }}>
              <MDTypography
                variant="p"
                component="div"
                sx={{
                  fontSize: '10px',
                  fontWeight: 'bold',
                  lineHeight: '10px'
                }}
              >
                POS
              </MDTypography>
              <MDTypography
                variant="p"
                component="div"
                sx={{
                  fontSize: '14px',
                  fontWeight: 'bold',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  margin: '0 5px',
                  height: '25px',
                  background: 'transparent',
                  whiteSpace: 'nowrap'
                }}
              >
                {sl}
              </MDTypography>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

TotemLabeling.propTypes = {
  labels: PropTypes.any.isRequired,
  keyName: PropTypes.number.isRequired,
  warehouseData: PropTypes.any
};

export default TotemLabeling;
