/* eslint-disable indent */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import MDBox from 'components/MDBox';
import DashboardNavbar from 'components/DashboardNavbar';
import DashboardLayout from 'layouts/DashboardLayout';
import { makeStyles } from '@mui/styles';
import { Box, Grid } from '@mui/material';
import MDButton from 'components/Button';
import DateTimeInput from 'components/DateTimePicker';
import MDInput from 'components/MDInput';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { roleIconSlugs } from 'utils/inventorySlugTools';
import IconSelector from 'components/IconSelector';
import { useFormik } from 'formik';
import schema from 'services/ValidationServices';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import RolesActions from 'redux/RolesRedux';
import Breadcrumbs from 'components/Breadcrumbs';
import { WarehouseSelectors } from 'redux/WarehouseRedux';
import { InventorySelectors } from 'redux/InventoryRedux';
import { Toggles } from 'components';
import WarehouseActions from 'redux/WarehouseRedux';
import InventoryActions from 'redux/InventoryRedux';
import { API } from 'constant';
import { useLocation } from 'react-router-dom';
import { ACCESS_CONTROL } from 'constant';

const useStyles = makeStyles(() => ({
  inputLabel: {
    fontSize: '16px',
    letterSpacing: '0.01em',
    color: '#000',
    marginBottom: '4px'
  },
  fullWidth: {
    width: '100%',
    borderColor: '#d2d6da',
    borderRadius: '0.375rem'
  }
}));

function CreateEditUserRole({ context }) {
  const classes = useStyles();
  const location = useLocation();
  const roleData = location?.state?.role;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const warehouses = useSelector(WarehouseSelectors.getWarehouseDetail);
  const inventories = useSelector(InventorySelectors.getInventoryDetail);
  // const actions = useSelector(PermissionsSelectors.getActionsDetail);
  // const visibilities = useSelector(PermissionsSelectors.getPermissionsDetail);

  useEffect(() => {
    if (context === 'edit' && !roleData) navigate('/setup/users-access');

    (!warehouses || warehouses.length === 0) &&
      dispatch(
        WarehouseActions.warehouseDataAction({
          loader: 'loading-request',
          slug: API.GET_WAREHOUSE_DATA,
          method: 'get'
        })
      );
    (!inventories || inventories.length === 0) &&
      dispatch(
        InventoryActions.getInventoryAction({
          loader: 'loading-request',
          slug: API.GET_INVENTORY,
          method: 'get'
        })
      );
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues:
      context === 'edit'
        ? {
            name: roleData?.name || '',
            description: roleData?.description || '',
            permissions: roleData?.permissions || [],
            allowedUIModules: roleData?.allowedUIModules || [],
            icon_slug: roleData?.icon_slug || ''
          }
        : {
            name: '',
            description: '',
            permissions: [],
            allowedUIModules: [],
            icon_slug: ''
          },
    validationSchema: schema.createRole,
    onSubmit: (values, { setSubmitting }) => {
      const onValidationFailed = () => {
        setSubmitting(false);
      };
      const onSuccessfulSubmission = () => {
        navigate('/setup/users-access');
      };
      const adaptPayload = (values) => {
        let valuesClone = {};
        valuesClone.name = values.name;
        valuesClone.description = values.description;
        valuesClone.icon_slug = values.icon_slug;
        valuesClone.permissions = values.permissions ? values.permissions : [];
        valuesClone.allowedUIModules = values.allowedUIModules ? values.allowedUIModules : [];
        valuesClone.status = true;
        return valuesClone;
      };

      dispatch(
        RolesActions.createRoleAction({
          loader: 'loading-request',
          slug: context === 'edit' ? '/user-role/' + roleData._id : '/user-role/create',
          method: 'post',
          data: adaptPayload(values),
          type: context,
          onValidationFailed,
          onSuccessfulSubmission
        })
      );
    }
  });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Breadcrumbs
        title="Role Details"
        route={[
          { name: 'Home', path: '/home' },
          { name: 'Setup', path: '/setup' },
          { name: 'User Access', path: '/setup/users-access' },
          { name: 'Role Details' }
        ]}
      />
      <MDBox
        px={5}
        py={3}
        sx={{ backgroundColor: '#fff' }}
        className={formik.isSubmitting ? 'loader' : null}
      >
        <Grid container spacing={2}>
          <Grid item container xs={12} spacing={2}>
            <Grid item container xs={6}>
              <Grid item xs={12}>
                <Box component="div" sx={{}} className={classes.inputLabel}>
                  Role name
                </Box>
                <MDInput
                  fullWidth
                  name="name"
                  type="text"
                  variant="outlined"
                  value={formik.values.name}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={
                    formik.touched.name &&
                    formik.errors.name && <div style={{ color: 'red' }}>{formik.errors.name}</div>
                  }
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <Box component="div" className={classes.inputLabel}>
                  Description
                </Box>
                <TextareaAutosize
                  className={classes.fullWidth}
                  minRows={3}
                  name="description"
                  value={formik.values.description}
                  error={formik.touched.description && Boolean(formik.errors.description)}
                  helperText={
                    formik.touched.description &&
                    formik.errors.description && (
                      <div style={{ color: 'red' }}>{formik.errors.description}</div>
                    )
                  }
                  sx={{
                    width: '100%',
                    borderColor: '#d2d6da',
                    borderRadius: '0.375rem'
                  }}
                  onChange={formik.handleChange}
                />
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Box component="div" className={classes.inputLabel}>
                Choose an avatar for the role
              </Box>
              <IconSelector
                iconList={roleIconSlugs}
                name="icon_slug"
                value={formik.values.icon_slug}
                onChange={formik.handleChange}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {context === 'edit' && roleData && (
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Box component="div" className={classes.inputLabel}>
                    Created By
                  </Box>
                  <MDInput
                    disabled
                    fullWidth
                    value={roleData?.createdBy?.fullName}
                    type="text"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <Box component="div" className={classes.inputLabel}>
                    Date &amp; Time
                  </Box>
                  <DateTimeInput disabled value={roleData?.createdAt} />
                </Grid>
                <Grid item xs={6}>
                  <Box component="div" className={classes.inputLabel}>
                    Last Updated by
                  </Box>
                  <MDInput
                    disabled
                    fullWidth
                    value={roleData?.updatedBy?.fullName}
                    type="text"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <Box component="div" className={classes.inputLabel}>
                    Date &amp; Time
                  </Box>
                  <DateTimeInput disabled value={roleData?.updatedAt} />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ marginTop: '12px' }}>
          <Toggles
            name="permissions"
            title="Actions"
            toggles={ACCESS_CONTROL.permissions}
            selectedToggles={formik.values.permissions.join(',')}
            onChange={(val) => {
              let allKeys = Object.keys(val);
              val = allKeys.filter((key) => val[key] && key);
              formik.setFieldValue('permissions', val);
            }}
          />
          <Toggles
            name="allowedUIModules"
            title="Application"
            toggles={ACCESS_CONTROL.allowedUIModules}
            selectedToggles={formik.values.allowedUIModules.join(',')}
            onChange={(val) => {
              let allKeys = Object.keys(val);
              val = allKeys.filter((key) => val[key] && key);
              formik.setFieldValue('allowedUIModules', val);
            }}
          />
        </Grid>
        <MDBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          lineHeight={1}
          sx={{ marginBottom: '15px', marginTop: '24px' }}
        >
          <MDButton
            size="medium"
            color="primary"
            variant="outlined"
            type="submit"
            sx={{ marginRight: '15px' }}
            onClick={() => {
              navigate('/setup/users-access');
            }}
          >
            Cancel
          </MDButton>
          <MDButton
            size="medium"
            color="primary"
            variant="contained"
            disabled={formik.isSubmitting}
            onClick={formik.handleSubmit}
          >
            Save
          </MDButton>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

CreateEditUserRole.propTypes = {
  context: PropTypes.any
};

export default CreateEditUserRole;
