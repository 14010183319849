/* eslint-disable complexity */
import React, { useState } from 'react';
import { Box, FormControl, FormHelperText, Grid, MenuItem, Select } from '@mui/material';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import MDButton from 'components/Button';
import MDInput from 'components/MDInput';
import { makeStyles } from '@mui/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import CrossIcon from 'assets/images/CrossIcon';
// import PickupScanGrid from 'components/PickUpScan';
import PprActions from 'redux/PprRedux';
import { useDispatch } from 'react-redux';
import schema from 'services/ValidationServices';
import { API } from 'constant';

const useStyles = makeStyles(() => ({
  inputLabel: {
    fontSize: '16px',
    letterSpacing: '0.01em',
    color: '#000',
    marginBottom: '4px',
    fontWeight: 'normal',
    '& .MuiFormControlLabel-label': {
      fontWeight: 'normal',
      fontSize: '16px',
      color: '#000'
    }
  },
  centreContent: {
    justifyContent: 'center'
  },
  fullWidth: {
    width: '100%',
    borderColor: '#d2d6da',
    borderRadius: '0.375rem'
  },
  pickupBtn: {
    '&:hover': {
      backgroundColor: '#ff9330',
      color: '#fff'
    }
  },
  cursorPointer: {
    cursor: 'pointer',
    display: 'flex',
    alignSelf: 'end',
    margin: '0'
  },
  fontNormal: {
    fontWeight: 'normal',
    '& .MuiInputLabel-root': {
      fontWeight: 'normal',
      fontSize: '16px',
      letterSpacing: '0.01em',
      color: '#000',
      marginBottom: '4px',
      paddingTop: '16px',
      lineHeight: '26px',
      paddingBottom: '0'
    }
  }
}));

function ScanReserve({ show, setShow, inventoryItem }) {
  const classes = useStyles();
  const [inventoryData, setInventoryData] = useState(null);
  const [locationList, setLocationList] = useState(null);

  const dispatch = useDispatch();
  React.useEffect(() => {
    const { locationList } = inventoryItem;
    const warehouseData = Object.keys(locationList).map((key) => {
      return locationList?.[key]?.[0]?.warehouse;
    });
    setInventoryData(inventoryItem.inventoryData);
    setLocationList(warehouseData);
  }, []);

  const handleWarehouse = (e) => {
    const { _id, name } = e.target.value;
    const quantity = inventoryItem.locationList[name].reduce((acc, loc) => {
      return acc + loc.quantity;
    }, 0);
    formik.setValues({ ...formik.values, warehouseId: _id, totalQuan: quantity });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      widgetname: inventoryData?.widgetName,
      totalQuan: inventoryData?.availQuan,
      type: inventoryData?.type,
      size: inventoryData?.size,
      color: inventoryData?.color,
      reservequan: '',
      job: '',
      pickdate: '',
      reservationreason: ''
    },
    validationSchema: schema.reserve,
    onSubmit: (values) => {
      dispatch(
        PprActions.pickAction({
          loader: 'loading-request',
          slug: `${API.RESERVE.replace(':id', inventoryData.inventoryId)}`,
          method: 'post',
          data: {
            reserveQuantity: Number(values.reservequan),
            job: values.job,
            pickupDate: new Date(values.pickdate).toISOString(),
            usageReason: values.reservationreason,
            warehouseId: values.warehouseId
          },
          callback: () => {
            formik.resetForm();
            setShow();
          }
        })
      );
    }
  });

  return (
    <div>
      <Dialog open={show} maxWidth="xl" onClose={setShow}>
        <MDButton
          className={classes.cursorPointer}
          sx={{ padding: '15px', minWidth: '14px' }}
          onClick={setShow}
        >
          <CrossIcon />
        </MDButton>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent style={{ padding: '20px 47px' }}>
            <Grid container spacing={5}>
              <Grid item xs={12} md={6}>
                <Box component="div" sx={{}} className={classes.inputLabel}>
                  Widget Name
                </Box>
                <MDInput
                  disabled
                  fullWidth
                  name="widgetname"
                  type="text"
                  variant="outlined"
                  value={formik.values.widgetname}
                  error={formik.touched.widgetname && Boolean(formik.errors.widgetname)}
                  helperText={
                    formik.touched.widgetname &&
                    formik.errors.widgetname && (
                      <div style={{ color: 'red' }}>{formik.errors.commonName}</div>
                    )
                  }
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Box component="div" sx={{}} className={classes.inputLabel}>
                  Total Available
                </Box>
                <MDInput
                  disabled
                  fullWidth
                  name="totalQuan"
                  type="text"
                  variant="outlined"
                  value={formik.values.totalQuan}
                  error={formik.touched.totalQuan && Boolean(formik.errors.totalQuan)}
                  helperText={
                    formik.touched.totalQuan &&
                    formik.errors.totalQuan && (
                      <div style={{ color: 'red' }}>{formik.errors.totalQuan}</div>
                    )
                  }
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Box component="div" sx={{}} className={classes.inputLabel}>
                  Type
                </Box>
                <MDInput
                  disabled
                  fullWidth
                  name="type"
                  type="text"
                  variant="outlined"
                  value={formik.values.type}
                  error={formik.touched.type && Boolean(formik.errors.type)}
                  helperText={
                    formik.touched.type &&
                    formik.errors.type && <div style={{ color: 'red' }}>{formik.errors.type}</div>
                  }
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Box component="div" sx={{}} className={classes.inputLabel}>
                  Ouantity To Reserve
                </Box>
                <MDInput
                  fullWidth
                  name="reservequan"
                  type="text"
                  variant="outlined"
                  value={formik.values.reservequan}
                  error={formik.touched.reservequan && Boolean(formik.errors.reservequan)}
                  helperText={
                    formik.touched.reservequan &&
                    formik.errors.reservequan && (
                      <div style={{ color: 'red' }}>{formik.errors.reservequan}</div>
                    )
                  }
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Box component="div" sx={{}} className={classes.inputLabel}>
                  Size
                </Box>
                <MDInput
                  disabled
                  fullWidth
                  name="size"
                  type="text"
                  variant="outlined"
                  value={formik.values.size}
                  error={formik.touched.size && Boolean(formik.errors.size)}
                  helperText={
                    formik.touched.size &&
                    formik.errors.size && <div style={{ color: 'red' }}>{formik.errors.size}</div>
                  }
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Box component="div" sx={{}} className={classes.inputLabel}>
                  Job #/ Reference
                </Box>
                <MDInput
                  fullWidth
                  name="job"
                  type="text"
                  variant="outlined"
                  value={formik.values.job}
                  error={formik.touched.job && Boolean(formik.errors.job)}
                  helperText={
                    formik.touched.job &&
                    formik.errors.job && <div style={{ color: 'red' }}>{formik.errors.job}</div>
                  }
                  onChange={formik.handleChange}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Box component="div" sx={{}} className={classes.inputLabel}>
                  Color
                </Box>
                <MDInput
                  disabled
                  fullWidth
                  name="color"
                  type="text"
                  variant="outlined"
                  value={formik.values.color}
                  error={formik.touched.color && Boolean(formik.errors.color)}
                  helperText={
                    formik.touched.color &&
                    formik.errors.color && <div style={{ color: 'red' }}>{formik.errors.color}</div>
                  }
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Box component="div" sx={{}} className={classes.inputLabel}>
                  Pick By Date
                </Box>
                <MDInput
                  fullWidth
                  name="pickdate"
                  type="date"
                  variant="outlined"
                  value={formik.values.pickdate}
                  error={formik.touched.pickdate && Boolean(formik.errors.pickdate)}
                  helperText={
                    formik.touched.pickdate &&
                    formik.errors.pickdate && (
                      <div style={{ color: 'red' }}>{formik.errors.pickdate}</div>
                    )
                  }
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Box component="div" sx={{}} className={classes.inputLabel}>
                  Select Warehouse
                </Box>
                <FormControl fullWidth>
                  <Select
                    name="warehouseId"
                    value={formik.values.warehouseId}
                    label="Select Warehouse"
                    inputProps={{ 'aria-label': 'Without label' }}
                    onChange={handleWarehouse}
                  >
                    {locationList &&
                      locationList.map((data) => (
                        <MenuItem value={data} key={data?._id}>
                          {data?.name}
                        </MenuItem>
                      ))}
                  </Select>
                  <FormHelperText>
                    {formik.touched.warehouseId && formik.errors.warehouseId && (
                      <div style={{ color: 'red' }}>{formik.errors.warehouseId}</div>
                    )}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box component="div" sx={{}} className={classes.inputLabel}>
                  Reservation Reason
                </Box>
                <MDInput
                  fullWidth
                  name="reservationreason"
                  type="text"
                  variant="outlined"
                  as="textarea"
                  value={formik.values.reservationreason}
                  error={
                    formik.touched.reservationreason && Boolean(formik.errors.reservationreason)
                  }
                  helperText={
                    formik.touched.reservationreason &&
                    formik.errors.reservationreason && (
                      <div style={{ color: 'red' }}>{formik.errors.reservationreason}</div>
                    )
                  }
                  className={classes.fullWidth}
                  minRows="4"
                  onChange={formik.handleChange}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className={classes.centreContent}>
            <MDButton
              size="medium"
              type="submit"
              sx={{ backgroundColor: '#ff9330', color: '#fff' }}
              className={classes.pickupBtn}
            >
              COMPLETE RESERVATION
            </MDButton>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
export default ScanReserve;

ScanReserve.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.func,
  inventoryItem: PropTypes.shape({
    inventoryData: PropTypes.shape({
      inventoryId: PropTypes.string.isRequired,
      widgetName: PropTypes.string.isRequired,
      availQuan: PropTypes.number.isRequired,
      type: PropTypes.string.isRequired,
      size: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired
    }),
    locationList: PropTypes.any.isRequired
  }).isRequired
};
