/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 PRO React base styles
import borders from 'assets/theme-dark/base/borders';

const { borderRadius } = borders;

export default {
  styleOverrides: {
    root: {
      transition: 'all 200ms ease-in-out'
    },

    rounded: {
      borderRadius: borderRadius.lg
    },

    img: {
      height: 'auto'
    }
  }
};
