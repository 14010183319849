import PropTypes from 'prop-types';
import MDBox from 'components/MDBox';
import DashboardNavbar from 'components/DashboardNavbar';
import DashboardLayout from 'layouts/DashboardLayout';
import { makeStyles } from '@mui/styles';
import Dropdown from 'components/Dropdown';
import { Box, Checkbox, Grid, TableBody, TableCell, TableRow } from '@mui/material';
import MDButton from 'components/Button';
import Breadcrumbs from 'components/Breadcrumbs';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { LabellingSelectors } from 'redux/LabellingRedux';
import { WarehouseSelectors } from 'redux/WarehouseRedux';
import { WarehouseLocationsSelectors } from 'redux/WarehouseLocationsRedux';
import WarehouseActions from 'redux/WarehouseRedux';
import { API } from 'constant';
import WarehouseLocationsActions from 'redux/WarehouseLocationsRedux';
import LabellingActions from 'redux/LabellingRedux';
import BasicTable from 'components/BasicTable';
import PrintLocationLabel from 'components/PrintLocationLabel';
// import ReactToPrint from 'react-to-print';
import MDTypography from 'components/MDTypography';
// import h2c from 'html2canvas';
import { jsPDF } from 'jspdf';
import domtoimage from 'dom-to-image-more';

const DEFAULT_PDF_MARGIN = 10;

const useStyles = makeStyles({
  size: {
    fontWeight: '500'
  },
  nodataStyle: {
    height: '200px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '300%'
  },
  iconSize: {
    width: '50%',
    height: '50%',
    marginBottom: '10px'
  },
  margin: {
    margin: '20px'
  },
  buttondiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '200px'
  }
});

export const ComponentToPrint = React.forwardRef((props, ref) => {
  return <div ref={ref}>{props.children}</div>;
});

ComponentToPrint.propTypes = {
  children: PropTypes.any
};

function LabelingScreen() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [warehouseId, setWarehouseId] = useState('');
  const [zoneId, setZoneId] = useState('');
  const [areaId, setAreaId] = useState('');
  const [rowId, setRowId] = useState('');
  const [totemMap, setTotemMap] = useState({});
  const [PrintLocLabel, setPrintLocLabel] = useState(false);

  const warehouseData = useSelector(WarehouseSelectors.getWarehouseDetail);
  const zonedata = useSelector(WarehouseLocationsSelectors.getChildrenOfParent(warehouseId));
  const areadata = useSelector(WarehouseLocationsSelectors.getChildrenOfParent(zoneId));
  const rowdata = useSelector(WarehouseLocationsSelectors.getChildrenOfParent(areaId));
  const labelData = useSelector(LabellingSelectors.getLabelDetail);
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  const headCells = [
    {
      id: 'warehouse',
      label: 'Warehouse'
    },
    {
      id: 'zone',
      label: 'Zone'
    },
    {
      id: 'area',
      label: 'Area'
    },
    {
      id: 'row',
      label: 'Rack'
    },
    {
      id: 'bay',
      label: 'Location'
    }
  ];

  useEffect(() => {
    warehouseData?.length < 1 &&
      dispatch(
        WarehouseActions.warehouseDataAction({
          loader: 'loading-request',
          slug: API.GET_WAREHOUSE_DATA,
          method: 'get'
        })
      );
  }, []);

  const warehouseChange = (event) => {
    const id = event.target.value;
    const type = 'warehouse';
    setWarehouseId(id);
    setZoneId('');
    setAreaId('');
    setTotemMap({});
    dispatch(LabellingActions.clearLabelAction({}));
    dispatch(
      WarehouseLocationsActions.locationRequest({
        loader: 'loading-request',
        slug: API.GET_CHILDREN_FROM_PARENT,
        method: 'post',
        data: { id, type }
      })
    );
  };

  const zoneChange = (event) => {
    const id = event.target.value;
    const type = 'zone';
    setZoneId(id);
    setAreaId('');
    setRowId('');
    setTotemMap({});
    dispatch(LabellingActions.clearLabelAction({}));
    dispatch(
      WarehouseLocationsActions.locationRequest({
        loader: 'loading-request',
        slug: API.GET_CHILDREN_FROM_PARENT,
        method: 'post',
        data: { id, type }
      })
    );
  };

  const areaChange = (event) => {
    const id = event.target.value;
    const type = 'area';
    setAreaId(id);
    setRowId('');
    setTotemMap({});
    dispatch(LabellingActions.clearLabelAction({}));
    dispatch(
      WarehouseLocationsActions.locationRequest({
        loader: 'loading-request',
        slug: API.GET_CHILDREN_FROM_PARENT,
        method: 'post',
        data: { id, type }
      })
    );
  };

  const rowChange = (event) => {
    const id = event.target.value;
    // const type = 'row';
    setRowId(id);
    setTotemMap({});
    // dispatch(
    //   WarehouseLocationsActions.locationRequest({
    //     loader: 'loading-request',
    //     slug: API.GET_CHILDREN_FROM_PARENT,
    //     method: 'post',
    //     data: { id, type }
    //   })
    // );
    dispatch(LabellingActions.clearLabelAction({}));
    dispatch(
      LabellingActions.getLabelAction({
        loader: 'labelling-request',
        slug: API.GET_LABEL,
        method: 'post',
        data: {
          warehouse: warehouseId,
          zone: zoneId,
          area: areaId,
          row: id
        }
      })
    );
  };

  const getTableItem = (e, item) => {
    setPrintLocLabel(false);
    setTotemMap((prev) => {
      if (prev[item.bay.id]) {
        const cloneState = { ...prev };
        delete cloneState[item.bay.id];
        return cloneState;
      }
      return {
        ...prev,
        [item.bay.id]: item
      };
    });
  };

  const exportToPdf = async () => {
    var pdf = new jsPDF('p', 'px', 'a4');
    pdf.setFontSize(12);

    const promiseArray = [];
    const totemLabels = [];
    let totemLabelIndex = 0;
    Object.keys(totemMap).forEach((key) => {
      let elem = document.getElementById(`view-totem-${key}`);
      elem.childNodes.forEach((childElement) => {
        if (childElement.id.startsWith('totem-label')) {
          totemLabels.push(childElement.innerText);
        } else {
          promiseArray.push(domtoimage.toPng(childElement));
        }
      });
      promiseArray.push(null);
    });

    promiseArray.pop();

    Promise.all(promiseArray).then((data) => {
      const textDim = pdf.getTextDimensions(totemLabels[totemLabelIndex], { fontSize: 12 });
      const textX = (pdf.internal.pageSize.width - textDim.w) / 2;
      const textY = DEFAULT_PDF_MARGIN + textDim.h;
      pdf.text(totemLabels[0], textX, textY);
      const labelHeight = 50;
      const labelWidth = (pdf.internal.pageSize.width - DEFAULT_PDF_MARGIN * 2) * 0.8;

      let remainingHeight = pdf.internal.pageSize.height - DEFAULT_PDF_MARGIN * 2;
      let labelY = DEFAULT_PDF_MARGIN * 2 + textDim.h;
      const labelX = (pdf.internal.pageSize.width - labelWidth) / 2;
      data.forEach((pngData) => {
        if (!pngData || remainingHeight - labelHeight < 0) {
          !pngData && totemLabelIndex++;
          pdf.addPage();
          pdf.text(totemLabels[totemLabelIndex], textX, textY);
          labelY = textY + DEFAULT_PDF_MARGIN;
          remainingHeight = pdf.internal.pageSize.height - DEFAULT_PDF_MARGIN * 2 - textY;
        }
        if (pngData) {
          pdf.addImage(pngData, 'png', labelX, labelY, labelWidth, labelHeight);
          labelY += labelHeight;
          remainingHeight -= labelHeight;
        }
      });
      pdf.save('export.pdf');
    });
  };

  // const inputRef = useRef(null);
  // const printDocument = () => {
  //   html2canvas(inputRef.current).then((canvas) => {
  //     const imgData = canvas.toDataURL('image/jpeg');
  //     const pdf = new jsPDF('p', 'pt', 'a4');
  //     pdf.addImage(imgData, 'JPEG', 0, 0);
  //     pdf.addPage();
  //     pdf.addImage(imgData, 'JPEG', 0, 0);
  //     pdf.addPage();
  //     pdf.addImage(imgData, 'JPEG', 0, 0);

  //     pdf.save('test.pdf');
  //   });
  // };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Breadcrumbs
        title="Search And Print Location Labels"
        route={[
          { name: 'Home', path: '/home' },
          { name: 'Setup', path: '/setup' },
          { name: 'Labeling', path: '/setup/labeling' },
          { name: 'Location Label' }
        ]}
      />
      <MDBox px={5} py={5}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <Dropdown
              label="Select warehouse"
              dropdownData={warehouseData}
              onChange={warehouseChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Dropdown dropdownData={zonedata} label="Select Zone" onChange={zoneChange} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Dropdown dropdownData={areadata} label="Select Area" onChange={areaChange} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Dropdown dropdownData={rowdata} label="Select Rack" onChange={rowChange} />
          </Grid>
        </Grid>
        <br />
        <MDTypography variant="h4" className={classes.size}>
          Select Rows To Print
        </MDTypography>
        <div style={{ marginTop: '20px', marginBottom: '20px' }}>
          <BasicTable
            headCells={headCells}
            records={labelData}
            backgroundColor="#E5E5E5"
            color="#8D8D8D"
          >
            <TableBody>
              {rowId ? (
                labelData &&
                labelData.map((item) => (
                  <TableRow key={item._id}>
                    <TableCell>
                      <Checkbox
                        {...label}
                        sx={{ marginRight: '2px' }}
                        checked={!!totemMap[item?.bay?.id]}
                        onChange={(e) => getTableItem(e, item)}
                      />
                      {item?.warehouse?.name}
                    </TableCell>
                    <TableCell>{item?.zone?.name}</TableCell>
                    <TableCell>{item?.area?.name}</TableCell>
                    <TableCell>{item?.row?.name}</TableCell>
                    <TableCell>{item?.bay?.name}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow className={classes.nodataStyle}>No Data</TableRow>
              )}
            </TableBody>
          </BasicTable>
        </div>
        <MDTypography variant="h4" className={classes.size}>
          Rows Selected For Printing
        </MDTypography>
        <Grid container spacing={4} py={5}>
          <Grid item xs={12} sm={6} md={6}>
            <Box sx={{ backgroundColor: '#E5E5E5', padding: '3px 12px', color: '#8D8D8D' }}>
              Bay Totem Labels
            </Box>
            <Box
              sx={{
                border: '1px solid #EBEBEB',
                padding: '3px 12px',
                overflowY: 'auto',
                height: '300px'
              }}
            >
              {Object.keys(totemMap).map((key) => {
                const item = totemMap[key];
                return (
                  <div style={{ display: 'flex', flexDirection: 'column' }} key={key}>
                    {item.totem_label && item.totem_label.map((label) => label.replace(/=/g, '-'))}
                  </div>
                );
              })}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Box sx={{ backgroundColor: '#E5E5E5', padding: '3px 12px', color: '#8D8D8D' }}>
              Bin Location Labels
            </Box>
            <Box
              sx={{
                border: '1px solid #EBEBEB',
                padding: '3px 12px',
                height: '300px',
                overflowY: 'auto'
              }}
            >
              {Object.keys(totemMap).map((key) => {
                const locationData = totemMap[key].location_data;
                return (
                  <div style={{ display: 'flex', flexDirection: 'column' }} key={key}>
                    {locationData.map((locationLabel, key) => {
                      return (
                        <div style={{ display: 'flex', flexDirection: 'column' }} key={key}>
                          {locationLabel.label.replace(/=/g, '-')}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </Box>
          </Grid>
        </Grid>
        <div className={classes.buttondiv}>
          <MDButton
            sx={{ marginRight: '10px' }}
            color="primary"
            onClick={() => setPrintLocLabel(true)}
          >
            {'View Labels'}
          </MDButton>

          <MDButton color="primary" disabled={!PrintLocLabel} onClick={exportToPdf}>
            {'Print PDF'}
          </MDButton>
        </div>
        {PrintLocLabel ? (
          <>
            {/* <Paper
              sx={{
                // backgroundColor: '#f5f5f5',
                minHeight: '297mm',
                marginLeft: 'auto',
                marginRight: 'auto'
                // display: 'flex',
                // alignItems: 'center',
                // justifyContent: 'center',
                // padding: '90px 65px'
              }}
              id="divToPrint"
              // ref={inputRef}
            > */}
            <PrintLocationLabel totemMap={totemMap} />
            {/* </Paper> */}
          </>
        ) : (
          ''
        )}
      </MDBox>
    </DashboardLayout>
  );
}
export default LabelingScreen;
