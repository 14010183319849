import React, { useEffect, useState } from 'react';
import MDBox from 'components/MDBox';
import PropTypes from 'prop-types';
import UploadIcon from 'assets/images/UploadIcon';
import MDTypography from 'components/MDTypography';
import pxToRem from 'assets/theme-dark/functions/pxToRem';
import { Button } from '@mui/material';
import Close from 'assets/images/Close';
import Carousel from 'react-material-ui-carousel';

function ImageUpload({ heading, accept, multiple, images, setImages, isCarousel }) {
  const [btnSmall, setBtnSmall] = useState(false);

  useEffect(() => {
    if (images.length > 0) {
      setBtnSmall(true);
    } else {
      setBtnSmall(false);
    }
  }, [btnSmall, images]);

  const addImage = (e) => {
    setImages([
      ...images,
      { src: URL.createObjectURL(e.target.files[0]), file: e.target.files[0] }
    ]);
  };

  const removeImage = (index) => {
    setImages(images.filter((_val, idx) => idx !== index));
  };

  const [index, setIndex] = React.useState(0);

  const imagePreview = (images) => {
    let renderUploadImages = images.map((item, idx) => {
      return (
        <MDBox
          key={idx}
          component="span"
          sx={{
            width: isCarousel ? '100%' : '80px',
            height: isCarousel ? '250px' : '63px',
            marginRight: '16px',
            display: 'flex',
            justifyContent: 'center',
            borderRadius: '4px',
            position: 'relative',
            padding: isCarousel ? '10px' : '0'
          }}
        >
          <Button
            sx={{
              backgroundColor: '#fff !important',
              boxShadow: '0px 1px 1px rgb(0 0 0 / 25%)',
              padding: '0',
              minWidth: '20px',
              zIndex: '500',
              minHeight: '20px',
              borderRadius: '100%',
              position: 'absolute',
              top: isCarousel ? '20px' : '4px',
              right: isCarousel ? '20px' : '4px',
              '&:hover': {
                backgroundColor: 'red !important'
              }
            }}
            onClick={() => {
              removeImage(idx);
            }}
          >
            <Close />
          </Button>
          <img src={item.src} alt="placeholder" height="250px" />
        </MDBox>
      );
    });
    return renderUploadImages;
  };
  return (
    <>
      <MDBox
        sx={{
          border: btnSmall ? '0px' : '1px solid #c4c4c4',
          borderRadius: pxToRem(8)
        }}
      >
        <MDBox
          sx={{
            border: btnSmall ? '1px solid #C4C4C4' : '1px dashed #C4C4C4',
            borderRadius: pxToRem(6),
            cursor: 'pointer',
            position: 'relative',
            textAlign: 'center',
            minHeight: btnSmall ? '20px' : pxToRem(200),
            display: btnSmall ? 'inline-block' : 'flex',
            float: btnSmall ? 'right' : 'none',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: '16px'
          }}
        >
          <MDBox
            component="input"
            name="file"
            disabled={!multiple && images.length}
            accept={accept}
            type="file"
            sx={{
              width: '100%',
              opacity: '0',
              cursor: 'pointer',
              position: 'absolute',
              top: '0',
              left: '0',
              right: '0',
              bottom: '0'
            }}
            onChange={addImage}
          />
          {btnSmall ? (
            <MDBox component="span">
              <MDTypography
                component="span"
                sx={{
                  color: '#000',
                  letterSpacing: '0.01em',
                  display: 'block',
                  padding: '10px',
                  cursor: 'pointer'
                }}
              >
                {!multiple && images.length ? 'Cannot add more images' : '+ Add More'}
              </MDTypography>
            </MDBox>
          ) : (
            <MDBox component="span">
              {!multiple && images.length ? null : <UploadIcon />}
              <MDTypography
                component="span"
                sx={{ color: '#000', letterSpacing: '0.01em', display: 'block' }}
              >
                {!multiple && images.length ? 'Cannot add more images' : heading}
              </MDTypography>
            </MDBox>
          )}
        </MDBox>
        {/* -----------img-preview-----`------ */}
        <MDBox
          sx={{
            clear: 'both',
            border: '1px solid #c4c4c4'
          }}
        >
          {isCarousel && images.length && images ? (
            <Carousel
              indicators
              navButtonsAlwaysVisible
              stopAutoPlayOnHover
              swipe
              cycleNavigation
              index={index}
              interval={4000}
              animation="slide"
              navButtonsWrapperProps={{
                style: {
                  height: 'auto',
                  top: 'calc(50% - 23px)',
                  margin: '0 10px'
                }
              }}
              onChange={(cur) => {
                setIndex(cur);
              }}
            >
              {imagePreview(images)}
            </Carousel>
          ) : (
            <> {imagePreview(images)} </>
          )}
        </MDBox>
      </MDBox>
    </>
  );
}

export default ImageUpload;
ImageUpload.propTypes = {
  images: PropTypes.array,
  heading: PropTypes.string,
  multiple: PropTypes.bool,
  accept: PropTypes.string,
  setImages: PropTypes.func,
  isCarousel: PropTypes.bool,
  btnSmall: PropTypes.bool
};
