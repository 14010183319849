/* eslint-disable indent */
import moment from 'moment';
import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Grid, Tabs, Tab } from '@mui/material';
import { makeStyles } from '@mui/styles';
import MDBox from 'components/MDBox';

import DashboardNavbar from 'components/DashboardNavbar';
import DashboardLayout from 'layouts/DashboardLayout';
import SearchBar from 'components/SearchBar';
import MDButton from 'components/Button';
import Breadcrumbs from 'components/Breadcrumbs';
import { PwTablePanel } from 'components';

import WarehouseActions, { WarehouseSelectors } from 'redux/WarehouseRedux';
import InventoryActions, { InventorySelectors } from 'redux/InventoryRedux';
import UsersActions, { UsersSelectors } from 'redux/UsersRedux';
import RolesActions, { RolesSelectors } from 'redux/RolesRedux';

import { API } from 'constant';
import { AuthSelectors } from 'redux/AuthRedux';

const useStyles = makeStyles((theme) => ({
  iconSize: {
    width: '1.5rem',
    height: '1.5rem',
    color: theme.palette.primary.light,
    marginRight: '8px'
  },
  statusActive: {
    color: 'green'
  },
  statusInactive: {
    color: 'red'
  },
  limitWidth: {
    maxWidth: '20rem'
  },
  margin: {
    marginBottom: '20px',
    borderTop: '1px solid #ddd',
    borderBottom: '1px solid #ddd'
  },
  wrap: {
    display: 'flex'
  },
  marginTable: {
    marginTop: '20px'
  },
  iconwrap: {
    display: 'flex',
    alignItems: 'center'
  },
  radialBorder: {
    overflow: 'hidden',
    borderRadius: '0.5rem',
    overflowX: 'auto'
  },
  rightPlaced: {
    position: 'absolute',
    right: 0
  },
  tabs: {
    borderRadius: 0,
    '& .MuiButtonBase-root.MuiTab-root': {
      padding: '12px 0px',
      borderRadius: '0px',
      fontWeight: 'bold',
      backgroundColor: '#eee',
      border: '1px solid #ddd'
    },
    '& .MuiButtonBase-root.MuiTab-root.Mui-selected': {
      backgroundColor: '#017AFF',
      color: 'white !important'
    }
  }
}));

function UserAccessScreen() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const usersData = useSelector(UsersSelectors.getUsersDetail);
  const rolesData = useSelector(RolesSelectors.getRolesDetail);
  const warehouses = useSelector(WarehouseSelectors.getWarehouseDetail);
  const inventories = useSelector(InventorySelectors.getInventoryDetail);
  const canCreateUsers = useSelector(AuthSelectors.hasPermission('Modify::Users'));

  const [userRecords, setUserRecords] = useState([]);
  const [rolesRecords, setRoleRecords] = useState([]);
  const [userLoader, setUserLoader] = useState(false);
  const [roleLoader, setRoleLoader] = useState(false);
  const [roleApiCompleted, setRoleApiCompleted] = useState(false);
  const [userApiCompleted, setUserApiCompleted] = useState(false);
  const [originalUserRecords, setOriginalUserRecords] = useState([]);
  const [originalRolesRecords, setOriginalRoleRecords] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    (!warehouses || warehouses.length === 0) &&
      dispatch(
        WarehouseActions.warehouseDataAction({
          loader: 'loading-request',
          slug: API.GET_WAREHOUSE_DATA,
          method: 'get'
        })
      );
    (!inventories || inventories.length === 0) &&
      dispatch(
        InventoryActions.getInventoryAction({
          loader: 'loading-request',
          slug: API.GET_INVENTORY,
          method: 'get'
        })
      );
  }, []);

  const userHeadCells = [
    { id: 'full_name', label: 'User Name', isEditAnchor: true, value: (record) => record.fullName },
    { id: 'phone_number', label: 'Phone Number', value: (record) => record.phoneNumber },
    { id: 'role_name', label: 'Roles', value: (record) => record.role_name },
    {
      id: 'updated_by_at',
      label: 'Last Updated By & Date',
      value: (record) =>
        `${record.updatedBy?.fullName ? record.updatedBy.fullName + ' | ' : ''}${moment(
          record.updatedAt
        ).format('D/M/YYYY h:m:s A')}`
    },
    {
      id: 'created_by_at',
      label: 'Created By & Date',
      value: (record) =>
        `${record.createdBy?.fullName ? record.createdBy.fullName + ' | ' : ''}${moment(
          record.createdAt
        ).format('D/M/YYYY h:m:s A')}`
    },
    {
      id: 'last_login',
      label: 'Last Login',
      value: (record) =>
        record.lastLogin ? moment(record.lastLogin).format('D/M/YYYY h:m:s A') : ''
    },
    {
      id: 'is_active',
      label: 'Access',
      value: (record) =>
        record.isActive ? (
          <span className={classes.statusActive}>Active</span>
        ) : (
          <span className={classes.statusInactive}>Inactive</span>
        )
    }
  ];

  const rolesHeadCells = [
    { id: 'role', label: 'Role', isEditAnchor: true, value: (record) => record.name },
    {
      id: 'permissions',
      label: 'Actions',
      limitWidth: true,
      value: (record) => record.permissions && record.permissions.join(', ')
    },
    {
      id: 'allowedUIModules',
      label: 'App Modules',
      limitWidth: true,
      value: (record) => record.allowedUIModules && record.allowedUIModules.join(', ')
    },
    {
      id: 'status',
      label: 'Status',
      value: (record) =>
        record.status === 'ACTIVE' ? (
          <span className={classes.statusActive}>Active</span>
        ) : (
          <span className={classes.statusInactive}>Inactive</span>
        )
    }
  ];

  const usersHandler = () => {
    setUserLoader(true);
    dispatch(
      UsersActions.getUsersAction({
        loader: 'loading-request',
        slug: API.GET_USERS_DATA,
        method: 'get',
        callback: () => {
          setUserLoader(false);
          setUserApiCompleted(true);
        }
      })
    );
  };

  const rolesHandler = () => {
    setRoleLoader(true);
    dispatch(
      RolesActions.getRolesAction({
        loader: 'loading-request',
        slug: API.GET_ROLES_DATA,
        method: 'get',
        callback: () => {
          setRoleLoader(false);
          setRoleApiCompleted(true);
        }
      })
    );
  };

  useMemo(() => rolesHandler(), []);
  useMemo(() => usersHandler(), []);

  useEffect(() => {
    if (usersData.length) {
      let users = JSON.parse(JSON.stringify(usersData));
      users = users.map((user) => {
        user.role_name = user.role?.name || '';
        return user;
      });
      setOriginalUserRecords(users);
      setUserRecords(users);
    }

    if (rolesData.length) {
      let roles = JSON.parse(JSON.stringify(rolesData));
      roles = roles.map((role) => {
        role.status = role.status ? 'ACTIVE' : 'INACTIVE';
        return role;
      });
      setOriginalRoleRecords(roles);
      setRoleRecords(roles);
    }
  }, [rolesData, usersData]);

  const handleTabs = (e, val) => {
    setValue(val);
  };

  const handleSearch = (e, currentTab) => {
    const value = e.target.value;
    let records = currentTab === 0 ? originalUserRecords : originalRolesRecords;
    records = JSON.parse(JSON.stringify(records));
    records.forEach((record) => (record.status = record.status ? 'ACTIVE' : 'INACTIVE'));
    let searchList =
      currentTab === 0
        ? ['fullName', 'phoneNumber', 'role_name', 'status']
        : ['name', 'permissions', 'status'];
    const setter = currentTab === 0 ? setUserRecords : setRoleRecords;
    searchList = searchList.concat([
      'createdBy.fullName',
      'createdAt',
      'updatedBy.fullName',
      'updatedAt'
    ]);
    const filteredRecords = records.filter((record) =>
      searchList.some((field) => {
        let recordInner = { ...record };
        if (field.indexOf('.') > -1) {
          field = field.split('.');
          recordInner = recordInner[field[0]];
          field = field[1];
        }
        return (
          recordInner &&
          recordInner[field] !== undefined &&
          typeof recordInner[field] === 'string' &&
          recordInner[field].toLowerCase().indexOf(value?.toLowerCase()) > -1
        );
      })
    );
    records.forEach((record) => (record.status = record.status === 'ACTIVE'));
    setter(filteredRecords);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Breadcrumbs
        title="Access Details"
        route={[
          { name: 'Home', path: '/home' },
          { name: 'Setup', path: '/setup' },
          { name: 'User Access' }
        ]}
      />
      <MDBox px={5} py={3}>
        <Grid container spacing={1} className={classes.margin + ' w-100 ms-0'}>
          <Grid item xs={12} sm={4} md={4} className="ps-0 pt-0">
            <Tabs value={value} className={`p-0 h-100 ${classes.tabs}`} onChange={handleTabs}>
              <Tab label="Users" onClick={() => usersHandler()} />
              <Tab label="Roles" onClick={() => rolesHandler()} />
            </Tabs>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            md={6}
            className="py-2"
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}
          >
            <SearchBar onChange={(e) => handleSearch(e, value)} />
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            md={2}
            className="py-2"
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}
          >
            <MDButton
              color="primary"
              size="medium"
              disabled={!canCreateUsers}
              onClick={() =>
                navigate(`/setup/users-access/${value === 0 ? 'create-user' : 'create-role'}`)
              }
            >
              {value === 0 ? '+ CREATE USER' : '+ CREATE ROLE'}
            </MDButton>
          </Grid>
        </Grid>
        <Grid>
          <PwTablePanel
            classes={classes}
            headCells={userHeadCells}
            id="user-list"
            index={0}
            loader={userLoader}
            dataFetched={userApiCompleted}
            records={userRecords}
            navUrl="/setup/users-access/edit-user"
            table="user"
            value={value}
          />
          <PwTablePanel
            classes={classes}
            headCells={rolesHeadCells}
            id="role-list"
            index={1}
            loader={roleLoader}
            dataFetched={roleApiCompleted}
            records={rolesRecords}
            navUrl="/setup/users-access/edit-role"
            table="role"
            value={value}
          />
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}
export default UserAccessScreen;
