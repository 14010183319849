import React from 'react';

export default function TvIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="96"
      height=" 96"
      color="#007aff"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-tv"
    >
      <rect x="2" y="7" width="20" height="15" rx="2" ry="2"></rect>
      <polyline points="17 2 12 7 7 2"></polyline>
    </svg>
  );
}
